import { RootStore } from '../../../../stores/RootStore'
import { action, observable, computed, reaction } from 'mobx'
import MyTrainingPlansWidgetVM from './MyTrainingPlansWidgetVM'
import { TrainingPlanItem } from '../../../aggregates/TrainingPlanItem'
import { TrainingPlansUpdateService } from '../../../service/TrainingPlansUpdateService'
import { ContentVM } from './../ContentVM'
import { Quiz } from '../../../../quiz/aggregate/Quiz'
import { Attachment } from '../../../aggregates/Attachment'
import moment from 'moment'
import { PlanItemVM } from '../PlanItemVM'
import { MyTrainingsTimelinePlanRowVM } from './MyTrainingsTimelinePlanRowVM'

export class MyTrainingsTimelineItemRowVM {
  private trainingPlanItem: TrainingPlanItem
  private rootStore: RootStore
  public svc: TrainingPlansUpdateService
  private planRowVM: MyTrainingsTimelinePlanRowVM

  constructor(
    rootStore: RootStore,
    planRowVM: MyTrainingsTimelinePlanRowVM,
    trainingPlanItem: TrainingPlanItem
  ) {
    this.rootStore = rootStore
    this.trainingPlanItem = trainingPlanItem
    this.planRowVM = planRowVM
    this.s = this.rootStore.localizationStore.lzStrings.training_items
    this.svc = new TrainingPlansUpdateService()
    setTimeout(() => this.getElement(), 2000)
    reaction(
      () => this.isExpanded,
      () => setTimeout(() => this.getElement(), 500)
    )
  }

  @observable public isExpanded: boolean = false
  @observable public element: any = null
  @observable public s: any = null

  @computed
  public get isLastItem(): boolean {
    return this.planRowVM.itemRows[this.planRowVM.itemRows.length - 1].id === this.id
  }

  @computed
  public get isFirstTrainingItem(): boolean {
    return this.planRowVM.itemRows[0].id === this.id
  }

  @computed
  public get previousTrainingItem() {
    const idx = this.planRowVM.itemRows.findIndex((row) => row.id === this.id)
    if (idx === 0) return null
    return this.planRowVM.itemRows[idx - 1]
  }

  @computed
  public get mustCompleteInOrder(): boolean {
    return this.trainingPlanItem.mustCompleteInOrder
  }

  @computed
  public get requireCertificate(): boolean {
    if (this.planRowVM.isCertificateUploadDisabled) {
      return false
    } else {
      return this.trainingPlanItem.requireCertificate
    }
  }

  @computed
  public get hasCertificate(): boolean {
    return Boolean(this.trainingPlanItem.certificate)
  }

  @action
  public setCertificate(result: Attachment) {
    this.trainingPlanItem.setCertificate(result)
  }

  @computed
  public get previousItemIsIncomplete(): boolean {
    let isTrue = false
    const idx = this.planRowVM.itemRows.findIndex((row) => row.id === this.id)
    for (let i = 0; i < idx; i++) {
      if (!this.planRowVM.itemRows[i].isComplete) isTrue = true
    }
    return isTrue
  }

  @action
  public openUserTrainingItem() {
    this.rootStore.appStore.router.push(
      `/userTrainingPlans/take/${this.planRowVM.objectId}/foritem/${this.id}`
    )
  }

  @computed
  public get completionAllowed(): boolean {
    if (this.previousItemIsIncomplete && this.planRowVM.mustCompleteItemsInOrder) {
      return false
    }
    return true
  }

  @action
  public getElement() {
    this.element = null
    this.element = document.getElementById(`training-item-${this.id}`)
    if (!this.element) setTimeout(() => this.getElement(), 100)
  }

  @computed
  public get height(): number {
    let height
    if (!this.isExpanded && this.planRowVM.itemRows.length === 1) {
      return 0
    } else if (!this.isExpanded && this.planRowVM.itemRows.length > 0 && !this.isLastItem) {
      return this.element ? this.element.clientHeight : 68
    } else if (!this.isExpanded && this.planRowVM.itemRows.length > 0 && this.isLastItem) {
      return 10
    }
    height = this.element ? this.element.clientHeight - 20 : 68
    if (this.isLastItem && this.isExpanded) height = height - 30
    if (this.nextTrainingItem) height = height + 15
    return height
  }

  @computed
  public get name(): string {
    return this.trainingPlanItem.name.toUpperCase()
  }

  @computed
  public get id(): string {
    return this.trainingPlanItem.id
  }

  @computed
  public get certificateUrl(): string {
    if (!this.trainingPlanItem.certificate) return ''
    return this.trainingPlanItem.certificate.url
  }

  @computed
  public get certificateFileName(): string {
    const fileName = `${this.trainingPlanItem.certificate.fileName}.${this.trainingPlanItem.certificate.format}`
    return fileName
  }

  @computed
  public get trainingItemCompletionString(): string {
    if (
      this.trainingPlanItem.requireQuiz &&
      this.trainingPlanItem.requireCertificate &&
      !this.planRowVM.isCertificateUploadDisabled
    )
      return `+ ${this.s.quiz} + ${this.s.certificate}`
    if (this.trainingPlanItem.requireQuiz) return `+ ${this.s.quiz}`
    if (this.trainingPlanItem.requireCertificate && !this.planRowVM.isCertificateUploadDisabled)
      return `+ ${this.s.certificate}`
    return ''
  }

  @computed
  public get requireQuiz(): boolean {
    return this.trainingPlanItem.requireQuiz
  }

  @computed
  public get isPastDue(): boolean {
    if (moment().isAfter(moment(this.dueDate))) return true
    return false
  }

  @computed
  public get dueDay(): string {
    return moment(this.dueDate).format('DD')
  }

  @computed
  public get dueMonth(): string {
    return moment(this.dueDate).format('MMM')
  }

  @computed
  public get quiz(): Quiz {
    if (!this.trainingPlanItem.requireQuiz) return null
    return this.trainingPlanItem.quiz
  }

  @computed
  public get isComplete(): boolean {
    return this.trainingPlanItem.isComplete
  }

  @computed
  public get nextTrainingItem() {
    const nextIndex = this.planRowVM.itemRows.findIndex((row) => row.id === this.id)
    return this.planRowVM.itemRows[nextIndex + 1]
  }

  @computed
  public get dueDate() {
    if (!this.planRowVM.variesByItem) return moment(this.planRowVM.dueDate)
    return moment(this.trainingPlanItem.dueDate)
  }

  @computed
  public get timePastDue(): string {
    if (!this.isPastDue) return ''
    const dueDate = moment(this.dueDate)
    const today = moment()
    const timeTilDue = today.diff(dueDate, 'minutes')
    const numDays = Math.floor(timeTilDue / 1440)
    const numHours = Math.floor((timeTilDue % 1440) / 60)
    const numMin = Math.floor((timeTilDue % 1440) % 60)
    if (numDays >= 2) return `${numDays} ${this.s.days_past_due}`
    if (numDays >= 1) return `1 ${this.s.day_past_due}`
    if (numHours >= 2) return `${numHours} ${this.s.hours_past_due}`
    if (numHours >= 1) return `1 ${this.s.hour_past_due}`
    if (numMin > 0) return `${numMin} ${this.s.minutes_past_due}`
    return ''
  }

  @computed
  public get timeUntilDue(): string {
    if (this.isPastDue) return this.timePastDue
    const dueDate = moment(this.dueDate)
    const today = moment()
    const timeTilDue = dueDate.diff(today, 'minutes')
    const numDays = Math.floor(timeTilDue / 1440)
    const numHours = Math.floor((timeTilDue % 1440) / 60)
    const numMin = Math.floor((timeTilDue % 1440) % 60)
    if (numDays >= 2) return `${this.s.due_in} ${numDays} ${this.s.days}`
    if (numDays >= 1) return `${this.s.due_in} 1 ${this.s.day}`
    if (numHours >= 2) return `${this.s.due_in} ${numHours} ${this.s.hours}`
    if (numHours >= 1) return ` ${this.s.due_in} 1 ${this.s.hour}`
    if (numMin > 0) return `${this.s.due_in} ${numMin} ${this.s.minutes}`
    return ''
  }

  @computed
  public get contentItemTypes(): string {
    const types = []
    this.trainingPlanItem.contentItems.forEach((item) => {
      if (item.type) types.push(item.type)
    })
    return types.join(', ')
  }

  @action
  public markAsIncomplete() {
    this.trainingPlanItem.markIsIncomplete()
  }
}
