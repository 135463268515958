import { observable, action, computed, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { RootStore } from './RootStore'
import * as Sentry from '@sentry/browser'
import Parse from 'parse'
import axios from 'axios'
import { NotificationsWidgetVM } from '../notifications-widget/view-models/NotificationsWidgetVM'
import ieIE11, { isIE11 } from '../../utils/isIE11'
import { AuthenticationService } from '../auth/services/AuthenticationService'
import { BrowserRouter, Location } from 'react-router-dom'
import ReactGA from 'react-ga'
import moment from 'moment'
import env from '../../env'

export class AppStore {
  private rootStore: RootStore
  private redirectingToLogin: boolean = false
  private to: any
  @observable public currentRoute: string = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.checkOnline()
    this.notificationsWidgetVM = new NotificationsWidgetVM(this.rootStore)
    this.initializeReactGA()
  }

  @persist @observable public currentOrgId = ''
  @persist @observable public currentUserId = ''
  @persist @observable public isImpersonation: boolean = false
  @persist @observable public rememberMe: boolean = true
  @persist @observable public rememberedUserName: string = ''
  @persist @observable public theme: string = ''
  @observable public isOnline: boolean = true
  @persist @observable public attemptingEmail: string = ''
  @persist @observable public rememberLink: string = ''

  @observable public notificationsWidgetVM: NotificationsWidgetVM = null
  @observable public widgetResizeCounter: number = 0
  public loadingData: boolean = false
  @observable public isDrawerOpen = false
  public router: BrowserRouter
  @observable public authCheckedTimes: number = 0
  public themeHydrated: boolean = false

  private initializeReactGA() {
    if (process.env.NODE_ENV === 'test') return
    ReactGA.initialize(
      env.var.REACT_APP_ENVIRONMENT === 'DEV'
        ? 'UA-194237561-1'
        : env.var.REACT_APP_ENVIRONMENT === 'LOCAL'
        ? 'UA-194237561-1'
        : env.var.REACT_APP_ENVIRONMENT === 'QA'
        ? 'UA-194237561-3'
        : env.var.REACT_APP_ENVIRONMENT === 'STAGING'
        ? 'UA-194237561-4'
        : env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION'
        ? 'UA-194237561-5'
        : null,
      { debug: false }
    )
  }

  public setRouter(router: BrowserRouter) {
    if (this.router) return
    this.router = router
    const currentLocation = router.getCurrentLocation().pathname
    this.setCurrentRoute(currentLocation)
    this.router.listen((location: Location) => {
      if (location.action !== 'PUSH' && location.action !== 'REPLACE') return
      this.setCurrentRoute(location.pathname)
    })
  }

  private setCurrentRoute(newRoute: string) {
    newRoute = this.removeIdsFromRoute(newRoute)
    if (this.currentRoute === newRoute) return
    ReactGA.pageview(newRoute)
    this.currentRoute = newRoute
    if (newRoute.includes('/impact')) this.lazyLoadAudienceMembers()
    else if (newRoute.includes('/training')) this.lazyLoadAudienceMembers()
    else if (newRoute.includes('/tasks')) this.lazyLoadAudienceMembers()
  }

  private lazyLoadAudienceMembers(attempts: number = 0) {
    if (attempts === 50) return
    if (process.env.NODE_ENV === 'test') return
    if (!this.rootStore.userStore.currentOrganization) {
      setTimeout(() => this.lazyLoadAudienceMembers(++attempts), 500)
      return
    }
    this.rootStore.audienceMembersStore.loadAudienceMembers()
  }

  private removeIdsFromRoute(route: string): string {
    const routeString = String(route.toLowerCase())
    if (routeString.includes('/dashboard/userdashboard/edit'))
      return '/dashboard/userdashboard/edit'
    if (routeString.includes('/dashboard/userdashboard')) return '/dashboard/userdashboard'
    if (routeString.includes('/dashboard/worksheets/edit')) return '/dashboard/worksheets/edit'
    if (routeString.includes('/dashboard/worksheets')) return '/dashboard/worksheets'
    if (routeString.includes('/dashboard/surveys/edit')) return '/dashboard/surveys/edit'
    if (routeString.includes('/surveyresponses/view')) return '/surveyresponses/view'
    if (routeString.includes('/dashboard/surveys')) return '/dashboard/surveys'
    if (routeString.includes('/dashboard/tasks/edit')) return '/dashboard/tasks/edit'
    if (routeString.includes('/dashboard/tasks')) return '/dashboard/tasks'
    if (routeString.includes('/dashboard/impact/trainings/edit'))
      return '/dashboard/impact/trainings/edit'
    if (routeString.includes('/dashboard/impact/trainings')) return '/dashboard/impact/trainings'
    if (routeString.includes('/dashboard/impact/catalog/edit'))
      return '/dashboard/impact/catalog/edit'
    if (routeString.includes('/dashboard/impact/catalog')) return '/dashboard/impact/catalog'
    if (routeString.includes('/dashboard/impact/goals/edit')) return '/dashboard/impact/goals/edit'
    if (routeString.includes('/dashboard/impact/goals')) return '/dashboard/impact/goals'
    if (routeString.includes('/dashboard/tenantadmin/org/edit'))
      return '/dashboard/tenantadmin/org/edit'
    if (routeString.includes('/dashboard/tenantadmin/org')) return '/dashboard/tenantadmin/org'
    if (routeString.includes('/dashboard/tenantadmin/config/edit'))
      return '/dashboard/tenantadmin/config/edit'
    if (routeString.includes('/dashboard/tenantadmin/config'))
      return '/dashboard/tenantadmin/config'
    if (routeString.includes('/dashboard/tenantadmin/survey/edit'))
      return '/dashboard/tenantadmin/survey/edit'
    if (routeString.includes('/dashboard/tenantadmin/survey'))
      return '/dashboard/tenantadmin/survey'
    if (routeString.includes('/surveys/editv2')) return '/surveys/editv2'
    if (routeString.includes('/goals/edit')) return '/goals/edit'
    if (routeString.includes('/trainingplans/edit')) return '/trainingPlans/edit'
    if (routeString.includes('/trainingitems/from-catalog/edit'))
      return '/trainingitems/from-catalog/edit'
    if (routeString.includes('/goals/from-catalog/edit')) return '/goals/from-catalog/edit'
    if (routeString.includes('/usersurveys/take')) return '/usersurveys/take'
    if (routeString.includes('/usersurveys/completed')) return '/usersurveys/completed'
    if (routeString.includes('/worksheets/edit')) return '/worksheets/edit'
    if (routeString.includes('/trainingplans/from-catalog/edit'))
      return '/trainingplans/from-catalog/edit'
    if (routeString.includes('/surveyresults/view')) return '/surveyresults/view'
    if (routeString.includes('/pulse/v1/edit')) return '/pulse/v1/edit'
    return route.toLowerCase()
  }

  @action
  public setAppOffline(reason: string = '') {
    console.log('app offline: ' + reason)
    this.isOnline = false
    // if (this.checkOnlineTO) return
    // this.checkOnlineTO = setTimeout(() => this.loadData('trying to reconnect'), 5000)
  }

  @action
  public setAppOnline() {
    // const wasOffline = this.isOnline === false
    this.isOnline = true
    // if (wasOffline) {
    //   setTimeout(() => this.loadData('connection restored'), 2500)
    // }
  }

  @action
  public async checkOnline() {
    if (process.env.NODE_ENV === 'test') return
    const url = env.var.REACT_APP_API_URL + '/online'
    try {
      await axios.get(url)
      this.setAppOnline()
    } catch (e) {
      this.setAppOffline()
      setTimeout(() => this.checkOnline(), 5000)
    }
  }

  @action
  public toggleRememberMe() {
    this.rememberMe = !this.rememberMe
    localStorage.setItem('rememberMe', '1')
    localStorage.setItem('rememberedUserName', this.rememberedUserName)
    if (!this.rememberMe) {
      this.setRememberedUserName('')
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('rememberedUserName')
    }
  }

  @action
  public incrementWidgetResizeCounter() {
    this.widgetResizeCounter++
  }

  @action
  public setRememberMe(val) {
    this.rememberMe = val
  }

  @action
  public setRememberLink(val: string) {
    if (val) val = val.replace('#', '')
    this.rememberLink = val
    localStorage.setItem('rememberLink', val)
    if (!this.rememberLink) localStorage.removeItem('rememberLink')
  }

  @action
  public setRememberedUserName(val) {
    this.rememberedUserName = val
    localStorage.setItem('rememberedUserName', val)
    if (!this.rememberMe) localStorage.removeItem('rememberedUserName')
  }

  @action
  public toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen
    document.body.classList.toggle('drawerOpen')

    if (isIE11) {
      console.log('resize event ie11')
      setTimeout(() => {
        let event = document.createEvent('UIEvents')
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      }, 0)
      /* Timeout delay shorter - no animation in ie11. */
    } else {
      console.log('resize event not ie11')
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 325)
      /*  Timeout delay should be slightly greater than the transition duration in Navigation.scss.
          If you make changes, make sure to change both. */
    }
  }

  @action
  public setRedirectingToLogin(val) {
    this.redirectingToLogin = val
  }

  @action
  public navigateToNoOrganizationsFound() {
    setTimeout(() => this.router.push('/auth/login/organization/404/'), 1000)
  }

  @action
  public checkAuth(): boolean {
    if (!this.rootStore.isHydrated || !this.router) {
      setTimeout(() => this.checkAuth(), 500)
      return false
    }
    let shouldRedirect = this.currentUserId === '' || !this.currentUserId
    if (shouldRedirect && !this.redirectingToLogin) {
      console.log('Hash: ' + window.location.hash)
      this.setRedirectingToLogin(true)
      if (this.checkForTaskRedirect()) return false
      this.setRememberLink(window.location.hash)
      setTimeout(() => this.redirectToLogin(), 0)
      return false
    } else if (this.redirectingToLogin) {
      return false
    } else {
      if (this.to) clearTimeout(this.to)
      this.to = setTimeout(() => this.authCheckedTimes++, 500)
    }
    return true
  }

  private checkForTaskRedirect() {
    const { userTaskId, orgId } = this.router.params
    if (!orgId) return false
    if (!userTaskId) return false
    this.router.push(`/auth/login/task/${userTaskId}/org/${orgId}`)
    return true
  }

  private redirectToLogin() {
    this.router.push('/auth/login')
  }

  @action
  public setCurrentOrg(orgId: string) {
    const isSame = orgId === this.currentOrgId
    if (isSame) return false
    this.currentOrgId = orgId
    if (!this.isFullyLoaded) this.loadData('org id changed')
    return true
  }

  @action
  public clearCurrentOrg() {
    this.currentOrgId = ''
  }

  @action
  public setTheme(val) {
    if (val === '') val = 'ripple'
    this.theme = val
    this.themeHydrated = true
    if (document.body.classList.contains('theme-' + val)) return
    document.body.classList.remove('theme-ripple')
    document.body.classList.remove('theme-warnerMedia')
    document.body.classList.add('theme-' + val)
  }

  @action
  public async loadData(reason) {
    if (process.env.NODE_ENV === 'test') return
    if (!this.themeHydrated && this.theme !== '') this.setTheme(this.theme)
    console.log('trying reload: ' + reason)
    if (this.currentUserId === '') return
    await this.rootStore.userStore.loadUser()
    if (!this.isOnline) return
    if (this.currentOrgId === '') return
    if (this.loadingData) return
    this.loadingData = true
    console.log('reloading all data: ' + reason)
    const proms = [
      this.loadUserData(),
      this.loadCalendarSettingsData(),
      this.loadPulseCampaignTypesData(),
      this.loadSurveysData(),
      this.loadWorksheetsData(),
      this.loadUserWorksheetsData(),
      this.loadTasksData(),
      // this.loadGoalsData(),
      this.loadPulseData(),
      this.loadCMSItemAttachmentsData(),
      this.loadAnnouncementsData(),
      this.loadTrainingPlansData(),
      this.loadTenantAdminData(),
      this.loadCustomCollectionsData(),
      this.loadSystemAdminData(),
      this.loadEmailTemplatesData(),
    ]
    await Promise.all(proms)
    this.loadingData = false
  }

  private async loadUserData() {
    await this.rootStore.organizationsStore.loadListRecords()
    const proms = [
      this.rootStore.dashboardsStore.loadListRecords(),
      this.rootStore.userDashboardsStore.loadListRecords(),
      this.rootStore.privilegesStore.loadListRecords(),
      this.rootStore.labelsStore.loadListRecords(),
      this.rootStore.rolesStore.loadListRecords(),
      this.rootStore.groupsStore.loadListRecords(),
      this.rootStore.privilegeSetsStore.loadListRecords(),
      this.rootStore.consentsStore.loadListRecords(),
    ]
    await Promise.all(proms)
  }

  private async loadSystemAdminData() {
    if (!this.rootStore.userStore.user.isSystemAdmin) return
    await this.rootStore.systemAdminsStore.loadSystemAdmins()
    await this.rootStore.organizationsSAStore.loadOrganizations()
  }

  private async loadCustomCollectionsData() {
    await this.rootStore.customCollectionsStore.loadListRecords() // TODO: eliminate the need for this store loading unless privileged for it
  }

  private async loadCalendarSettingsData() {
    const proms = [
      this.rootStore.eventSchedulesStore.loadListRecords(),
      this.rootStore.calendarSettingsStore.loadCalendarSettings(),
      this.rootStore.eventTypesStore.loadListRecords(),
      this.rootStore.locationsStore.loadListRecords(),
      this.rootStore.resourcesStore.loadListRecords(),
    ]
    await Promise.all(proms)
  }

  private async loadTenantAdminData() {
    if (!this.isSystemAdmin && !this.isOrgAdmin) return
    // await this.rootStore.weightProfilesStore.loadListRecords()
  }

  private async loadSurveysData() {
    this.rootStore.userSurveysStore.loadListRecords()
    this.rootStore.userSurveysCountStore.loadListRecords()
    const proms = [
      this.rootStore.categoriesStore.loadListRecords(),
      this.rootStore.mediaItemsStore.loadListRecords(),
      this.rootStore.surveyTypesStore.loadListRecords(),
      this.rootStore.clientsStore.loadListRecords(),
      this.rootStore.completedSurveyResponsesStore.loadListRecords(),
      this.rootStore.contactsStore.loadListRecords(),
      this.rootStore.notificationsStore.loadListRecords(),
      this.rootStore.surveyResultsStore.loadListRecords(),
      this.rootStore.surveyTagsStore.loadListRecords(),
      // TODO: load templates lazily on new survey if not tenant admin?
    ]
    await Promise.all(proms)
  }

  private async loadPulseCampaignTypesData() {
    await this.rootStore.pulseCampaignTypesStore.loadListRecords()
  }

  private async loadWorksheetsData() {
    await this.rootStore.worksheetsStore.loadListRecords()
  }

  private async loadUserWorksheetsData() {
    await this.rootStore.userWorksheetsStore.loadListRecords()
  }

  private async loadEmailTemplatesData() {
    await this.rootStore.emailTemplatesStore.loadListRecords()
  }

  private async loadTasksData() {
    await this.rootStore.userTasksStore.loadListRecords()
    const proms = [
      this.rootStore.tasksStore.loadListRecords(),
      this.rootStore.taskTemplatesStore.loadListRecords(),
      this.rootStore.taskCommentsStore.loadListRecords(),
    ]
    await Promise.all(proms)
  }

  private async loadPulseData() {
    await this.rootStore.pulseSetsStore.loadListRecords()
  }

  private async loadGoalsData() {
    return
    await this.rootStore.userGoalsStore.loadListRecords()
    const proms = [
      this.rootStore.goalsCatalogStore.loadListRecords(),
      this.rootStore.goalsStore.loadListRecords(),
    ]
    await Promise.all(proms)
  }

  private async loadAnnouncementsData() {
    await this.rootStore.userAnnouncementsStore.loadListRecords()
    await this.rootStore.announcementsStore.loadListRecords()
  }

  private async loadTrainingPlansData() {
    this.rootStore.userTrainingPlansStore.loadListRecords()
    const proms = [
      this.rootStore.trainingItemsStore.loadListRecords(),
      this.rootStore.trainingPlansStore.loadListRecords(),
      this.rootStore.trainingPlansCatalogStore.loadListRecords(),
    ]
    await Promise.all(proms)
  }

  private async loadCMSItemAttachmentsData() {
    await this.rootStore.cmsItemAttachmentStore.clearData()
  }

  @action
  public clearData() {
    this.setCurrentOrg('')
    this.setCurrentUser('')
    this.attemptingEmail = undefined
    //TODO: Utilize rootstore.initializedStores array to clear data
    this.rootStore.taskTemplatesStore.clearData()
    this.rootStore.rolesStore.clearData()
    this.rootStore.userStore.clearData()
    this.rootStore.usersStore.clearData()
    this.rootStore.audienceMembersStore.clearData()
    this.rootStore.questionsStore.clearData()
    this.rootStore.resourcesStore.clearData()
    this.rootStore.locationsStore.clearData()
    this.rootStore.eventTypesStore.clearData()
    this.rootStore.eventTypesStore.clearData()
    this.rootStore.calendarSettingsStore.clearData()
    this.rootStore.privilegeSetsStore.clearData()
    this.rootStore.privilegesStore.clearData()
    this.rootStore.dashboardsStore.clearData()
    this.rootStore.labelsStore.clearData()
    this.rootStore.groupsStore.clearData()
    this.rootStore.categoriesStore.clearData()
    this.rootStore.userSurveysStore.clearData()
    this.rootStore.surveyTypesStore.clearData()
    this.rootStore.pulseCampaignTypesStore.clearData()
    this.rootStore.surveysStore.clearData()
    this.rootStore.taskTemplatesStore.clearData()
    this.rootStore.organizationsStore.clearData()
    this.rootStore.systemAdminsStore.clearData()
    this.rootStore.weightProfilesStore.clearData()
    this.rootStore.organizationsSAStore.clearData()
    this.rootStore.completedSurveyResponsesStore.clearData()
    this.rootStore.goalsStore.clearData()
    this.rootStore.userGoalsStore.clearData()
    this.rootStore.trainingItemsStore.clearData()
    this.rootStore.userTrainingPlansStore.clearData()
    this.rootStore.trainingPlansCatalogStore.clearData()
    this.rootStore.goalsCatalogStore.clearData()
    this.rootStore.organizationUsersStore.clearData()
    this.rootStore.surveyTagsStore.clearData()
    this.rootStore.cmsItemAttachmentStore.clearData()
  }

  @action
  public async logout(reason: string = '', doReload: boolean = true, silent: boolean = false) {
    console.log('Logging out: ' + reason)
    this.clearData()
    if (!this.isImpersonation) await Parse.User.logOut()
    if (!silent) this.router.push('/auth/login')
    if (!doReload) return
    setTimeout(() => window.location.reload(), 1000) // give time for localstorage to be cleared
  }

  @action
  public setCurrentUser(userId: string, isImpersonation?: boolean) {
    this.setRedirectingToLogin(false)
    const isChanged = userId !== this.currentUserId
    this.currentUserId = userId
    this.isImpersonation = isImpersonation ? true : false
    if (isChanged) this.loadData('user changed')
    if (process.env.NODE_ENV === 'test') return
    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId })
      scope.setTag('organizationId', this.currentOrgId)
    })
  }

  @computed
  public get calendarNotifications(): number {
    const length = this.rootStore.userEventsStore.currentOrgUserEvents.filter((userEvent) => {
      if (userEvent.readStatus === 'read') {
        return false
      }
      if (moment(userEvent.startDate).diff(moment().startOf('day')) <= 0) return false
      if (userEvent.userId !== this.rootStore.appStore.currentUserId) return false
      return true
    }).length
    return length < 100 ? length : 99
  }

  @computed
  public get taskNotifications(): number {
    const length = this.rootStore.userTasksStore.userTasks.filter(
      (task) => task.userId === this.rootStore.appStore.currentUserId && !task.isCompleted
    ).length
    return length < 100 ? length : 99
  }

  @computed
  public get surveyNotifications(): number {
    const length = this.rootStore.userSurveysCountStore.currentOrgActiveUserSurveys.length
    return length < 100 ? length : 99
  }

  @computed
  public get impactNotifications(): number {
    const goals = this.rootStore.userGoalsStore.orgUserGoals.filter(
      (userGoal) => userGoal.userId === this.currentUserId && !userGoal.isRead
    ).length
    const plans = this.rootStore.userTrainingPlansStore.orgUserTrainingPlans.filter(
      (plan) => plan.userId === this.currentUserId && !plan.isRead
    ).length
    return goals + plans
  }

  @computed
  public get environmentLabel(): string {
    if (env.var.REACT_APP_ENVIRONMENT === 'STAGING') return 'STAGING'
    if (env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION') return ''
    if (env.var.REACT_APP_ENVIRONMENT === 'DEV') return 'DEV'
    if (env.var.REACT_APP_ENVIRONMENT === 'QA') return 'QA'
    if (env.var.REACT_APP_ENVIRONMENT === 'DEMO') return 'DEMO'
    if (env.var.REACT_APP_ENVIRONMENT === 'LOCAL') return 'LOCAL'
    return ''
  }

  @computed
  public get isOnlineLabel(): string {
    if (env.var.REACT_APP_ENVIRONMENT === 'PRODUCTION') return ''
    if (!this.rootStore.appStore.isOnline) return 'offline'
    return 'online'
  }

  @computed
  public get canAssignWork() {
    return this.rootStore.userStore.userPrivileges.includes('can_assign_work')
  }

  @computed
  public get canCreateGoals() {
    return this.rootStore.userStore.userPrivileges.includes('can_create_goals')
  }

  @computed
  public get canCreateTrainings() {
    return this.rootStore.userStore.userPrivileges.includes('can_create_trainings')
  }

  @computed
  public get canEditAllTrainings() {
    return this.rootStore.userStore.userPrivileges.includes('can_edit_all_trainings')
  }

  @computed
  public get canChat() {
    return this.rootStore.userStore.userPrivileges.includes('can_chat')
  }

  @computed
  public get canManageCategories() {
    return this.rootStore.userStore.userPrivileges.includes('can_manage_categories')
  }

  @computed
  public get canSchedule() {
    return this.rootStore.userStore.userPrivileges.includes('can_schedule')
  }

  @computed
  public get canSurvey() {
    return this.rootStore.userStore.userPrivileges.includes('can_survey')
  }

  @computed
  public get canCreateAnnouncements() {
    return this.rootStore.userStore.userPrivileges.includes('can_create_announcements')
  }

  @computed
  public get canModifySurveyVisibility() {
    return this.rootStore.userStore.userPrivileges.includes('can_modify_survey_visibility')
  }

  @computed
  public get canModifySurveyOwner() {
    return this.rootStore.userStore.userPrivileges.includes('can_modify_survey_owner')
  }

  @computed
  public get canSurveyClients() {
    return this.rootStore.userStore.userPrivileges.includes('can_survey_clients')
  }

  @computed
  public get canImpersonateUsers() {
    return this.rootStore.userStore.userPrivileges.includes('can_impersonate_users')
  }

  @computed
  public get canManageCustomCollections() {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    if (!this.rootStore.organizationsStore.currentOrganization.isCustomCollectionsEnabled)
      return false
    if (
      !this.isOrgAdmin &&
      !this.rootStore.userStore.userPrivileges.includes('can_manage_custom_collections')
    )
      return false
    return true
  }

  @computed
  public get canManagePulseCampaigns() {
    if (this.isOrgAdmin) return true
    return this.rootStore.userStore.userPrivileges.includes('can_manage_campaigns')
  }

  @computed
  public get canAccessTasks() {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    return this.rootStore.organizationsStore.currentOrganization.isTasksEnabled
  }

  @computed
  public get canEditEventType() {
    return this.rootStore.userStore.userPrivileges.includes('can_edit_event_type')
  }

  @computed
  public get canEditEventResource() {
    return this.rootStore.userStore.userPrivileges.includes('can_edit_event_resource')
  }

  @computed
  public get canAccessWorksheets() {
    if (this.canAccessAllAnalysis) return true
    if (this.canAccessScopedAnalysis) return true
    return false
  }

  @computed
  public get canAccessScopedAnalysis() {
    if (this.isOrgAdmin || this.isSystemAdmin) return false
    return this.rootStore.userStore.userPrivileges.includes('can_access_scoped_analysis')
  }

  @computed
  public get canAccessAllAnalysis() {
    return this.rootStore.userStore.userPrivileges.includes('can_access_worksheets')
  }

  @computed
  public get canArchiveSurveys() {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    if (this.rootStore.organizationsStore.currentOrganization.canArchiveSurveys) return true
    return false
  }

  @computed
  public get canViewPulseInvitationHistory() {
    return this.rootStore.userStore.userPrivileges.includes('can_view_pulse_invite_history')
  }

  @computed
  public get canAccessActionableInsights() {
    return this.rootStore.userStore.userPrivileges.includes('can_access_actionable_insights')
  }

  @computed
  public get isFullyLoaded(): boolean {
    if (!this.rootStore.userStore.loaded) return false
    if (!this.rootStore.userStore.currentOrganization) return false
    if (!this.rootStore.rolesStore.loaded) return false
    return true
  }

  @computed
  public get isOrgAdmin() {
    if (!this.rootStore.rolesStore) return false
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.currentOrganization) return false
    return this.rootStore.userStore.currentOrganization.isOrgAdmin
  }

  @computed
  public get isSystemAdmin() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.user) return false
    return this.rootStore.userStore.user.isSystemAdmin
  }

  @computed
  public get canManageUsers() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.userPrivileges) return false
    return this.rootStore.userStore.userPrivileges.includes('can_manage_users')
  }

  @computed
  public get canManageSurveyTemplates() {
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.userPrivileges) return false
    return this.rootStore.userStore.userPrivileges.includes('can_manage_survey_templates')
  }

  @computed
  public get canManageTrainingPlanFlows() {
    return this.rootStore.userStore.userPrivileges.includes('can_manage_training_plan_flows')
  }

  @computed
  public get canStartTrainingPlanFlows() {
    return this.rootStore.userStore.userPrivileges.includes('can_start_training_plan_flows')
  }
}
