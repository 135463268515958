import { Grid } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import QuizBuilder from '../../../quiz/views/QuizBuilder'
import { TrainingItemsStore } from '../../store/TrainingItemsStore'
import './CompletionTabPanel.scss'
import CompletionToolbar from './CompletionToolbar'

interface Props {
  trainingItemsStore?: TrainingItemsStore
}

const CompletionTabPanel: React.FC<Props> = ({ trainingItemsStore }) => {
  const { trainingItemEditVM: vm } = trainingItemsStore
  if (!vm) return null

  const renderBody = () => {
    if (!vm.requireQuiz) return null
    return <QuizBuilder quiz={vm.quiz} />
  }

  return (
    <Grid container id='CompletionTabPanel'>
      <CompletionToolbar />
      {renderBody()}
    </Grid>
  )
}

export default inject('trainingItemsStore')(observer(CompletionTabPanel))
