import React from 'react'
import { inject, observer, useLocalStore } from 'mobx-react'
import { Grid } from '@material-ui/core'
import rootStore from '../../../stores/RootStore'
import { PulseCampaignsStore } from '../../store/PulseCampaignsStore'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import TopToolbar from './TopToolbar'
import PulseCampaignCategoriesTree from '../tree/PulseCampaignCategoriesTree'
import './../../../pulse-categories/views/PulseCategoriesManage.scss'
import './FocusStep.scss'

interface Props {
  pulseCampaignsStore?: PulseCampaignsStore
  manageVM?: PulseCategoriesManageVM
}

const FocusStep: React.FC<Props> = ({ pulseCampaignsStore, manageVM }) => {
  const { editVM } = pulseCampaignsStore.viewModels
  if (!editVM) return null

  const localStore = useLocalStore(() => ({
    vm: manageVM ? manageVM : new PulseCategoriesManageVM(rootStore),
  }))
  const vm = localStore.vm

  const summaryToolbarVM = new PulseCategoriesManageVM(rootStore, false)
  summaryToolbarVM.dataStore = vm.dataStore
  summaryToolbarVM.categoryVMs = vm.categoryVMs

  return (
    <div id='FocusStep'>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='stretch'
        className={'pulse-campaign-focus'}
      >
        <Grid item className={'pulse-campaign-focus-builder'}>
          <TopToolbar vm={vm} countLabelType={'none'} isTemplate={false} />
          <Grid container id={'PulseCampaignCategoriesTree'}>
            <PulseCampaignCategoriesTree vm={vm} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('pulseCampaignsStore')(observer(FocusStep))
