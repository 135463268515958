import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, IReactionDisposer, reaction, when } from 'mobx'
import {
  CellEditingStoppedEvent,
  ColDef,
  GridOptions,
  RowClickedEvent,
  RowNode,
} from 'ag-grid-community'
import { nameOf } from '../../../shared/nameOf'
import { AGGridVM } from '../../../shared/ag-grid/AGGridVM'
import { EmailTemplateRowVM } from '../../../email-templates/view-models/EmailTemplateRowVM'
import { OrganizationUsersListRowVM } from './OrganizationUsersListRowVM'
import { OrganizationUsersListManageVM } from './OrganizationUsersListManageVM'
import SelectAll from '../../views/list/SelectAll'
import { AuthenticationService } from '../../../auth/services/AuthenticationService'
import { IParticipant } from '../../../participants-select/interfaces/IParticipant'
import { ParticipantsSelectVM } from '../../../participants-select/view-models/ParticipantsSelectVM'
import { OrganizationUsersListDataStore } from './OrganizationUsersListDataStore'
import { IOrganizationUsersFindRequest } from '../../interfaces/IOrganizationUsersFindRequest'
import { OrganizationUser } from '../../aggregate/OrganizationUser'
import { IOrganizationUsersListPreferencesSaveRequest } from '../../interfaces/IOrganizationUsersListPreferencesSaveRequest'
import { IOrganizationUsersListPreferencesFindRequest } from '../../interfaces/IOrganizationUsersListPreferencesFindRequest'
import { OrganizationUsersListPreferencesService } from '../../service/OrganizationUsersListPreferencesService'
import { IOrganizationUsersBulkOperationsRequest } from '../../interfaces/IOrganizationUsersBulkOperationsRequest'
import { IGroupDTO } from '../../dtos/IGroupDTO'
import { IRoleDTO } from '../../dtos/IRoleDTO'
import { IPrivilegeSetDTO } from '../../dtos/IPrivilegeSetDTO'
import { IOrganizationUsersEditOperationsRequest } from '../../interfaces/IOrganizationUsersEditOperationsRequest'
import DatePickerCell from '../../views/list/DatePickerCell'
import BirthDatePickerCell from '../../views/list/BirthDatePickerCell'
import NameCell from '../../views/list/NameCell'
import { IOrganizationUsersBulkRequest } from '../../interfaces/IOrganizationUsersBulkRequest'
import { OrganizationUsersBulkOperationsService } from '../../service/OrganizationUsersBulkOperationsService'
import { ITrainingPlanFlowsUsersBulkOpsRequest } from '../../../training-plan-flows/interfaces/ITrainingPlanFlowsUsersBulkOpsRequest'
import { TrainingPlanFlowBulkOperationsService } from '../../../training-plan-flows/service/TrainingPlanFlowsBulkOperationsService'

export class OrganizationUsersListTableVM extends AGGridVM {
  private rootStore: RootStore
  public dataStore: OrganizationUsersListDataStore
  public parentVM: OrganizationUsersListManageVM
  protected quickSaveTO: NodeJS.Timer
  private reactions: IReactionDisposer[] = []
  protected columnMenuTimer: NodeJS.Timer

  constructor(
    rootStore: RootStore,
    parentVM: OrganizationUsersListManageVM,
    columns,
    sortColumnName,
    sortDirection
  ) {
    super()
    this.rootStore = rootStore
    this.viewColumns = columns
    this.sortField = sortColumnName
    this.sortOrder = sortDirection
    this.parentVM = parentVM
    this.dataStore = new OrganizationUsersListDataStore(
      this.rootStore,
      {
        organizationId: this.rootStore.appStore.currentOrgId,
        participants: [],
      } as IOrganizationUsersFindRequest,
      this.shouldShowHiddenUsers,
      sortColumnName,
      sortDirection
    )
    this.sizeColumnsToFit = false
    this.serverSideLoaded = true
    this.participantsSelectVM = new ParticipantsSelectVM(this.rootStore, false, false)
    this.loadStore()

    this.dataStore.onRecordUpdated = (e) => this.onRecordUpdated(e)
    this.reactions.push(
      reaction(
        () => this.highlightedRow,
        () => {
          this.gridApi.redrawRows()
        }
      )
    )
    this.reactions.push(
      reaction(
        () =>
          this.rootStore.organizationUsersStore.editVM &&
          this.rootStore.organizationUsersStore.editVM.isOpen,
        () => {
          if (this.rootStore.organizationUsersStore.editVM.isOpen === true) return
          this.highlightedRow = undefined
        }
      )
    )
    this.reactions.push(
      when(
        () => this.dataStore.listRecordsLoaded,
        () => {
          this.reactions.push(
            reaction(
              () => this.sortString,
              () => {
                this.saveColumns()
              }
            )
          )
        }
      )
    )
  }

  @action
  public loadStore() {
    this.dataStore.loadListRecords()
  }

  @observable public confirmSendDialogOpen: boolean = false
  @observable public confirmExpirePasswordsDialogOpen: boolean = false
  @observable public selectedRow: EmailTemplateRowVM = undefined
  @observable public selectAll: boolean = false
  @observable public newSelectedRows: Array<any> = []
  @observable public unSelectedRows: Array<any> = []
  @observable public selectedTotalShown: number = 0
  @observable public calculatedSelected: number = 0
  @observable public isProcessing: boolean = false
  @observable public filterPopupAnchorEl = null
  @observable public participantsSelectVM: ParticipantsSelectVM = null
  @observable public rows = []
  @observable public loadingFirstPage: boolean = true
  @observable public modifyMenuOpen: boolean = false
  @observable public viewColumnsDialogOpen: boolean = false
  @observable public columnPopupAnchorEl = null
  @observable public columnsLoaded: boolean = false
  @observable public viewColumns: Array<string> = [
    'name',
    'email',
    'username',
    'roles',
    'groups',
    'welcomeEmailStatus',
  ]
  @observable public addToGroupDialogOpen: boolean = false
  @observable public removeFromGroupDialogOpen: boolean = false
  @observable public addToRoleDialogOpen: boolean = false
  @observable public removeFromRoleDialogOpen: boolean = false
  @observable public addToPrivilegeDialogOpen: boolean = false
  @observable public removeFromPrivilegeDialogOpen: boolean = false
  @observable public modifyMenuAnchorEl = null
  @observable public selectedGroupsToAdd: Array<IGroupDTO> = []
  @observable public selectedRolesToAdd: Array<IRoleDTO> = []
  @observable public selectedPrivilegeSetsToAdd: Array<IPrivilegeSetDTO> = []
  @observable public selectedGroupsToRemove: Array<IGroupDTO> = []
  @observable public selectedRolesToRemove: Array<IRoleDTO> = []
  @observable public selectedPrivilegeSetsToRemove: Array<IPrivilegeSetDTO> = []
  @observable public addNew: boolean = false
  @observable public newGroupToAdd: string = ''
  @observable public newRoleToAdd: string = ''
  @observable public tableHasBeenEdited: boolean = false
  @observable public editedRowEvents: Array<any> = []
  @observable public showTable: boolean = true
  @observable public archiveUsersDialogOpen: boolean = false
  @observable public deleteUsersDialogOpen: boolean = false
  @observable public startTrainingPlanFlowBeginnerDialogOpen: boolean = false
  @observable public startTrainingPlanFlowIntermediateDialogOpen: boolean = false
  @observable public startTrainingPlanFlowAdvancedDialogOpen: boolean = false
  @observable public cancelTrainingPlanFlowDialogOpen: boolean = false
  @observable public shouldShowHiddenUsers: boolean = false
  @observable public moreMenuAnchorEl = null
  @observable public addMenuAnchorEl = null
  @observable public removeMenuAnchorEl = null
  @observable public highlightedRow: string = ''
  @observable public currentlyHoveringAdd: boolean = false
  @observable public currentlyHoveringRemove: boolean = false
  @observable public sortField: string = 'fk_User.lastName'
  @observable public sortOrder: 'asc' | 'desc' = 'asc'
  @observable public enableMenuAnchorEl = null
  @observable public disableMenuAnchorEl = null
  @observable public startFlowMenuAnchorEl = null
  @observable public currentlyHoveringEnable: boolean = false
  @observable public currentlyHoveringDisable: boolean = false
  @observable public currentlyHoveringStartFlow: boolean = false
  @observable public enableEmailNotificationsDialogOpen: boolean = false
  @observable public disableEmailNotificationsDialogOpen: boolean = false
  @observable public enableTextNotificationsDialogOpen: boolean = false
  @observable public disableTextNotificationsDialogOpen: boolean = false
  @observable public enablePushNotificationsDialogOpen: boolean = false
  @observable public disablePushNotificationsDialogOpen: boolean = false
  @observable public disableEmailNotifications: boolean = false
  @observable public enableEmailNotifications: boolean = false
  @observable public disableTextNotifications: boolean = false
  @observable public enableTextNotifications: boolean = false
  @observable public disablePushNotifications: boolean = false
  @observable public enablePushNotifications: boolean = false
  @observable public hideCounts: boolean = false
  @observable public showArchivedUsers: boolean = false

  @computed
  public get isLoaded(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization) return false
    if (!this.dataStore.listRecordsLoaded) return false
    return true
  }

  @computed
  public get isSystemAdmin(): boolean {
    return this.rootStore.appStore.isSystemAdmin
  }

  @computed
  public get isUserAdmin(): boolean {
    return this.rootStore.appStore.canManageUsers
  }

  @computed
  public get canManage() {
    if (this.rootStore.appStore.isSystemAdmin) return true
    if (!this.rootStore.userStore.currentOrganization) return false
    return false
  }

  @computed
  public get canStartTrainingPlanFlows(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization?.hasTrainingPlanFlows) return false
    if (this.rootStore.appStore.canStartTrainingPlanFlows) return true
    return false
  }

  @computed
  public get hasRows() {
    return this.rows.length !== 0
  }

  @computed
  public get totalRecords() {
    return this.dataStore.totalRecords
  }

  @computed
  public get dialogCount() {
    if (this.selectAll && this.unSelectedRows.length === 0) return `all ${this.totalRecords}`
    if (this.selectAll) return this.totalRecords - this.unSelectedRows.length
    return this.newSelectedRows.length
  }

  @computed
  public get sortString() {
    return this.dataStore.sortColumnName + this.dataStore.sortDirection
  }

  @computed
  public get participants() {
    if (!this.participantsSelectVM) return []
    return this.participantsSelectVM.participantsToDTO()
  }

  @computed
  public get sortColumn(): string {
    if (!this.dataStore.sortColumnName) return ''
    return this.dataStore.sortColumnName
  }

  @computed
  public get actionButtonDisabled() {
    if (this.isProcessing) return true
    if (this.newSelectedRows.length === 0) return true
    return false
  }

  @action
  public applyParticipantSelections() {
    this.closeParticipantPicker()
    this.selectAll = false
    this.resetNodesSelected()
    const req = {
      organizationId: this.rootStore.appStore.currentOrgId,
      participants: this.participants,
    } as IOrganizationUsersFindRequest
    this.dataStore.setRequest(req)
    this.dataStore.loadListRecords()
    this.refresh()
  }

  @computed
  public get hasParticipants() {
    if (this.participants.length > 0) return true
    return false
  }

  public onRecordUpdated(obj: OrganizationUser) {
    const rowNode = this.gridApi.getRowNode(obj.userId)
    if (!rowNode) return
    rowNode.setData(new OrganizationUsersListRowVM(this.rootStore, obj, this))
  }

  @action
  public setQuickFilter(val: string) {
    this.typedFilterText = val
    if (this.quickFilterTO) clearTimeout(this.quickFilterTO)
    this.quickFilterTO = setTimeout(() => this.applyFilter(), 1000)
  }

  private applyFilter() {
    this.selectAll = false
    this.resetNodesSelected()
    this.dataStore.setFilter(this.typedFilterText)
    this.reload()
    this.columnApi.applyColumnState({
      state: [{ colId: 'fk_User.name', sort: 'asc' }],
    })
  }

  @action
  public scrollToTop() {
    this.gridApi.ensureIndexVisible(0)
  }

  @action
  public refresh() {
    if (!this.gridApi) return
    this.gridApi.purgeInfiniteCache()
  }

  @action
  public hardRefreshUsersList() {
    if (!this.gridApi) return
    this.selectAll = false
    this.scrollToTop()
    this.resetNodesSelected()
    this.gridApi.purgeInfiniteCache()
  }

  @action
  public refreshTable() {
    if (!this.gridApi) return
    this.gridApi.setColumnDefs(this.columnDefs)
    this.columnApi.applyColumnState({
      state: [{ colId: this.sortField, sort: this.sortOrder }],
    })
    this.loadStore()
  }

  @action
  public setFilterPopupAnchorEl(el) {
    this.filterPopupAnchorEl = el
    this.participantsSelectVM.isVisible = true
    this.participantsSelectVM.setCurrentTab(0)
  }

  @action
  public setColumnPopupAnchorEl(el) {
    this.columnPopupAnchorEl = el
  }

  @action
  public setTimer(el) {
    if (this.columnMenuTimer) clearTimeout(this.columnMenuTimer)
    this.columnMenuTimer = setTimeout(() => this.setColumnPopupAnchorEl(el), 100)
  }

  @action
  public cancelTimer() {
    if (this.columnMenuTimer) clearTimeout(this.columnMenuTimer)
  }

  @action
  public setModifyMenuAnchorEl(el) {
    this.modifyMenuAnchorEl = el
  }

  @action
  public setMoreMenuAnchorEl(el) {
    this.moreMenuAnchorEl = el
  }

  @action
  public setAddMenuAnchorEl(el) {
    this.addMenuAnchorEl = el
  }

  @action
  public setRemoveMenuAnchorEl(el) {
    this.removeMenuAnchorEl = el
  }

  @action
  public setEnableMenuAnchorEl(el) {
    this.enableMenuAnchorEl = el
  }

  @action
  public setDisableMenuAnchorEl(el) {
    this.disableMenuAnchorEl = el
  }

  @action
  public setStartFlowMenuAnchorEl(el) {
    this.startFlowMenuAnchorEl = el
  }

  @action
  public handleClickAdd(event) {
    this.setAddMenuAnchorEl(event.currentTarget)
  }

  @action
  public handleHoverAdd() {
    this.currentlyHoveringAdd = true
  }

  @action
  public handleCloseAdd() {
    this.setAddMenuAnchorEl(null)
  }

  @action
  public handleCloseHoverAdd() {
    this.currentlyHoveringAdd = false
    setTimeout(() => {
      if (!this.currentlyHoveringAdd) {
        this.handleCloseAdd()
      }
    }, 50)
  }

  @action
  public handleClickRemove(event) {
    this.setRemoveMenuAnchorEl(event.currentTarget)
  }

  @action
  public handleHoverRemove() {
    this.currentlyHoveringRemove = true
  }

  @action
  public handleCloseRemove() {
    this.setRemoveMenuAnchorEl(null)
  }

  @action
  public handleCloseHoverRemove() {
    this.currentlyHoveringRemove = false
    setTimeout(() => {
      if (!this.currentlyHoveringRemove) {
        this.handleCloseRemove()
      }
    }, 50)
  }

  @action
  public handleClickEnable(event) {
    this.setEnableMenuAnchorEl(event.currentTarget)
  }

  @action
  public handleHoverEnable() {
    this.currentlyHoveringEnable = true
  }

  @action
  public handleCloseEnable() {
    this.setEnableMenuAnchorEl(null)
  }

  @action
  public handleCloseHoverEnable() {
    this.currentlyHoveringEnable = false
    setTimeout(() => {
      if (!this.currentlyHoveringEnable) {
        this.handleCloseEnable()
      }
    }, 50)
  }

  @action
  public handleClickDisable(event) {
    this.setDisableMenuAnchorEl(event.currentTarget)
  }

  @action
  public handleHoverDisable() {
    this.currentlyHoveringDisable = true
  }

  @action
  public handleCloseDisable() {
    this.setDisableMenuAnchorEl(null)
  }

  @action
  public handleCloseHoverDisable() {
    this.currentlyHoveringDisable = false
    setTimeout(() => {
      if (!this.currentlyHoveringDisable) {
        this.handleCloseDisable()
      }
    }, 50)
  }

  @action
  public handleClickStartFlow(event) {
    this.setStartFlowMenuAnchorEl(event.currentTarget)
  }

  @action
  public handleHoverStartFlow() {
    this.currentlyHoveringStartFlow = true
  }

  @action
  public handleCloseStartFlow() {
    this.setStartFlowMenuAnchorEl(null)
  }

  @action
  public handleCloseHoverStartFlow() {
    this.currentlyHoveringStartFlow = false
    setTimeout(() => {
      if (!this.currentlyHoveringStartFlow) {
        this.handleCloseStartFlow()
      }
    }, 50)
  }

  @action nullAllAnchors() {
    this.removeMenuAnchorEl = null
    this.addMenuAnchorEl = null
    this.columnPopupAnchorEl = null
  }

  protected onGridReadied(): void {
    this.gridApi.setDatasource({
      rowCount: this.totalRecords,
      getRows: async (params) => {
        params.sortModel.forEach((col: { colId: string; sort: 'asc' | 'desc' }, idx: number) => {
          let dbCol = col.colId
          if (dbCol.includes('fullName')) dbCol = 'fk_User.lastName'
          if (dbCol.includes('username')) dbCol = 'fk_User.username'
          if (dbCol.includes('firstName')) dbCol = 'fk_User.firstName'
          if (dbCol.includes('lastName')) dbCol = 'fk_User.lastName'
          if (dbCol.includes('email')) dbCol = 'fk_User.email'
          if (dbCol.includes('emailNotifications')) dbCol = 'fk_User.receiveEmails'
          if (dbCol.includes('pushNotifications')) dbCol = 'fk_User.receivePushNotifications'
          if (dbCol.includes('textNotifications')) dbCol = 'fk_User.receiveTextNotifications'
          if (dbCol.includes('language')) dbCol = 'fk_User.languagePreference'
          if (dbCol.includes('phone')) dbCol = 'fk_User.phone'
          if (dbCol.includes('birthdate')) dbCol = 'fk_User.birthDate'
          if (dbCol.includes('welcome')) dbCol = 'welcomeEmailStatus'
          if (dbCol.includes('createdAt')) dbCol = 'createdAt'
          if (dbCol.includes('updatedAt')) dbCol = 'updatedAt'
          if (idx === 0) this.dataStore.setSort(dbCol, col.sort)
          if (idx > 0) this.dataStore.addSort(dbCol, col.sort)
        })
        // if (params.sortModel.length === 0) this.dataStore.setSort('fk_User.lastName')
        if (params.startRow === 0) {
          this.dataStore.setPage(0)
          this.loadingFirstPage = true
        }
        await this.dataStore.getNextPage()
        const rows = this.dataStore.organizationUsers.map(
          (e) => new OrganizationUsersListRowVM(this.rootStore, e, this)
        )
        this.rows = rows
        this.loadingFirstPage = false

        params.successCallback(rows, this.dataStore.totalRecords)
        if (this.selectAll) {
          this.gridApi.forEachNode((node) => {
            const unselected = this.unSelectedRows.filter((e) => e.userId === node.id)
            if (unselected.length > 0) return
            node.setSelected(true)
          })
        }
        setTimeout(() => this.setSelectedTotalShown(), 100)
      },
    })
  }

  private sizeColToContent(params) {
    setTimeout(() => {
      const colIds = params.columnApi
        .getAllColumns()
        .slice(1)
        .map((c) => c.colId)
      params.columnApi.autoSizeColumns(colIds)
    }, 500)
  }

  public getGridOptions(): GridOptions {
    return {
      ...this.baseGridOptions,
      rowData: this.rows,
      // onRowClicked: (e) => this.rowClicked(e),
      getRowNodeId: (e) => this.getRowNodeId(e),
      onFirstDataRendered: (params) => this.sizeColToContent(params),
      suppressRowClickSelection: true,
      suppressRowHoverHighlight: true,
      suppressCellSelection: false,
      rowSelection: 'multiple',
      onRowSelected: (e) => this.onRowSelected(e.node),
      columnDefs: this.ensureTooltipFields(this.columnDefs),
      pagination: false,
      frameworkComponents: {
        selectAll: SelectAll,
        datePickerCell: DatePickerCell,
        birthDatePickerCell: BirthDatePickerCell,
        nameCell: NameCell,
      },
      isRowSelectable: (rowNode) => {
        if (!rowNode.data) return false
        return !rowNode.data.isArchived
      },
      rowClassRules: {
        'row-highlighted': (params) => {
          if (!this.highlightedRow) return false
          return params.node.id === this.highlightedRow
        },
      },
      onCellEditingStopped: (event: CellEditingStoppedEvent) => {
        this.processEditEvents(event)
      },
      onCellValueChanged(params: any) {
        if (params.oldValue !== params.newValue) {
          this.tableHasBeenEdited = true
          params.colDef.cellStyle = (p) =>
            p.node.id === params.node.id ? { 'background-color': 'rgb(4,159,157,.5)' } : {}
          params.api.refreshCells({
            force: true,
            columns: [params.column.getId()],
            rowNodes: [params.node],
          })
        }
      },
    }
  }

  @computed
  public get columnDefs(): ColDef[] {
    const s = this.rootStore.localizationStore.lzStrings
    return [
      {
        width: 53,
        suppressSizeToFit: true,
        checkboxSelection: true,
        sortable: false,
        headerComponent: 'selectAll',
        headerComponentParams: { vm: this },
      },
      {
        headerName: 'First Name',
        headerTooltip: 'First Name',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.firstName),
        checkboxSelection: false,
        hide: !this.showFirstColumn,
        sortable: true,
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Last Name',
        headerTooltip: 'Last Name',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.lastName),
        checkboxSelection: false,
        hide: !this.showLastColumn,
        sortable: true,
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.fullName),
        minWidth: 160,
        suppressSizeToFit: true,
        checkboxSelection: false,
        tooltipField: 'null',
        hide: !this.showNameColumn,
        cellRenderer: 'nameCell',
      },
      {
        headerName: 'Email',
        minWidth: 200,
        suppressSizeToFit: true,
        headerTooltip: 'Email',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.email),
        hide: !this.showEmailColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Username',
        headerTooltip: 'Username',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.username),
        hide: !this.showUsernameColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Phone #',
        headerTooltip: 'Phone #',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.phone),
        hide: !this.showPhoneColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Roles',
        headerTooltip: 'Roles',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.roles),
        hide: !this.showRolesColumn,
        sortable: false,
      },
      {
        headerName: 'Groups',
        headerTooltip: 'Groups',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.groups),
        hide: !this.showGroupsColumn,
        sortable: false,
      },
      {
        headerName: 'Privilege Sets',
        headerTooltip: 'Privilege Sets',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.privilegeSets),
        hide: !this.showPrivilegeSetsColumn,
        sortable: false,
      },
      {
        headerName: 'Privileges',
        headerTooltip: 'Privileges',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.privileges),
        hide: !this.showPrivilegesColumn,
        sortable: false,
      },
      {
        headerName: 'Primary Role',
        headerTooltip: 'Primary Role',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.primaryRole),
        hide: !this.showPrimaryRoleColumn,
        sortable: true,
      },
      {
        headerName: 'Primary Group',
        headerTooltip: 'Primary Group',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.primaryGroup),
        hide: !this.showPrimaryGroupColumn,
        sortable: true,
      },
      {
        headerName: 'Title',
        headerTooltip: 'Title',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.title),
        hide: !this.showTitleColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Birthday',
        headerTooltip: 'Birthday',
        sortable: true,
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.birthDate),
        hide: !this.showBirthdayColumn,
        cellRenderer: 'birthDatePickerCell',
      },
      {
        headerName: 'Employee Id',
        headerTooltip: 'Employee Id',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.employeeId),
        hide: !this.showEmployeeIdColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Job Number',
        headerTooltip: 'Job Number',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.jobNumber),
        hide: !this.showJobNumberColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: {
          useFormatter: true,
          maxLength: 25,
        },
      },
      {
        headerName: 'Role Start Date',
        headerTooltip: 'Role Start Date',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.roleStartDate),
        hide: !this.showRoleStartDateColumn,
        cellRenderer: 'datePickerCell',
      },
      {
        headerName: 'Language',
        headerTooltip: 'Language',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.languagePreference),
        hide: !this.showLanguageColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['English', 'German', 'Pseudo'],
        },
      },
      {
        headerName: 'Email Notifications',
        headerTooltip: 'Email Notifications',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.receiveEmails),
        hide: !this.showEmailNotificationsColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
      },
      {
        headerName: 'Push Notifications',
        headerTooltip: 'Push Notifications',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.receivePushNotifications),
        hide: !this.showPushNotificationsColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
      },
      {
        headerName: 'Text Notifications',
        headerTooltip: 'Text Notifications',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.receiveTextMessages),
        hide: !this.showTextNotificationsColumn,
        editable: true,
        sortable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Yes', 'No'],
        },
      },
      {
        headerName: 'Has Consented',
        headerTooltip: 'Has Consented',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.hasConsented),
        hide: !this.showConsentColumn,
        sortable: true,
      },
      {
        headerName: 'Welcome Email Status',
        headerTooltip: 'Welcome Email Status',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.welcomeEmailStatus),
        hide: !this.showWelcomeColumn,
        sortable: true,
      },
      {
        headerName: 'Created At',
        headerTooltip: 'Created At',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.createdAt),
        hide: !this.showCreatedColumn,
        sortable: true,
      },
      {
        headerName: 'Updated At',
        headerTooltip: 'Updated At',
        field: nameOf<OrganizationUsersListRowVM, string>((e) => e.updatedAt),
        hide: !this.showUpdatedColumn,
        sortable: true,
      },
    ]
  }

  @action
  public toggleSendWelcomeConfirmDialog() {
    this.confirmSendDialogOpen = !this.confirmSendDialogOpen
  }

  @action
  public cancelSendWelcomeConfirmDialog() {
    this.confirmSendDialogOpen = false
  }

  @action
  public toggleExpirePasswordsConfirmDialog() {
    this.confirmExpirePasswordsDialogOpen = !this.confirmExpirePasswordsDialogOpen
  }

  @action
  public cancelExpirePasswordsConfirmDialog() {
    this.confirmExpirePasswordsDialogOpen = false
  }

  @action
  public toggleModifyMenu() {
    this.modifyMenuOpen = !this.modifyMenuOpen
  }

  @action
  public toggleArchiveUsersConfirmDialog() {
    this.archiveUsersDialogOpen = !this.archiveUsersDialogOpen
  }

  @action
  public cancelArchiveUsersConfirmDialog() {
    this.archiveUsersDialogOpen = false
  }

  @action
  public toggleDeleteUsersConfirmDialog() {
    this.deleteUsersDialogOpen = !this.deleteUsersDialogOpen
  }

  @action
  public toggleStartTrainingPlanFlowBeginnerConfirmDialog() {
    this.startTrainingPlanFlowBeginnerDialogOpen = !this.startTrainingPlanFlowBeginnerDialogOpen
  }

  @action
  public toggleStartTrainingPlanFlowIntermediateConfirmDialog() {
    this.startTrainingPlanFlowIntermediateDialogOpen = !this.startTrainingPlanFlowIntermediateDialogOpen
  }

  @action
  public toggleStartTrainingPlanFlowAdvancedConfirmDialog() {
    this.startTrainingPlanFlowAdvancedDialogOpen = !this.startTrainingPlanFlowAdvancedDialogOpen
  }
  
  @action
  public toggleCancelTrainingPlanFlowConfirmDialog() {
    this.cancelTrainingPlanFlowDialogOpen = !this.cancelTrainingPlanFlowDialogOpen
  }

  @action
  public cancelDeleteUsersConfirmDialog() {
    this.deleteUsersDialogOpen = false
  }

  @action
  public cancelStartTrainingPlanFlowBeginnerConfirmDialog() {
    this.startTrainingPlanFlowBeginnerDialogOpen = false
  }

  @action
  public cancelStartTrainingPlanFlowIntermediateConfirmDialog() {
    this.startTrainingPlanFlowIntermediateDialogOpen = false
  }

  @action
  public cancelStartTrainingPlanFlowAdvancedConfirmDialog() {
    this.startTrainingPlanFlowAdvancedDialogOpen = false
  }

  @action
  public cancelCancelTrainingPlanFlowConfirmDialog() {
    this.cancelTrainingPlanFlowDialogOpen = false
  }

  @action
  public closeParticipantPicker() {
    return this.setFilterPopupAnchorEl(null)
  }

  @action
  public closeColumnSelector() {
    return this.setColumnPopupAnchorEl(null)
  }

  @computed
  public get sendAll() {
    if (!this.selectAll) return false
    if (this.hasFilter) return false
    if (this.hasParticipants) return false
    if (this.totalRecords === this.newSelectedRows.length) return true
    return false
  }

  public toDTO(e): IParticipant {
    return {
      id: e.userId,
      type: e.type,
      name: `${e.firstName} ${e.lastName}`,
      email: e.email,
    }
  }

  public participantsToDTO(): IParticipant[] {
    return this.newSelectedRows.map((e) => this.toDTO(e))
  }

  public getParticipantsIds(): IParticipant[] {
    const idArray = []
    this.newSelectedRows.map((e) => idArray.push(e.userId))
    return idArray
  }

  @action
  public async sendWelcome() {
    const orgId = this.rootStore.appStore.currentOrgId
    let users = []
    let unselectedUsers = []
    this.isProcessing = true
    if (!this.selectAll)
      this.newSelectedRows.map((e) => {
        users.push(e.userId)
      })
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedUsers = await this.getUnselectedUsers()
    }
    this.toggleSendWelcomeConfirmDialog()
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: users,
        allSelected: this.selectAll,
        unselectedUserIds: unselectedUsers,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        showHidden: this.shouldShowHiddenUsers,
        showArchived: this.showArchivedUsers,
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.sendWelcomeEmail(req)
      if (result.success === true) {
        this.hardRefreshUsersList()
        this.parentVM.openSnackbar(`Success: Welcome Emails Sent`)
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async expirePasswords() {
    const orgId = this.rootStore.appStore.currentOrgId
    let users = []
    let unselectedUsers = []
    this.isProcessing = true
    if (!this.selectAll)
      this.newSelectedRows.map((e) => {
        users.push(e.userId)
      })
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedUsers = await this.getUnselectedUsers()
    }
    this.toggleExpirePasswordsConfirmDialog()
    this.isProcessing = true
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: users,
        allSelected: this.selectAll,
        unselectedUserIds: unselectedUsers,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        showHidden: this.shouldShowHiddenUsers,
        showArchived: this.showArchivedUsers,
      }
      const authSvc = new AuthenticationService()
      const result = await authSvc.expirePassword(req)
      if (result.success === true) {
        this.hardRefreshUsersList()
        this.parentVM.openSnackbar(`Success: Passwords have been expired`)
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public getRowNodeId(row): string {
    return row.objectId
  }

  @computed
  public get hasFilter(): boolean {
    return Boolean(this.typedFilterText)
  }

  public rowClicked(e: RowClickedEvent) {
    const row: OrganizationUsersListRowVM = e.data
    if (!row) return
    row.click()
  }
  @action
  selectAllNodes() {
    this.gridApi.forEachNode((node) => {
      node.setSelected(true)
    })
  }

  @action
  deSelectAllNodes() {
    this.hideCounts = true
    this.gridApi.forEachNode((node) => {
      node.setSelected(false)
    })
    setTimeout(() => (this.hideCounts = false), 100)
  }

  @action
  public toggleSelectAll() {
    this.selectAll = !this.selectAll
    if (this.selectAll) {
      this.selectAllNodes()
    }
    if (!this.selectAll) {
      this.deSelectAllNodes()
    }
    setTimeout(() => this.setSelectedTotalShown(), 100)
  }

  @computed public get allSelectedIndeterminate() {
    if (this.selectAll && !this.allSelected) return true
    return false
  }

  @computed public get allSelectedChecked() {
    if (this.selectAll && this.allSelected) return true
    return false
  }

  @computed public get allSelected() {
    if (!this.selectAll) return false
    if (this.hasFilter) return false
    if (this.hasParticipants) return false
    if (this.totalRecords === this.dialogCount) return true
    return false
  }

  @action
  public manageSelectedRows(node) {
    const selection = node.data.toDTO()
    let index

    if (selection.type === 'user') {
      index = this.newSelectedRows.findIndex((e) => e.userId === selection.userId)
    }
    if (selection.type === 'non-user') {
      index = this.newSelectedRows.findIndex((e) => e.email === selection.email)
    }
    if (index === -1) this.newSelectedRows.push(selection)
    else this.newSelectedRows.splice(index, 1)
  }

  @action
  public manageUnselectedRows(node) {
    if (!this.selectAll) return
    if (node.selected) return
    const selection = node.data.toDTO()
    let index

    if (selection.type === 'user') {
      index = this.unSelectedRows.findIndex((e) => e.userId === selection.userId)
    }
    if (selection.type === 'non-user') {
      index = this.unSelectedRows.findIndex((e) => e.email === selection.email)
    }
    if (index === -1) this.unSelectedRows.push(selection)
    else this.unSelectedRows.splice(index, 1)
  }

  @action
  public onRowSelected(node: RowNode) {
    if (this.isProcessing) return
    this.manageSelectedRows(node)
    this.manageUnselectedRows(node)
    this.calculateAllSelected()
  }

  @action
  resetSelected() {
    this.newSelectedRows = []
    this.unSelectedRows = []
  }

  @action
  public resetNodesSelected() {
    this.selectAll = false
    this.gridApi.deselectAll()
    setTimeout(() => this.resetSelected(), 100)
  }

  @action public calculateAllSelected() {
    if (!this.selectAll) return
    const diff = this.selectedTotalShown - this.newSelectedRows.length
    const result = this.totalRecords - diff
    this.calculatedSelected = result
  }

  @action public setSelectedTotalShown() {
    if (!this.selectAll) return
    if (this.newSelectedRows.length > this.selectedTotalShown) {
      this.selectedTotalShown = this.newSelectedRows.length
    }
    this.calculateAllSelected()
  }

  @computed
  public get showNameColumn() {
    return this.viewColumns.includes('name')
  }

  @computed
  public get showFirstColumn() {
    return this.viewColumns.includes('first')
  }

  @computed
  public get showLastColumn() {
    return this.viewColumns.includes('last')
  }

  @computed
  public get showEmailColumn() {
    return this.viewColumns.includes('email')
  }

  @computed
  public get showUsernameColumn() {
    return this.viewColumns.includes('username')
  }

  @computed
  public get showRolesColumn() {
    return this.viewColumns.includes('roles')
  }

  @computed
  public get showGroupsColumn() {
    return this.viewColumns.includes('groups')
  }

  @computed
  public get showPrimaryRoleColumn() {
    return this.viewColumns.includes('primaryRole')
  }

  @computed
  public get showPrimaryGroupColumn() {
    return this.viewColumns.includes('primaryGroup')
  }

  @computed
  public get showPhoneColumn() {
    return this.viewColumns.includes('phoneNumber')
  }

  @computed
  public get showTitleColumn() {
    return this.viewColumns.includes('title')
  }

  @computed
  public get showBirthdayColumn() {
    return this.viewColumns.includes('birthday')
  }

  @computed
  public get showEmployeeIdColumn() {
    return this.viewColumns.includes('employeeId')
  }

  @computed
  public get showJobNumberColumn() {
    return this.viewColumns.includes('jobNumber')
  }

  @computed
  public get showRoleStartDateColumn() {
    return this.viewColumns.includes('roleStartDate')
  }

  @computed
  public get showLanguageColumn() {
    return this.viewColumns.includes('language')
  }

  @computed
  public get showEmailNotificationsColumn() {
    return this.viewColumns.includes('emailNotifications')
  }

  @computed
  public get showPushNotificationsColumn() {
    return this.viewColumns.includes('pushNotifications')
  }

  @computed
  public get showTextNotificationsColumn() {
    return this.viewColumns.includes('textNotifications')
  }

  @computed
  public get showWelcomeColumn() {
    return this.viewColumns.includes('welcomeEmailStatus')
  }

  @computed
  public get showConsentColumn() {
    return this.viewColumns.includes('hasConsented')
  }

  @computed
  public get showPrivilegeSetsColumn() {
    return this.viewColumns.includes('privilegeSets')
  }

  @computed
  public get showPrivilegesColumn() {
    return this.viewColumns.includes('privileges')
  }

  @computed
  public get showCreatedColumn() {
    return this.viewColumns.includes('createdAt')
  }

  @computed
  public get showUpdatedColumn() {
    return this.viewColumns.includes('updatedAt')
  }

  @action
  public async saveColumns() {
    const orgId = this.rootStore.appStore.currentOrgId
    const userId = this.rootStore.appStore.currentUserId
    const sortField = this.dataStore.sortColumnName
    const sortOrder = this.dataStore.sortDirection
    this.toggleModifyMenu()
    this.isProcessing = true
    let fields = []
    this.viewColumns.map((e) => fields.push(e))
    try {
      const req: IOrganizationUsersListPreferencesSaveRequest = {
        organizationId: orgId,
        userId: userId,
        fields: fields,
        sortField: sortField,
        sortOrder: sortOrder,
      }
      const orgSvc = new OrganizationUsersListPreferencesService()
      const result = await orgSvc.saveOrganizationUsersListPreferences(req)
      if (result.success === false) {
        console.log(result.errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async getColumns() {
    const orgId = this.rootStore.appStore.currentOrgId
    const userId = this.rootStore.appStore.currentUserId
    try {
      const req: IOrganizationUsersListPreferencesFindRequest = {
        organizationId: orgId,
        userId: userId,
      }
      const orgSvc = new OrganizationUsersListPreferencesService()
      const result = await orgSvc.getOrganizationUsersListPreferences(req)
      if (result && result.fields) {
        this.viewColumns = result.fields
        if (result.sortField) {
          this.dataStore.sortColumnName = result.sortField
          this.dataStore.sortDirection = result.sortOrder ? result.sortOrder : 'asc'
        }
        this.refreshTable()
      }
      this.columnsLoaded = true
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public setQuickSave() {
    if (this.quickSaveTO) clearTimeout(this.quickSaveTO)
    this.quickSaveTO = setTimeout(() => this.saveColumns(), 1000)
  }

  @action
  public modifyViewColumn(value) {
    const column = value.trim()
    if (this.viewColumns.includes(column)) {
      this.viewColumns = this.viewColumns.filter((e) => e !== column)
    } else {
      this.viewColumns.push(column)
    }
    this.refreshTable()
    this.setQuickSave()
  }

  @action
  public toggleAddToGroupDialog() {
    this.addToGroupDialogOpen = !this.addToGroupDialogOpen
    if (!this.addToGroupDialogOpen) {
      this.selectedGroupsToAdd = []
      this.newGroupToAdd = ''
      this.addNew = false
    }
  }

  @action
  public toggleRemoveFromGroupDialog() {
    this.removeFromGroupDialogOpen = !this.removeFromGroupDialogOpen
    if (!this.removeFromGroupDialogOpen) {
      this.selectedGroupsToRemove = []
    }
  }

  @action
  public toggleAddToRoleDialog() {
    this.addToRoleDialogOpen = !this.addToRoleDialogOpen
    if (!this.addToRoleDialogOpen) {
      this.newRoleToAdd = ''
      this.selectedRolesToAdd = []
      this.addNew = false
    }
  }

  @action
  public toggleRemoveFromRoleDialog() {
    this.removeFromRoleDialogOpen = !this.removeFromRoleDialogOpen
    if (!this.removeFromRoleDialogOpen) {
      this.selectedRolesToRemove = []
    }
  }

  @action
  public toggleAddToPrivilegeDialog() {
    this.addToPrivilegeDialogOpen = !this.addToPrivilegeDialogOpen
    if (!this.addToPrivilegeDialogOpen) {
      this.selectedPrivilegeSetsToAdd = []
    }
  }

  @action
  public toggleRemoveFromPrivilegeDialog() {
    this.removeFromPrivilegeDialogOpen = !this.removeFromPrivilegeDialogOpen
    if (!this.removeFromPrivilegeDialogOpen) {
      this.selectedPrivilegeSetsToRemove = []
    }
  }

  @action
  public toggleAddNew() {
    this.addNew = !this.addNew
    this.selectedGroupsToAdd = []
    this.selectedRolesToAdd = []
  }

  @action
  public handleSelectedOption(option, type) {
    if (option.name === 'Add New...') return this.toggleAddNew()
    this.setSelectedOption(option, type)
  }

  @action
  public setSelectedOption(option, type) {
    if (type === 'role') {
      this.selectedRolesToAdd = []
      this.selectedRolesToAdd.push(option)
    }
    if (type === 'group') {
      this.selectedGroupsToAdd = []
      this.selectedGroupsToAdd.push(option)
    }
  }

  @action
  public setNewGroupToAdd(option) {
    if (option.name === 'Add New...') return this.toggleAddNew()
    this.newGroupToAdd = option
  }

  @action
  public setNewRoleToAdd(option) {
    if (option.name === 'Add New...') return this.toggleAddNew()
    this.newRoleToAdd = option
  }

  @action
  public setSelectedRolesToAdd(option) {
    this.selectedRolesToAdd = []
    this.selectedRolesToAdd.push(option)
  }

  @action
  public setSelectedRolesToRemove(option) {
    this.selectedRolesToRemove = []
    this.selectedRolesToRemove.push(option)
  }

  @action
  public setSelectedGroupsToRemove(option) {
    this.selectedGroupsToRemove = []
    this.selectedGroupsToRemove.push(option)
  }

  @action
  public setSelectedPrivilegeSetsToAdd(option) {
    this.selectedPrivilegeSetsToAdd = []
    this.selectedPrivilegeSetsToAdd.push(option)
  }

  @action
  public setSelectedPrivilegeSetsToRemove(option) {
    this.selectedPrivilegeSetsToRemove = []
    this.selectedPrivilegeSetsToRemove.push(option)
  }

  @computed
  public get groupOptions() {
    let groups = []
    this.rootStore.groupsStore.groups.forEach((group) => groups.push(group))
    groups.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    return groups
  }

  @computed
  public get roleOptions() {
    let roles = []
    this.rootStore.rolesStore.roles.forEach((role) => roles.push(role))
    roles.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    })
    return roles
  }

  @computed
  public get addGroupOptions() {
    let groups = this.groupOptions
    groups.push({ name: 'Add New...' } as IGroupDTO)
    return groups
  }

  @computed
  public get addRoleOptions() {
    let roles = this.roleOptions
    roles.push({ name: 'Add New...' } as IRoleDTO)
    return roles
  }

  @computed
  public get privilegeSetsOptions() {
    let privilegeSets = []
    this.rootStore.privilegeSetsStore.privilegeSets.forEach((privilegeSet) =>
      privilegeSets.push(privilegeSet)
    )
    return privilegeSets
  }

  @action
  public clearFilters() {
    this.participantsSelectVM.clearParticipants()
    this.applyParticipantSelections()
    this.setQuickFilter('')
  }

  @action
  public closeAllBulkDialogs() {
    this.addToGroupDialogOpen = false
    this.removeFromGroupDialogOpen = false
    this.addToRoleDialogOpen = false
    this.removeFromRoleDialogOpen = false
    this.addToPrivilegeDialogOpen = false
    this.removeFromPrivilegeDialogOpen = false
    this.enableEmailNotificationsDialogOpen = false
    this.disableEmailNotificationsDialogOpen = false
    this.enableTextNotificationsDialogOpen = false
    this.disableTextNotificationsDialogOpen = false
    this.enablePushNotificationsDialogOpen = false
    this.disablePushNotificationsDialogOpen = false
  }

  @action
  public resetBulkOperationsSelections() {
    this.selectedGroupsToAdd = []
    this.selectedRolesToAdd = []
    this.selectedPrivilegeSetsToAdd = []
    this.selectedGroupsToRemove = []
    this.selectedRolesToRemove = []
    this.selectedPrivilegeSetsToRemove = []
    this.addNew = false
    this.newGroupToAdd = ''
    this.newRoleToAdd = ''
    this.enableEmailNotifications = false
    this.disableEmailNotifications = false
    this.enableTextNotifications = false
    this.disableTextNotifications = false
    this.enablePushNotifications = false
    this.disablePushNotifications = false
  }

  @computed
  get termsValue() {
    if (!this.selectAll) return undefined
    if (!this.typedFilterText) return undefined
    return this.typedFilterText
  }

  @computed
  get filterValue() {
    if (!this.selectAll) return undefined
    if (!this.participants) return undefined
    return this.participants
  }

  @action
  async getUnselectedUsers() {
    let unselectedUsers = []
    this.unSelectedRows.map((e) => {
      unselectedUsers.push(e.userId)
    })
    return unselectedUsers
  }

  @action
  public async saveOptionToUsers() {
    const orgId = this.rootStore.appStore.currentOrgId
    const userId = this.rootStore.appStore.currentUserId
    let users = []
    let unselectedUsers = []
    this.isProcessing = true
    if (!this.selectAll)
      this.newSelectedRows.map((e) => {
        users.push(e.userId)
      })
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedUsers = await this.getUnselectedUsers()
    }
    let addGroupIds: Array<string> = [],
      addRoleIds = [],
      addPrivilegeIds = [],
      removeGroupIds = [],
      removeRoleIds = [],
      removePrivilegeIds = []
    if (this.selectedGroupsToAdd) {
      this.selectedGroupsToAdd.forEach((e) => addGroupIds.push(e.objectId))
    }
    if (this.selectedGroupsToRemove) {
      this.selectedGroupsToRemove.forEach((e) => removeGroupIds.push(e.objectId))
    }
    if (this.selectedRolesToAdd) {
      this.selectedRolesToAdd.forEach((e) => addRoleIds.push(e.objectId))
    }
    if (this.selectedRolesToRemove) {
      this.selectedRolesToRemove.forEach((role) => removeRoleIds.push(role.objectId))
    }
    if (this.selectedPrivilegeSetsToAdd) {
      this.selectedPrivilegeSetsToAdd.forEach((role) => addPrivilegeIds.push(role.objectId))
    }
    if (this.selectedPrivilegeSetsToRemove) {
      this.selectedPrivilegeSetsToRemove.forEach((role) => removePrivilegeIds.push(role.objectId))
    }
    try {
      const req: IOrganizationUsersBulkOperationsRequest = {
        orgId: orgId,
        editingUserId: userId,
        allSelected: this.selectAll,
        selectedUserIds: users,
        unselectedUserIds: unselectedUsers,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        showHidden: this.shouldShowHiddenUsers,
        showArchived: this.showArchivedUsers,
        addRoles: addRoleIds.length > 0 ? addRoleIds : undefined,
        addGroups: addGroupIds.length > 0 ? addGroupIds : undefined,
        addPrivilegeSets: addPrivilegeIds.length > 0 ? addPrivilegeIds : undefined,
        removeRoles: removeRoleIds.length > 0 ? removeRoleIds : undefined,
        removeGroups: removeGroupIds.length > 0 ? removeGroupIds : undefined,
        removePrivilegeSets: removePrivilegeIds.length > 0 ? removePrivilegeIds : undefined,
        newGroupToAdd: this.newGroupToAdd.length ? this.newGroupToAdd : '',
        newRoleToAdd: this.newRoleToAdd.length ? this.newRoleToAdd : '',
        enableEmailNotifications: this.enableEmailNotifications,
        disableEmailNotifications: this.disableEmailNotifications,
        enableTextNotifications: this.enableTextNotifications,
        disableTextNotifications: this.disableTextNotifications,
        enablePushNotifications: this.enablePushNotifications,
        disablePushNotifications: this.disablePushNotifications,
      }
      const svc = new OrganizationUsersBulkOperationsService()
      const result = await svc.saveToOrganizationUsersBulkOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.closeAllBulkDialogs()
        this.resetBulkOperationsSelections()
        this.parentVM.openSnackbar(
          `Success: Your bulk operation has been submitted for processing.  This may take a few minutes.`
        )
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public resetCellColors() {
    // this.showTable = false
    // setTimeout(() => (this.showTable = true), 50)
    this.refreshTable()
  }

  @action
  public processEditEvents(event) {
    const userId = event.data.aggregate.userId
    const field = event.colDef.field
    let newValue = event.value
    if (
      field === 'receiveEmails' ||
      field === 'receiveTextMessages' ||
      field === 'receivePushNotifications'
    ) {
      newValue = Boolean(newValue === 'Yes')
    }
    this.addToEditedRowEventsArray(userId, field, newValue)
  }

  @action
  public addToEditedRowEventsArray(userId, field, value) {
    this.tableHasBeenEdited = true
    const orgId = this.rootStore.appStore.currentOrgId
    const index = this.editedRowEvents.findIndex((obj) => obj.userId === userId)
    if (index !== -1) {
      const newData = [...this.editedRowEvents]
      newData[index] = { ...newData[index], [field]: value }
      this.editedRowEvents = newData
    } else {
      const editEvent = {
        organizationId: orgId,
        userId: userId,
        [field]: value,
      }
      this.editedRowEvents.push(editEvent)
    }
    this.gridApi.refreshCells({ force: true })
  }

  @action
  public cancelTableEdits() {
    this.tableHasBeenEdited = false
    this.editedRowEvents = []
    this.resetCellColors()
  }

  @action
  public async saveTableEdits() {
    const orgId = this.rootStore.appStore.currentOrgId
    const currentUserId = this.rootStore.appStore.currentUserId
    this.tableHasBeenEdited = false
    if (!this.editedRowEvents) return
    const newEventsArray = []
    this.editedRowEvents.forEach((e) => newEventsArray.push(e))
    try {
      const req: IOrganizationUsersEditOperationsRequest = {
        orgId: orgId,
        editingUserId: currentUserId,
        editedRowEvents: newEventsArray,
      }
      const svc = new OrganizationUsersBulkOperationsService()
      const result = await svc.saveOrganizationUsersTableEditsOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(
          `Success: Your table edit operation has been submitted for processing.  This may take a few minutes.`
        )
        this.resetCellColors()
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async archiveUsers() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    let unselectedUsers = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedUsers = await this.getUnselectedUsers()
    }
    this.toggleArchiveUsersConfirmDialog()
    this.isProcessing = true
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: selectedParticipantIds,
        allSelected: this.selectAll,
        unselectedUserIds: unselectedUsers,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        showHidden: this.shouldShowHiddenUsers,
        showArchived: this.showArchivedUsers,
      }
      const svc = new OrganizationUsersBulkOperationsService()
      const result = await svc.archiveUsersOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Archive`)
        this.hardRefreshUsersList()
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async deleteUsers() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    let unselectedUsers = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    if (this.selectAll && this.dialogCount !== this.totalRecords) {
      unselectedUsers = await this.getUnselectedUsers()
    }
    this.toggleDeleteUsersConfirmDialog()
    this.isProcessing = true
    try {
      const req: IOrganizationUsersBulkRequest = {
        orgId: orgId,
        selectedUserIds: selectedParticipantIds,
        allSelected: this.selectAll,
        unselectedUserIds: unselectedUsers,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        showHidden: this.shouldShowHiddenUsers,
        showArchived: this.showArchivedUsers,
      }
      const svc = new OrganizationUsersBulkOperationsService()
      const result = await svc.deleteUsersOperation(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Deletion`)
        this.hardRefreshUsersList()
      } else if (result.success === false) {
        console.log(result.errorMessage)
        this.parentVM.openSnackbar(`Error: Something went wrong.`)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async startTrainingPlanFlowBeginner() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    this.toggleStartTrainingPlanFlowBeginnerConfirmDialog()
    this.isProcessing = true
    try {
      const req: ITrainingPlanFlowsUsersBulkOpsRequest = {
        organizationId: orgId,
        selectedUserIds: selectedParticipantIds,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        allSelected: this.selectAll,
        competencyType: 'beginner',
      }
      const svc = new TrainingPlanFlowBulkOperationsService()
      const result = await svc.startTrainingPlanFlowUsersBulkOps(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Beginner (L1) Training Plan Flow Start`)
      } else if (result.success === false) {
        const errorMessage = result.errorMessage ? result.errorMessage : 'Something went wrong.'
        console.log(errorMessage)
        this.parentVM.openSnackbar(errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async startTrainingPlanFlowIntermediate() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    this.toggleStartTrainingPlanFlowIntermediateConfirmDialog()
    this.isProcessing = true
    try {
      const req: ITrainingPlanFlowsUsersBulkOpsRequest = {
        organizationId: orgId,
        selectedUserIds: selectedParticipantIds,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        allSelected: this.selectAll,
        competencyType: 'intermediate',
      }
      const svc = new TrainingPlanFlowBulkOperationsService()
      const result = await svc.startTrainingPlanFlowUsersBulkOps(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Intermediate (L2) Training Plan Flow Start`)
      } else if (result.success === false) {
        const errorMessage = result.errorMessage ? result.errorMessage : 'Something went wrong.'
        console.log(errorMessage)
        this.parentVM.openSnackbar(errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async startTrainingPlanFlowAdvanced() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    this.toggleStartTrainingPlanFlowAdvancedConfirmDialog()
    this.isProcessing = true
    try {
      const req: ITrainingPlanFlowsUsersBulkOpsRequest = {
        organizationId: orgId,
        selectedUserIds: selectedParticipantIds,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        allSelected: this.selectAll,
        competencyType: 'advanced',
      }
      const svc = new TrainingPlanFlowBulkOperationsService()
      const result = await svc.startTrainingPlanFlowUsersBulkOps(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Advanced (OPT) Training Plan Flow Start`)
      } else if (result.success === false) {
        const errorMessage = result.errorMessage ? result.errorMessage : 'Something went wrong.'
        console.log(errorMessage)
        this.parentVM.openSnackbar(errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public async cancelTrainingPlanFlow() {
    const orgId = this.rootStore.appStore.currentOrgId
    let selectedParticipantIds = []
    if (!this.selectAll) selectedParticipantIds = this.getParticipantsIds()
    this.toggleCancelTrainingPlanFlowConfirmDialog()
    this.isProcessing = true
    try {
      const req: ITrainingPlanFlowsUsersBulkOpsRequest = {
        organizationId: orgId,
        selectedUserIds: selectedParticipantIds,
        filterParticipants: this.filterValue,
        filterTerms: this.termsValue,
        allSelected: this.selectAll,
      }
      const svc = new TrainingPlanFlowBulkOperationsService()
      const result = await svc.cancelTrainingPlanFlowUsersBulkOps(req)
      this.isProcessing = false
      if (result.success === true) {
        this.parentVM.openSnackbar(`Success: Users have been queued for Training Plan Flow Cancel`)
      } else if (result.success === false) {
        const errorMessage = result.errorMessage ? result.errorMessage : 'Something went wrong.'
        console.log(errorMessage)
        this.parentVM.openSnackbar(errorMessage)
      }
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }

  @action
  public toggleViewHiddenUsers() {
    this.shouldShowHiddenUsers = !this.shouldShowHiddenUsers
    this.moreMenuAnchorEl = null
    this.dataStore.showHidden = this.shouldShowHiddenUsers
    this.refresh()
  }

  @action
  public toggleShowArchivedUsers() {
    this.showArchivedUsers = !this.showArchivedUsers
    this.moreMenuAnchorEl = null
    this.dataStore.showArchived = this.showArchivedUsers
    this.refresh()
  }

  @action
  public openUserImport() {
    this.rootStore.appStore.router.push('dashboard/tenantAdmin/userImport')
  }

  @action
  public openUserDrawer(orgUserId) {
    this.rootStore.organizationUsersStore.loadEditVM(orgUserId)
  }

  @action
  public setHighlightedRow(id) {
    this.highlightedRow = id
  }

  @action
  public openNewUser() {
    this.rootStore.organizationUsersStore.loadEditVMForNewUser()
  }

  @action
  public toggleEnableEmailNotificationsDialog() {
    this.enableEmailNotificationsDialogOpen = !this.enableEmailNotificationsDialogOpen
    if (this.enableEmailNotificationsDialogOpen) this.enableEmailNotifications = true
  }

  @action
  public toggleDisableEmailNotificationsDialog() {
    this.disableEmailNotificationsDialogOpen = !this.disableEmailNotificationsDialogOpen
    if (this.disableEmailNotificationsDialogOpen) this.disableEmailNotifications = true
  }

  @action
  public toggleEnableTextNotificationsDialog() {
    this.enableTextNotificationsDialogOpen = !this.enableTextNotificationsDialogOpen
    if (this.enableTextNotificationsDialogOpen) this.enableTextNotifications = true
  }

  @action
  public toggleDisableTextNotificationsDialog() {
    this.disableTextNotificationsDialogOpen = !this.disableTextNotificationsDialogOpen
    if (this.disableTextNotificationsDialogOpen) this.disableTextNotifications = true
  }

  @action
  public toggleEnablePushNotificationsDialog() {
    this.enablePushNotificationsDialogOpen = !this.enablePushNotificationsDialogOpen
    if (this.enablePushNotificationsDialogOpen) this.enablePushNotifications = true
  }

  @action
  public toggleDisablePushNotificationsDialog() {
    this.disablePushNotificationsDialogOpen = !this.disablePushNotificationsDialogOpen
    if (this.disablePushNotificationsDialogOpen) this.disablePushNotifications = true
  }

  @action
  public cancelEnableEmailNotificationsDialog() {
    this.enableEmailNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public cancelDisableEmailNotificationsDialog() {
    this.disableEmailNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public cancelEnableTextNotificationsDialog() {
    this.enableTextNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public cancelDisableTextNotificationsDialog() {
    this.disableTextNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public cancelEnablePushNotificationsDialog() {
    this.enablePushNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public cancelDisablePushNotificationsDialog() {
    this.disablePushNotificationsDialogOpen = false
    this.resetBulkOperationsSelections()
  }

  @action
  public async refreshUsersInDW() {
    this.isProcessing = true
    const orgId = this.rootStore.appStore.currentOrgId
    this.moreMenuAnchorEl = null
    try {
      const svc = new OrganizationUsersBulkOperationsService()
      const result = await svc.refreshUsersInDW(orgId)
      this.isProcessing = false
    } catch (error) {
      console.log(error)
    }
  }
}
