import { action, computed, observable } from 'mobx'
import moment from 'moment'
import { RootStore } from '../../../../stores/RootStore'
import { IPulseCampaignDTO } from '../../../dto/IPulseCampaignDTO'
import { PulseCampaignStatus } from '../../../type/PulseCampaignStatus'
import { TimelineSurveyVM } from './TimelineSurveyVM'
import { CampaignsListVM } from './CampaignsListVM'
import { ICampaignStatsDTO } from '../../../dto/ICampaignStatsDTO'

export class CampaignRowVM {
  private rootStore: RootStore

  constructor(
    rootStore: RootStore,
    campaign: IPulseCampaignDTO,
    parentVM: CampaignsListVM,
    isDetailsModal: boolean = false
  ) {
    this.rootStore = rootStore
    this.campaign = campaign
    this.parentVM = parentVM
    this.isDetailsModal = isDetailsModal
  }

  @observable public campaign: IPulseCampaignDTO = null
  @observable public isExpanded: boolean = false
  @observable public parentVM: CampaignsListVM = null
  @observable public isDetailsModal: boolean = false

  @computed
  public get objectId(): string {
    return this.campaign.objectId
  }

  @computed
  public get hasVizPermission(): boolean {
    if (this.rootStore.appStore.isSystemAdmin) return true
    return this.campaign.ownerUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get lzStrings() {
    return this.rootStore.localizationStore.lzStrings
  }

  @computed
  public get stats(): ICampaignStatsDTO {
    return this.campaign.stats
  }

  @computed
  public get polarityStatus() {
    if (!this.stats) return null
    if (!this.stats.averagePolarityScore && !this.stats.responses) return null
    if (this.stats.averagePolarityScore === 0) return 'neutral'
    if (this.stats.averagePolarityScore >= 0.5) return 'positive'
    if (this.stats.averagePolarityScore < 0) return 'negative'
    if (this.stats.averagePolarityScore < 0.5) return 'neutral'
  }

  @computed
  public get polarity() {
    if (!this.stats) return '-'
    if (!this.stats.averagePolarityScore && !this.stats.responses) return '-'
    return `${String(Math.round(this.stats.averagePolarityScore * 100))}`
  }

  @computed
  public get status(): PulseCampaignStatus {
    return this.campaign.status
  }

  @computed
  public get title(): string {
    if (this.campaign.name.length > 14) return this.campaign.name.substring(0, 16) + '...'
    return this.campaign.name
  }

  @computed
  public get startDate(): string {
    return `${moment(this.campaign.startDate).format('ll')}`
  }

  @computed
  public get endDate(): string {
    return `${moment(this.campaign.endDate).format('ll')}`
  }

  @computed
  public get surveys(): TimelineSurveyVM[] {
    return this.campaign.surveys.map((s, i) => new TimelineSurveyVM(this.rootStore, s, i + 1, this))
  }

  @computed
  public get sentCount(): number {
    const sent = this.surveys.filter((s) => s.status === 'sent').length
    const closed = this.surveys.filter((s) => s.status === 'closed').length
    return sent + closed
  }

  @computed
  public get surveysCount(): number {
    return this.surveys.length
  }

  @computed
  public get sentPercentage(): string {
    const val = this.sentCount / this.surveysCount
    return `${String(Math.round(val * 100))}%`
  }

  @computed
  public get responsesText(): string {
    if (!this.stats) return '-'
    if (!this.stats.responses) return '-'
    return `${this.responseCount} of ${this.invitationsCount} (${this.responsesPercentage})`
  }

  @computed
  public get responseCount(): number {
    if (!this.stats) return 0
    if (!this.stats.responses) return 0
    return this.stats.responses
  }

  @computed
  public get invitationsCount(): number {
    if (!this.stats) return 0
    if (!this.stats.invitations) return 0
    return this.stats.invitations
  }

  @computed
  public get responsesPercentage(): string {
    const val = this.responseCount / this.invitationsCount
    if (!val) return '--'
    return `${String(Math.round(val * 100))}%`
  }

  @computed
  public get activeSurveyIndex(): number {
    if (!this.isDetailsModal) return null
    return this.parentVM.activeSurveyIndex
  }

  @computed
  public get type() {
    let pc = this.rootStore.pulseCampaignTypesStore.pulseCampaignTypes.find((e) => e.objectId === this.campaign.pulseCampaignTypeId)
    if (!pc) return '--'
    if (this.rootStore.pulseCampaignTypesStore.isLoaded) return pc.name
  }

  @computed
  public get owner() {
    if (!this.campaign.fk_owner) return '--'
    return this.campaign.fk_owner.name
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }

  @action
  public editPulseCampaign() {
    this.rootStore.appStore.router.push(`/pulse/v1/edit/${this.objectId}`)
  }

  @action
  public copyPulseCampaign() {
    this.rootStore.appStore.router.push(`/pulse/v1/fromCopy/${this.objectId}`)
  }

  @action
  public editPulseAnalysis() {
    this.rootStore.appStore.router.push(`/pulse/v1/analysis/${this.objectId}`)
  }

  @action
  public openDetails(index?) {
    const activeIndex = index ? index : 0
    this.parentVM.openDetailsModal(this.objectId, activeIndex)
  }

  @action
  public closeDetails() {
    this.parentVM.closeDetailsModal()
  }
}
