import { action, observable, computed, reaction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { TrainingItem } from '../aggregate/TrainingItem'
import { CategoryPickerVM } from '../../shared/category-picker/CategoryPickerVM'
import { ParticipantsSelectVM } from '../../participants-select/view-models/ParticipantsSelectVM'
import { ContentItem } from '../aggregate/ContentItem'
import { ContentItemEditVM } from './ContentItemEditVM'
import { TrainingItemsService } from '../service/TrainingItemsService'
import { ContentItemType } from '../types/ContentItemType'
import { ParticipantVM } from '../../participants-select/view-models/ParticipantVM'
import { QuizVM } from '../../quiz/view-models/QuizVM'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { IApplicableParticipantDTO } from '../dto/IApplicableParticipantDTO'

export class TrainingItemEditVM {
  private rootStore: RootStore
  public trainingItem: TrainingItem
  public categoryPickerVM: CategoryPickerVM
  public participantsSelectVM: ParticipantsSelectVM
  public svc: TrainingItemsService

  constructor(rootStore: RootStore, trainingItem: TrainingItem) {
    this.rootStore = rootStore
    this.trainingItem = trainingItem
    if (!this.trainingItem.quiz) this.trainingItem.quiz = Quiz.create()
    this.categoryPickerVM = new CategoryPickerVM(this.rootStore)
    this.participantsSelectVM = new ParticipantsSelectVM(rootStore, false, false)
    this.svc = new TrainingItemsService()
    if (this.trainingItem.contentItems) this.currentContentItemVM = this.contentItemVMs[0]
    if (trainingItem.categories) {
      this.categoryPickerVM.selectedCategoryIds = trainingItem.categories
      this.categoryPickerVM.setAppliedCategories()
    }
    if (this.trainingItem.applicableParticipants) {
      this.selectedRolesAndGroups = this.trainingItem.applicableParticipants
    }

    // Reset completion in training items that have certificate uploads required but certificate uploads are currently disbaled
    if (
      this.trainingItem.requireProofOfCompletion &&
      this.trainingItem.requireCertificate &&
      this.isCertificateUploadDisabled
    ) {
      if (!this.trainingItem.requireQuiz) {
        this.trainingItem.requireCertificate = false
        this.trainingItem.requireProofOfCompletion = false
      } else if (this.trainingItem.requireQuiz) {
        this.trainingItem.requireCertificate = false
      }
    }

    reaction(
      () => JSON.stringify(this.trainingItem),
      () => this.setIsDirty()
    )
  }

  @observable public saveTried: boolean = false
  @observable public currentContentItemVM: ContentItemEditVM = null
  @observable public currentTab: number = 0
  @observable public newIndex: number = 0
  @observable public oldIndex: number = 0
  @observable public builderDrawerOpen: boolean = true
  @observable public selectedRolesAndGroups: IApplicableParticipantDTO[] = []
  @observable public toolbarAnchorEl: any = null
  @observable public showDeleteDialog: boolean = false
  @observable public isDirty: boolean = false
  @observable public isOpen: boolean = false

  @computed
  public get isCertificateUploadDisabled(): boolean {
    return true
  }

  @computed
  public get isCreatingFromModal(): boolean {
    return this.rootStore.appStore.router.location.pathname.startsWith('/trainingplans/edit')
  }

  @action
  public setIsDirty() {
    this.isDirty = true
  }

  @action
  public setNewIndex(value) {
    this.newIndex = value
  }

  @action
  public setOldIndex(value) {
    this.oldIndex = value
  }

  @action
  public moveContent() {
    let contentObj = this.trainingItem.contentItems.splice(this.oldIndex, 1)
    this.trainingItem.contentItems.splice(this.newIndex, 0, contentObj[0])
    setTimeout(() => this.openContentItem(this.trainingItem.contentItems[this.newIndex].id), 100)
  }

  @computed
  public get objectId(): string {
    return this.trainingItem.objectId
  }

  @computed
  public get name(): string {
    return this.trainingItem.name
  }

  @computed
  public get organizationId(): string {
    return this.trainingItem.organizationId
  }

  @computed
  public get quiz(): QuizVM {
    if (!this.trainingItem.quiz) return null
    return new QuizVM(this.rootStore, this.trainingItem.quiz)
  }

  @action
  public setToolbarAnchorEl(e: any) {
    this.toolbarAnchorEl = e
  }

  @action
  public setName(val: string) {
    this.trainingItem.setName(val)
  }

  @action
  public setBlock(val: string) {
    this.trainingItem.setBlock(val)
  }

  @computed
  public get description(): string {
    return this.trainingItem.description
  }

  @action
  public setDescription(val: string) {
    this.trainingItem.setDescription(val)
  }

  @computed
  public get contentCompletedInOrder(): boolean {
    return this.trainingItem.mustCompleteInOrder
  }

  @action
  public toggleMustCompletedInOrder() {
    this.trainingItem.toggleMustCompleteInOrder()
  }

  @computed
  public get requireProofOfCompletion(): boolean {
    return this.trainingItem.requireProofOfCompletion
  }

  @computed
  public get requireCertificate(): boolean {
    if (this.isCertificateUploadDisabled) {
      return false
    } else {
      return this.trainingItem.requireCertificate
    }
  }

  @computed
  public get requireQuiz(): boolean {
    return this.trainingItem.requireQuiz
  }

  @action
  public toggleRequireCompletionWithOptions() {
    this.trainingItem.toggleRequireCompletion()
  }

  @action
  public toggleRequireCompletionWithoutOptions() {
    this.trainingItem.toggleRequireCompletion()
    this.toggleRequireQuiz()
  }

  @action
  public toggleRequireQuiz() {
    this.trainingItem.toggleRequireQuiz()
  }

  @action
  public toggleRequireCertificate() {
    this.trainingItem.toggleRequireCertificate()
  }

  @computed
  public get categories(): string[] {
    return this.trainingItem.categories
  }

  @action
  public setCategories(val: string[]) {
    this.trainingItem.setCategories(val)
  }

  @computed
  public get contentItemVMs(): ContentItemEditVM[] {
    return this.trainingItem.contentItems.map(
      (content, index) => new ContentItemEditVM(this.rootStore, content, this, index)
    )
  }

  @computed
  public get detailsTabValid(): boolean {
    if (!this.categoriesValid || !this.nameValid) return false
    return true
  }

  @action
  public setCurrentTab(num: number) {
    this.currentTab = num
  }

  @action
  public removeCategory(id: string) {
    this.trainingItem.categories = this.trainingItem.categories.filter((cat) => cat !== id)
  }

  @action
  public continue() {
    if (this.currentTab < 1) {
      this.currentTab++
    } else {
      this.save()
    }
  }

  @computed
  public get hasContent(): boolean {
    if (this.contentItemVMs.length === 0 && this.saveTried) return false
    return true
  }

  @computed
  public get allContentValid(): boolean {
    const contentWithErrors = this.contentItemVMs.filter((content) => !content.isValid)
    if (contentWithErrors.length > 0 && this.saveTried) {
      return false
    } else {
      return true
    }
  }

  @computed
  public get builderTabValid(): boolean {
    if (!this.allContentValid || !this.hasContent || !this.categoriesValid) return false
    return true
  }

  @computed
  public get availableRolesAndGroups() {
    return this.participantsSelectVM.availableParticipants
      .filter(
        (participant) =>
          participant.id !== this.rootStore.appStore.currentUserId &&
          participant.type !== 'user' &&
          participant.type !== 'client' &&
          participant.type !== 'from-event'
      )
      .sort((a, b) => (a.sortNameWithType < b.sortNameWithType ? -1 : 0))
  }

  @action
  public setSelectedRolesAndGroups(arr: IApplicableParticipantDTO[]) {
    this.selectedRolesAndGroups = arr
    this.trainingItem.setRolesAndGroups(arr)
  }

  @action
  public openBuilderDrawer() {
    this.builderDrawerOpen = true
  }

  @action
  public closeBuilderDrawer() {
    this.builderDrawerOpen = false
  }

  @action
  public openContentItem(id: string) {
    this.closeBuilderDrawer()
    this.currentContentItemVM = this.contentItemVMs.filter(
      (contentItem) => contentItem.id === id
    )[0]
    this.openBuilderDrawer()
  }

  @action
  public deleteContentItem(id: string) {
    this.trainingItem.deleteContentItem(id)
    if (this.contentItemVMs[0])
      setTimeout(() => this.openContentItem(this.contentItemVMs[0].id), 100)
  }

  @action
  public duplicateContentItem(content: ContentItem) {
    this.trainingItem.duplicateContentItem(content.id)
  }

  @action
  public addContentItem(type: ContentItemType) {
    this.saveTried = false
    const id = this.trainingItem.addContentItem(type)
    this.openContentItem(id)
  }

  @computed
  public get nameValid(): boolean {
    if (!this.name && this.saveTried) return false
    return true
  }

  @computed
  public get categoriesValid(): boolean {
    // if (this.categories.length === 0 && this.saveTried) return false
    return true
  }

  @computed
  public get saveValid(): boolean {
    if (!this.categoriesValid) return false
    if (!this.nameValid) return false
    if (!this.allContentValid) return false
    if (!this.hasContent) return false
    if (!this.quizValid) return false
    return true
  }

  @computed
  public get quizValid(): boolean {
    if (!this.proofOfCompletionValid) return false
    if (!this.requireQuiz) return true
    if (!this.saveTried) return true
    let isValid = true
    this.quiz.questions.forEach((question) => {
      if (question.titleError || question.optionsError) isValid = false
    })
    return isValid
  }

  @computed
  public get proofOfCompletionValid(): boolean {
    if (this.requireProofOfCompletion && this.saveTried) {
      if (this.requireCertificate) return true
      if (this.requireQuiz) return true
      return false
    }
    return true
  }

  @action
  public toggleDeleteDialog() {
    this.showDeleteDialog = !this.showDeleteDialog
  }

  @action
  public delete() {
    this.svc.deleteTrainingItem(this.objectId, this.organizationId)
    this.rootStore.appStore.router.push('/dashboard/impact/catalog')
  }

  @action
  public checkLoadExerciseSets() {
    if (!this.trainingItem) return
    if (!Array.isArray(this.trainingItem.contentItems.slice())) return
    this.trainingItem.contentItems.forEach((item) => item.loadSets())
  }

  @action
  public async save() {
    this.saveTried = true
    if (this.requireQuiz) this.quiz.checkQuizErrors()
    this.checkLoadExerciseSets()
    if (!this.saveValid) return
    const dto = this.trainingItem.serialize()
    if (!dto.requireQuiz) dto.quiz = null
    await this.svc.saveTrainingItem(dto)
    this.isDirty = false
    if (this.rootStore.appStore.router.location.pathname.startsWith('/trainingplans/')) {
      this.rootStore.trainingPlansStore.trainingPlanEditVM.toggleItemModalOpen()
    } else {
      this.rootStore.appStore.router.push('/dashboard/impact/catalog')
    }
  }
}
