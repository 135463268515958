import React from 'react'
import clsx from 'clsx'
import { inject, observer } from 'mobx-react'
import { Button, CssBaseline, Grid, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { GrandCategoryTabVM } from '../../view-models/tree/GrandCategoryTabVM'
import { PulseCategoriesManageVM } from '../../view-models/PulseCategoriesManageVM'
import { GrandParentCategoryRowVM } from '../../view-models/tree/GrandParentCategoryRowVM'
import { CategoryQuestionsCountLabelType } from '../../type/QuestionCategoryCountType'
import isIE11 from '../../../../utils/isIE11'
import './TopToolbar.scss'

interface Props {
  vm: PulseCategoriesManageVM
  countLabelType?: CategoryQuestionsCountLabelType
  isTemplate?: boolean
}

const TopToolbar: React.FC<Props> = ({ vm, countLabelType = 'none', isTemplate }) => {
  if (!vm) return null

  const renderGrandCategoryTabs = () => {
    const tabs = []
    if (vm.grandCategoryTabs)
      vm.grandCategoryTabs.forEach((tab: GrandCategoryTabVM) => {
        tabs.push(renderCategoryButton(tab))
      })

    const midIndex = Math.ceil(tabs.length / 2)
    const firstColumnTabs = tabs.slice(0, midIndex)
    const secondColumnTabs = tabs.slice(midIndex)

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {firstColumnTabs}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction='column'>
            {secondColumnTabs}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const categoryCount = (row: GrandParentCategoryRowVM): number => {
    if (countLabelType == 'survey') return row.currentSurveyParentQuestionCount
    else if (countLabelType == 'campaign') return row.parentQuestionSelectedCount
    else return 0
  }

  const buildTabTitle = (tab: GrandCategoryTabVM) => {
    if (countLabelType == 'none') return tab.name
    const gpFound = vm.getGrandParentCategory(tab.objectId)
    const count = gpFound ? categoryCount(gpFound) : 0
    if (count === 0) return 'none'
    return `${tab.name}${count > 0 ? ` (${count})` : ''}`
  }

  const renderCategoryButton = (tab: GrandCategoryTabVM) => {
    const tabTitle = buildTabTitle(tab)
    if (vm.isBuild && tabTitle === 'none') return null
    if (tab.isSelected) {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-selected`}>
          <Button
            className='tab-btn tab-btn-selected'
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
          >
            <CheckCircleIcon className='check-icon' />
            {tabTitle}
          </Button>
        </Grid>
      )
    } else {
      return (
        <Grid item key={`cat-btn-${tab.index}-${tab.objectId}-unselected`}>
          <Button
            className='tab-btn tab-btn-unselected'
            key={'wd' + tab.index}
            value={tab.index}
            onClick={() => vm.toggleTabIndex(tab.index)}
          >
            <RadioButtonUncheckedIcon className='uncheck-icon' />
            {tab.name}
          </Button>
        </Grid>
      )
    }
  }

  const renderFocusHeaderText = () => {
    return (
      <div className='dashboard-text-wrapper'>
        <Typography className='dashboard-title'>Identify the Focus</Typography>
        <Typography className='dashboard-subtitle'>
          Pick from our list of expert-designed, research-backed questions to gather insights and
          align with your campaign goals.
        </Typography>
      </div>
    )
  }

  const renderCategoryFilterButton = () => {
    const spin = vm.categoryFilterMenuShown ? 'spin' : ''
    const buttonLabel =
      isTemplate || isTemplate === undefined ? 'Categories' : vm.categoryFilterBtnLabel
    return (
      <Button
        className='category-filter-btn'
        variant='outlined'
        aria-label='Open Categories'
        endIcon={<KeyboardArrowDownIcon className={`chevron ${spin}`} />}
        onClick={(e) => vm.openCategoryFilterMenu(e.currentTarget)}
      >
        <div className='category-filter-btn-wrapper'>
          <FilterListIcon />
          {buttonLabel}
        </div>
      </Button>
    )
  }

  const renderCategoryFilterMenu = () => {
    const disabled = vm.shouldShowAllCategoriesButtonBeDisabled ? 'disabled' : ''
    const titleText =
      isTemplate || isTemplate === undefined ? '' : vm.numberOfCategoriesSelectedText
    return (
      <Menu
        id='CategoryFilterMenu'
        className='category-filter-menu'
        elevation={2}
        anchorEl={vm.categoryFilterAnchorEl}
        keepMounted
        open={vm.categoryFilterMenuShown}
        onClose={() => vm.closeCategoryFilterMenu()}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <div className='menu-header'>
          <Typography className='menu-title'>{titleText}</Typography>
          <Button
            variant='text'
            aria-label='Show All Categories'
            className={`show-all ${disabled}`}
            disabled={vm.shouldShowAllCategoriesButtonBeDisabled}
            onClick={() => vm.selectAllTabs()}
          >
            Show all
          </Button>
        </div>
        {renderGrandCategoryTabs()}
      </Menu>
    )
  }

  return (
    <div id='PulseCampaignTopToolbar'>
      <CssBaseline />
      <div
        className={clsx('dashboard-appbar', {
          'no-transition': isIE11,
        })}
      >
        <Toolbar className='dashboard-toolbar'>
          {renderFocusHeaderText()}
          {renderCategoryFilterButton()}
          {renderCategoryFilterMenu()}
        </Toolbar>
      </div>
    </div>
  )
}

export default inject()(observer(TopToolbar))
