import { RootStore } from '../../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import { TrainingPlan } from '../../../aggregates/TrainingPlan'
import moment from 'moment'
import { AudienceMember } from '../../../aggregates/AudienceMember'
import CatalogPlansWidgetVM from './CatalogPlansWidgetVM'
import { CatalogPlanItemRowVM } from './CatalogPlanItemRowVM'

export class CatalogPlanRowVM {
  private trainingPlan: TrainingPlan
  private rootStore: RootStore
  private catalogWidgetVM: CatalogPlansWidgetVM

  constructor(
    rootStore: RootStore,
    catalogWidgetVM: CatalogPlansWidgetVM,
    trainingPlan: TrainingPlan
  ) {
    this.rootStore = rootStore
    this.trainingPlan = trainingPlan
    this.catalogWidgetVM = catalogWidgetVM
  }

  @observable public isExpanded: boolean = false
  @observable public trainerAnchorEl: any = null

  @computed
  public get isCertificateUploadDisabled(): boolean {
    return true
  }

  @computed
  public get objectId(): string {
    return this.trainingPlan.objectId
  }

  @computed
  public get title(): string {
    return this.trainingPlan.title
  }

  @computed
  public get isForTrainingFlow(): boolean {
    return Boolean(this.trainingPlan.isForAutomatedTraining)
  }

  @computed
  public get thumbnail(): string {
    return this.trainingPlan.thumbnail
  }

  @computed
  public get trainingPlanItems(): CatalogPlanItemRowVM[] {
    return this.trainingPlan.trainingPlanItems.map(
      (item) => new CatalogPlanItemRowVM(this.rootStore, item)
    )
  }

  @computed
  public get userOwned(): boolean {
    return this.trainingPlan.assignedByUserId === this.rootStore.appStore.currentUserId
  }

  @computed
  public get numberOfPlanItems(): string {
    return `${this.trainingPlanItems.length} Items`
  }

  @computed
  public get numberOfQuizzes(): string {
    let quizzes = 0
    this.trainingPlanItems.forEach((item) => {
      if (item.quiz) quizzes++
    })
    if (quizzes === 0) return ''
    return ` + ${quizzes} Quizzes`
  }

  @computed
  public get numberOfCertificates(): string {
    if (this.isCertificateUploadDisabled) return ''
    let certificates = 0
    this.trainingPlanItems.forEach((item) => {
      if (item.certificate) certificates++
    })
    if (certificates === 0) return ''
    return ` + ${certificates} Certificates`
  }

  @computed
  public get subTitle(): string {
    return `${this.numberOfPlanItems} ${this.numberOfQuizzes} ${this.numberOfCertificates}`
  }

  @computed
  public get buttonText(): string {
    if (this.isExpanded) {
      return 'VIEW LESS'
    } else {
      return 'VIEW MORE'
    }
  }

  @computed
  public get mailToLink() {
    if (!this.trainer) return ''
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.trainer.id)
    if (foundUser) {
      let href = 'mailto:' + foundUser.email
      return href
    }
  }

  @computed
  public get trainer(): AudienceMember {
    return this.trainingPlan.trainer
  }

  @computed
  public get dueDate(): Date {
    return moment(this.trainingPlan.dueDate).toDate()
  }

  @action
  public setTrainerAnchorEl(e) {
    this.trainerAnchorEl = e
  }

  @action
  public toggleIsExpanded() {
    this.isExpanded = !this.isExpanded
  }

  @action
  public createTrainingPlan() {
    this.rootStore.appStore.router.push(`/trainingPlans/from-catalog/edit/${this.objectId}`)
  }

  @action
  public assignCatalogPlan() {
    this.rootStore.appStore.router.push(`/trainingPlans/from-catalog/assign/${this.objectId}`)
  }

  @computed
  public get canManageTrainingPlanFlows(): boolean {
    if (!this.rootStore.organizationsStore.currentOrganization?.hasTrainingPlanFlows) return false
    if (this.rootStore.appStore.canManageTrainingPlanFlows) return true
    return false
  }
}
