import { observable, action, computed } from 'mobx'
import moment from 'moment'
import { serializable, serialize, deserialize, list, object, date } from 'serializr'
import { Quiz } from '../../../quiz/aggregate/Quiz'
import { TrainingPlanItem } from '../../aggregates/TrainingPlanItem'
import { IUserTrainingPlanDTO } from '../dto/IUserTrainingPlanDTO'
import { AudienceMember } from '../../aggregates/AudienceMember'
import { ITrainingPlanDTO } from '../../dtos/ITrainingPlanDTO'
import { IAggregate } from '../../../shared/data/IAggregate'
import { Attachment } from '../../../upload/aggregate/Attachment'

export class UserTrainingPlan implements IUserTrainingPlanDTO, IAggregate {
  public static createForPreview(
    trainingPlan: ITrainingPlanDTO,
    orgId: string,
    userId: string
  ): UserTrainingPlan {
    const userTrainingPlan = new UserTrainingPlan()
    userTrainingPlan.organizationId = orgId
    userTrainingPlan.userId = userId
    userTrainingPlan.trainingPlanId = trainingPlan.objectId
    userTrainingPlan.trainingPlanItems = trainingPlan.trainingPlanItems as TrainingPlanItem[]
    userTrainingPlan.quiz = trainingPlan.quiz as Quiz
    userTrainingPlan.title = trainingPlan.title
    userTrainingPlan.description = trainingPlan.description
    userTrainingPlan.mustCompleteInOrder = trainingPlan.mustCompleteInOrder
    userTrainingPlan.requireQuiz = trainingPlan.requireQuiz
    userTrainingPlan.requireCertificate = trainingPlan.requireCertificate
    userTrainingPlan.isCertificateUploadDisabled = trainingPlan.isCertificateUploadDisabled
    return userTrainingPlan
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public userId: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public description: string = ''
  @serializable @observable public trainingPlanId: string = ''
  @serializable @observable public requireQuiz: boolean = false
  @serializable @observable public requireCertificate: boolean = false
  @serializable @observable public isCertificateUploadDisabled: boolean = false
  @serializable @observable public assignedByUserId: string = ''
  @serializable(list(object(TrainingPlanItem)))
  @observable
  public trainingPlanItems: TrainingPlanItem[] = []
  @serializable(date()) @observable public dueDate: string = ''
  @serializable(date()) @observable public activeDate: string = ''
  @serializable @observable public organizationId: string = ''
  @serializable @observable public activeTrainingItem: string = ''
  @serializable(object(Quiz)) @observable public quiz: Quiz = null
  @serializable(object(Attachment)) @observable public certificate: Attachment = null
  @serializable @observable public isComplete: boolean = false
  @serializable @observable public completedDate: string = ''
  @serializable @observable public version: number = 0
  @serializable @observable public isRead: boolean = false
  @serializable(date()) @observable public readDateTime: Date = null
  @serializable @observable public mustCompleteInOrder: boolean = false
  @serializable(object(AudienceMember)) @observable public trainer: AudienceMember = null
  @serializable @observable public completion: number = 0
  @serializable @observable public isArchived: boolean = false
  @serializable @observable public dueDateVariesByTrainingItem: boolean = false
  @serializable @observable public showQuizResults: boolean = false
  @serializable @observable public associatedCatalogId: string = ''
  @serializable @observable public trainingPlanFlowId: string = ''
  @serializable @observable public isReadonly: boolean = false
  @serializable @observable public program: string = ''

  isOnServer: boolean = false
  isDeleted: boolean = false

  @action
  public setCertificate(result: Attachment) {
    this.certificate = result
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  @action
  public markAsComplete() {
    this.isComplete = true
    this.completedDate = moment(new Date()).toISOString()
  }

  @action
  public markAsIncomplete() {
    this.isComplete = false
    this.completedDate = ''
  }

  public serialize() {
    const obj = serialize(this)
    obj.dueDate = moment(this.dueDate).toISOString()
    obj.completedDate = moment(this.completedDate).toISOString()
    obj.activeDate = moment(this.activeDate).toISOString()
    return obj
  }

  @action
  public setActiveTrainingItem(id: string) {
    this.activeTrainingItem = id
  }

  public clone(): UserTrainingPlan {
    return deserialize(UserTrainingPlan, this.serialize())
  }

  @computed
  public get hasDueDate() {
    return this.dueDate && this.dueDate !== ''
  }
}
