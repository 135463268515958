import { action, computed, observable } from 'mobx'
import { TrainingItem } from '../../training-items/aggregate/TrainingItem'
import { RootStore } from '../../stores/RootStore'
import { TrainingPlanItem } from '../aggregates/TrainingPlanItem'
import { TrainingPlanEditVM } from './TrainingPlanEditVM'
import { ContentItem } from '../../training-items/aggregate/ContentItem'
import { startCase } from 'lodash'

export class TrainingPlanItemVM {
  private rootStore: RootStore
  public trainingPlanItem: TrainingPlanItem
  public trainingPlanVM: TrainingPlanEditVM

  constructor(
    rootStore: RootStore,
    trainingPlanItem: TrainingPlanItem,
    trainingPlanVM: TrainingPlanEditVM
  ) {
    this.rootStore = rootStore
    this.trainingPlanItem = trainingPlanItem
    this.trainingPlanVM = trainingPlanVM
  }

  @observable public anchorEl: any = null

  @computed
  public get id(): string {
    return this.trainingPlanItem.trainingItemId
  }

  @computed
  public get name(): string {
    return this.trainingPlanItem.name
  }

  @computed
  public get dueDate(): Date {
    return new Date(this.trainingPlanItem.dueDate)
  }

  @computed
  public get trainingItemCompletionString(): string {
    if (
      this.trainingPlanItem.requireQuiz &&
      this.trainingPlanItem.requireCertificate &&
      !this.trainingPlanVM.isCertificateUploadDisabled
    )
      return '+ Quiz + Certificate'
    if (this.trainingPlanItem.requireQuiz) return '+ Quiz'
    if (
      this.trainingPlanItem.requireCertificate &&
      !this.trainingPlanVM.isCertificateUploadDisabled
    )
      return '+ Certificate'
    return ''
  }

  @computed
  public get blockString(): string {
    if (!this.trainingPlanItem.block) return undefined
    return `Block-${this.trainingPlanItem.block}:  `
  }

  @computed
  public get block(): string {
    return this.trainingPlanItem.block
  }

  @computed
  public get subtitle(): string {
    if (this.trainingPlanItem.contentItems.length > 1)
      return `${this.trainingPlanItem.contentItems.length} items ${this.trainingItemCompletionString}`
    return `${this.trainingPlanItem.contentItems.length} item ${this.trainingItemCompletionString}`
  }

  @computed
  public get itemLabel(): string {
    return `Item Details`
  }

  @computed
  public get contentItems(): ContentItem[] {
    return this.trainingPlanItem.contentItems
  }

  @action
  public setDueDate(val: Date) {
    this.trainingPlanItem.setDueDate(val)
  }

  @action
  public setBlock(val: string) {
    this.trainingPlanItem.setBlock(val)
  }

  @action
  public setAnchorEl(e) {
    this.anchorEl = e
  }
}
