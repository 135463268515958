import { RootStore } from '../../../../stores/RootStore'
import { computed, action, observable } from 'mobx'
import MyTrainingPlansWidgetVM from './MyTrainingPlansWidgetVM'
import moment from 'moment'
import { UserTrainingPlan } from '../../aggregate/UserTrainingPlan'
import { TrainingPlan } from '../../../../training-plans/aggregates/TrainingPlan'
import User from '../../../../users/aggregate/User'
import { AudienceMember } from '../../../../training-plans/aggregates/AudienceMember'

export class MyTrainingPlansWidgetRowVM {
  private userTrainingPlan: UserTrainingPlan
  private rootStore: RootStore
  private manageWidgetVM: MyTrainingPlansWidgetVM

  constructor(
    rootStore: RootStore,
    manageWidgetVM: MyTrainingPlansWidgetVM,
    userTrainingPlan: UserTrainingPlan
  ) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.manageWidgetVM = manageWidgetVM
  }

  @observable public myTrainerAnchorEl: any = null

  @computed
  public get isCertificateUploadDisabled(): boolean {
    return true
  }

  @computed
  public get objectId(): string {
    return this.userTrainingPlan.objectId
  }

  @computed
  public get trainingPlan(): TrainingPlan {
    return this.rootStore.trainingPlansStore.getTrainingPlan(this.userTrainingPlan.trainingPlanId)
  }

  @computed
  public get title(): string {
    if (!this.userTrainingPlan) return ''
    return this.userTrainingPlan.title
  }

  @computed
  public get isForTrainingFlow(): boolean {
    return Boolean(this.userTrainingPlan.trainingPlanFlowId)
  }

  @computed
  public get isArchived(): boolean {
    return this.userTrainingPlan.isArchived
  }

  @computed
  public get userOwned(): boolean {
    if (!this.userTrainingPlan || !this.trainingPlan) return false
    return this.userTrainingPlan.userId === this.trainingPlan.assignedByUserId
  }

  @computed
  public get assignedByUser() {
    if (!this.userTrainingPlan) return null
    return this.rootStore.audienceMembersStore.getUser(this.userTrainingPlan.assignedByUserId)
  }

  @computed
  public get trainer(): AudienceMember {
    if (!this.userTrainingPlan) return null
    return this.userTrainingPlan.trainer
  }

  @computed
  public get isRead(): boolean {
    return this.userTrainingPlan.isRead
  }

  @computed
  public get isReadonly(): boolean {
    return Boolean(this.userTrainingPlan.isReadonly)
  }

  @computed
  public get lockClasses(): string {
    if (!this.isComplete && this.isPastDue) return 'lock lock-due'
    return 'lock lock-active'
  }

  @computed
  public get activeDate(): string {
    if (!this.userTrainingPlan.activeDate) return moment().toISOString()
    return this.userTrainingPlan.activeDate
  }

  @computed
  public get isActive(): boolean {
    if (
      moment(moment(this.activeDate).toDate()).isBefore(moment()) &&
      moment(moment(this.dueDate).endOf('day').toDate()).isSameOrAfter(moment())
    )
      return true

    return false
  }

  @computed
  public get isPastDue(): boolean {
    if (moment().isAfter(moment(this.dueDate).endOf('day'))) return true
    return false
  }

  @computed
  public get daysPastDue(): string {
    if (!this.isPastDue) return ''
    const numberOfDays = moment().diff(this.dueDate, 'days')
    const day = numberOfDays > 1 ? 'days' : 'day'
    const daysPastDue = `${numberOfDays} ${day} past due`
    return daysPastDue
  }

  @computed
  public get isComplete(): boolean {
    if (this.userTrainingPlan.isComplete) return true

    return false
  }

  @computed
  public get dueDate(): string {
    return this.userTrainingPlan.dueDate
  }

  @computed
  public get completedItems(): number {
    let completedItems = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      item.contentItems.forEach((contentItem) => {
        if (contentItem.isComplete) completedItems++
      })
    })
    return completedItems
  }

  @computed
  public get totalItems(): number {
    let totalItems = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      totalItems = totalItems + item.contentItems.length
    })
    return totalItems
  }

  @computed
  public get categories(): string {
    if (!this.trainingPlan) return ''
    const cats = []
    this.trainingPlan.categories.forEach((cat) => {
      const foundCat = this.rootStore.categoriesStore.getCategory(cat)
      if (!foundCat) return
      cats.push(foundCat.name)
    })
    return cats.join(', ')
  }

  @computed
  public get associateToGoal(): boolean {
    if (!this.trainingPlan) return null
    return this.trainingPlan.associateToGoals
  }

  @computed
  public get associatedGoalId(): string {
    if (!this.trainingPlan) return null
    return this.trainingPlan.associatedGoalId
  }

  @computed
  public get associatedGoalName(): string {
    if (!this.associateToGoal) return null
    const id = this.trainingPlan.associatedGoalId
    const goal = this.rootStore.goalsStore.getGoal(id)
    if (goal) return goal.name
    return 'goal'
  }

  @computed
  public get progress(): number {
    return this.userTrainingPlan.completion
  }

  @computed
  public get mailToLink() {
    if (!this.trainer) return ''
    let foundUser = this.rootStore.audienceMembersStore.getUser(this.trainer.id)
    if (foundUser) {
      let href = 'mailto:' + foundUser.email
      return href
    }
  }

  @action
  public setMyTrainerAnchorEl(e) {
    this.myTrainerAnchorEl = e
  }

  @action
  public openUserTrainingPlan() {
    this.rootStore.appStore.router.push(`/userTrainingPlans/take/${this.objectId}`)
  }

  @action
  public editTrainingPlan() {
    this.rootStore.appStore.router.push(`/trainingPlans/edit/${this.trainingPlan.objectId}`)
  }

  @computed
  public get numberOfPlanItems(): string {
    if (this.userTrainingPlan.trainingPlanItems.length === 1) return '1 Item'
    return `${this.userTrainingPlan.trainingPlanItems.length} Items`
  }

  @computed
  public get numberOfQuizzes(): string {
    let quizzes = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      if (item.quiz) quizzes++
    })
    if (quizzes === 0) return ''
    if (quizzes === 1) return ` + 1 Quiz`
    return ` + ${quizzes} Quizzes`
  }

  @computed
  public get numberOfCertificates(): string {
    if (this.isCertificateUploadDisabled) return ''
    let certificates = 0
    this.userTrainingPlan.trainingPlanItems.forEach((item) => {
      if (item.certificate) certificates++
    })
    if (certificates === 0) return ''
    if (certificates === 1) return ' + 1 Certificate'
    return ` + ${certificates} Certificates`
  }

  @computed
  public get subTitle(): string {
    return `${this.numberOfPlanItems} ${this.numberOfQuizzes} ${this.numberOfCertificates}`
  }
}
