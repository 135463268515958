import React from 'react'
import { inject, observer } from 'mobx-react'
import './CampaignsList.scss'
import { CampaignsListVM } from '../../../view-models/dashboard/all-campaigns/CampaignsListVM'
import { Typography } from '@material-ui/core'
import { CampaignRowVM } from '../../../view-models/dashboard/all-campaigns/CampaignRowVM'
import CampaignRow from './CampaignRow'
import usePagination from '../../summary/Pagination'
import Pagination from '@material-ui/lab/Pagination'
import emptyResults from '../../../../../assets/img/empty-state/empty-state-results.png'
import EmptyState from '../../../../shared/empty-state/EmptyState'
import LocalizationStore from '../../../../localization/LocalizationStore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

interface Props {
  vm: CampaignsListVM
  localizationStore?: LocalizationStore
}

const CampaignsList: React.FC<Props> = ({ vm, localizationStore }) => {
  if (!vm) return null
  const lz = localizationStore.lzStrings.pulseCampaignDashboard

  const data = vm.campaigns
  if (!data) return null
  const count = data.length > 0 ? Math.ceil(data.length / vm.PER_PAGE) : 1
  const _DATA = usePagination(data, vm.PER_PAGE)

  const handleChange = (e, p) => {
    vm.setPage(p)
    _DATA.jump(p)
  }

  if (vm && vm.page > _DATA.getMaxPage()) handleChange({}, _DATA.getMaxPage())

  const renderListHeader = () => {
    return (
      <div className={'campaigns-list-header'}>
        <div className={'name-column'} onClick={() => vm.setSort('name')}>
          <Typography className={'column-header'}>{lz.title}</Typography>
          {renderSortIcon('name')}
        </div>
        <div className={'progress-column'}>
          <Typography className={'column-header'}>{lz.send_progress}</Typography>
        </div>
        <div className={'start-column hide-on-small-screen'} onClick={() => vm.setSort('startDate')}>
          <Typography className={'column-header hide-on-small-screen'}>{lz.start}</Typography>
          {renderSortIcon('startDate')}
        </div>
        <div className={'end-column hide-on-small-screen'} onClick={() => vm.setSort('endDate')}>
          <Typography className={'column-header'}>{lz.end}</Typography>
          {renderSortIcon('endDate')}
        </div>
        <div className={'type-column'}>
          <Typography className={'column-header'}>{lz.type}</Typography>
        </div>
        <div className={'owner-column hide-on-small-screen'}>
          <Typography className={'column-header'}>{lz.owner}</Typography>
        </div>
        <div className={'polarity-column'}>
          <Typography className={'column-header'}>{lz.avg_polarity}</Typography>
        </div>
        <div className={'responded-column'}>
          <Typography className={'column-header'}>{lz.responded}</Typography>
        </div>
        <div className={'actions-column'}>
          <Typography className={'column-header'}>{lz.action}</Typography>
        </div>
      </div>
    )
  }

  const renderSortIcon = (name: string) => {
    if (name !== vm.sortName) return <span className={'blank-span'}></span>
    if (vm.sortDirection === 'asc') return <ExpandMore />
    return <ExpandLess />
  }

  const renderList = () => {
    if (!vm.hasRows) return null
    const campaigns = []
    _DATA.currentData().forEach((row: CampaignRowVM, i) => {
      campaigns.push(<CampaignRow key={i} vm={row} />)
    })
    return <div className={'campaigns-list'}>{campaigns}</div>
  }

  const renderNoCampaigns = () => {
    if (vm.hasRows) return null
    return (
      <EmptyState
        image={emptyResults}
        title={lz.no_campaigns}
        subtitle1={''}
        subtitle2={''}
        action={null}
      />
    )
  }

  const renderPagination = () => {
    return (
      <div className={'campaigns-list-pagination'}>
        <Pagination
          count={count}
          size='small'
          page={vm.page}
          variant='outlined'
          color='primary'
          onChange={handleChange}
        />
      </div>
    )
  }

  return (
    <>
      <div id='CampaignsList'>
        {renderListHeader()}
        {renderList()}
        {renderNoCampaigns()}
      </div>
      <div id='CampaignsPagination'>{renderPagination()}</div>
    </>
  )
}

export default inject('localizationStore')(observer(CampaignsList))
