import React from 'react'
import rootStore from '../../../../stores/RootStore'
import { observer, useLocalStore } from 'mobx-react'
import { Card, CardHeader, Grid, Typography } from '@material-ui/core'
import './UpcomingPulsesCard.scss'
import { UpcomingPulseVM } from '../../../view-models/dashboard/upcoming-pulses/UpcomingPulseVM'
import { UpcomingPulseRowVM } from '../../../view-models/dashboard/upcoming-pulses/UpcomingPulseRowVM'
import fileIcon from '../../../../../assets/img/campaign/fileIcon.png'

const UpcomingPulsesCard: React.FC = () => {
  const localStore = useLocalStore(() => ({
    vm: new UpcomingPulseVM(rootStore),
  }))

  const vm = localStore.vm
  const lz = vm.lz

  const renderRow = (row: UpcomingPulseRowVM, index: number) => {
    const shortName = row.name.length > 10 ? row.name.substring(0, 12) + '...' : row.name
    return (
      <Grid
        item
        key={index}
        onClick={(e) => {
          row.editPulse()
        }}
      >
        <Grid container className='draft-row'>
          <Grid item xs={3}>
            <div className='date-cont' title={row.deliveryDateTime}>
              <div className='month-text'>{row.deliveryMonth}</div>
              <Typography className='day-text'>{row.deliveryDay}</Typography>
            </div>
          </Grid>
          <Grid item xs={7} className='draft-text'>
            <Typography className='name-text'>            
              <span className='full-title'>{row.name}</span>
            <span className='short-title'>{shortName}</span>
            </Typography>

            <Typography className='date-text'>{row.campaignName}</Typography>
          </Grid>
          <Grid item xs={2} className='arrow-cont'>
            <div className='arrow'>
              <div className='line'></div>
              <div className='point'></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderNoPulses = () => {
    if (vm.hasRows) return null
    return <Typography className='no-drafts'>{lz.no_upcoming_pulses_to_show}</Typography>
  }

  const renderRows = () => {
    if (!vm.hasRows) return null
    return (
      <Grid item className='draft-container'>
        {vm.rows.map((e, idx) => renderRow(e, idx))}
      </Grid>
    )
  }

  const renderCard = () => {
    return (
      <Card className={'upcoming-pulses-card'}>
        <Grid container>
          <Grid item xs={10}>
            <CardHeader
              className='header-text'
              title={lz.upcoming_pulses}
              subheader={lz.pulses_scheduled}
            />
          </Grid>
          <Grid item xs={2} className={'icon-container'}>
            <div className={'upcoming-pulses-icon'}>
              <img className='file-icon' alt='File Icon' src={fileIcon} />
            </div>
          </Grid>
        </Grid>
        {renderNoPulses()}
        {renderRows()}
      </Card>
    )
  }

  return <div id='UpcomingPulsesCard'>{renderCard()}</div>
}

export default observer(UpcomingPulsesCard)
