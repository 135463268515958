import { action, observable, computed } from 'mobx'
import { serializable } from 'serializr'
import { RootStore } from '../../stores/RootStore'
import { QuestionVM } from './QuestionVM'
import { UserSurvey } from '../aggregate/UserSurvey'
import { ParseService } from '../../services/ParseService'
import { UserTask } from '../../user-tasks/aggregates/UserTask'
import { SurveyResponsesService } from '../../survey-responses/services/SurveyResponsesService'
import moment from 'moment'
import { SurveyResponse } from '../../survey-responses/aggregate/SurveyResponse'
import { Question as SurveyResponseQuestion } from '../../survey-responses/aggregate/Question'
import { UserSurveysCleanService } from '../service/UserSurveysCleanService'
import { AudienceMember } from 'src/app/audience-members/aggregate/AudienceMember'
import * as Sentry from '@sentry/browser'

export class UserSurveyTakeVM {
  private rootStore: RootStore
  public isLoggedIn: boolean
  public surveyResponseId: string

  constructor(
    rootStore: RootStore,
    userSurvey: UserSurvey | SurveyResponse,
    caller: string,
    isForPreviewer: boolean = false,
    isForResponseDisplay: boolean = false,
    isForCompletedSurvey: boolean = false,
    surveyResponseId: string = null
  ) {
    this.rootStore = rootStore
    this.userSurvey = userSurvey
    this.isForPreviewer = isForPreviewer
    this.isForResponseDisplay = isForResponseDisplay
    this.isForCompletedSurvey = isForCompletedSurvey
    this.surveyResponseId = surveyResponseId
    this.isForResponse = Boolean(surveyResponseId)
    this.isLoggedIn = userSurvey.userId === rootStore.appStore.currentUserId
    this.cleanData()
    this.loadData()
  }

  @serializable @observable public userSurvey: UserSurvey | SurveyResponse

  private cleanData() {
    const svc = new UserSurveysCleanService()
    svc.cleanData(this.userSurvey)
  }

  @action
  private loadData() {
    this.name = this.userSurvey.name
    this.questionsFlowFormat = this.userSurvey.questionsFlowFormat
    this.allQuestions = (this.userSurvey.questions as SurveyResponseQuestion[])
      .filter((e) => !e.isDeleted)
      .map((q, idx) => new QuestionVM(this.rootStore, this, q, idx))
    this.hasSurveyIntroduction = Boolean(this.userSurvey.surveyIntroduction)
    if (this.hasSurveyIntroduction && this.questionsFlowFormat === 'paged')
      this.showSurveyIntroductionPage = true

    this.setCurrentQuestionIndex()
    this.setQuestionDisplayedIndexes()
    this.isValid = true
  }

  @observable public questionsFlowFormat: string = ''
  @observable public name: string = ''
  @observable public currentQuestionIndex: number = 0
  @observable public nextQuestionIndex: number = null
  @observable public allQuestions: Array<QuestionVM> = []
  @observable public isSubmitting: boolean = false
  @observable public isSubmitted: boolean = false
  @observable public isForPreviewer: boolean = false
  @observable public isForResponseDisplay: boolean = false
  @observable public isForCompletedSurvey: boolean = false
  @observable public isForAlwaysAvailable: boolean = false
  @observable public anonymousToken: string = ''
  @observable public isForResponse: boolean = false
  @observable public confirmDeleteShown: boolean = false
  @observable public hasSurveyIntroduction: boolean = false
  @observable public showSurveyIntroductionPage: boolean = false
  @observable public showResponseDateTimePickers: boolean = false
  @observable public paperSurveyResponseDate: Date = null
  @observable public isValid: boolean = true
  @observable public isSnackbarOpen: boolean
  @observable public snackbarMessage: string = ''
  @observable public snackbarError: boolean = false

  @action
  public setPaperSurveyResponseDate(e: Date) {
    this.paperSurveyResponseDate = e
  }

  @action
  public showSurveyResults() {
    if (window.location.href.toLowerCase().includes('public')) {
      const url = '/#/public/survey-results/' + this.userSurvey.anonymousToken
      window.location.replace(url)
    } else if (window.location.href.toLowerCase().includes('alwaysavailable')) {
      const url = '/surveyresults/view/' + this.userSurvey.anonymousToken
      this.rootStore.appStore.router.push(url)
    } else {
      const url = '/surveyresults/view/' + this.userSurvey.surveyId + '/' + this.userSurvey.objectId
      this.rootStore.appStore.router.push(url)
    }
  }

  @action
  public setPaperSurveyResponseTime(hr, min) {
    const responseMoment = moment(this.paperSurveyResponseDate)
    responseMoment.hour(hr).minute(min).second(0)
    this.setPaperSurveyResponseDate(responseMoment.toDate())
  }

  @action
  public toggleShowResponseDateTimePickers() {
    this.showResponseDateTimePickers = !this.showResponseDateTimePickers
    if (this.showResponseDateTimePickers) {
      const now = moment()
      const remainder = 15 - (now.minute() % 15)
      this.paperSurveyResponseDate = now.add(remainder, 'minutes').toDate()
    }
    if (!this.showResponseDateTimePickers) {
      this.paperSurveyResponseDate = null
    }
  }

  @action
  public setSurveyIntroduction(intro: string) {
    this.userSurvey.surveyIntroduction = intro
    this.hasSurveyIntroduction = Boolean(this.userSurvey.surveyIntroduction)
    if (this.hasSurveyIntroduction && this.questionsFlowFormat === 'paged')
      this.showSurveyIntroductionPage = true
    if (
      !this.hasSurveyIntroduction &&
      this.questionsFlowFormat === 'paged' &&
      this.showSurveyIntroductionPage
    )
      this.showSurveyIntroductionPage = false
  }

  @computed
  public get surveyIntroduction(): string {
    return this.userSurvey.surveyIntroduction
  }

  @computed
  public get currentUser(): AudienceMember {
    return this.rootStore.audienceMembersStore.getUser(this.rootStore.appStore.currentUserId)
  }

  @computed
  public get paperSurveyForUser(): AudienceMember {
    if (!this.userSurvey.isPaperSurvey) return null
    return this.rootStore.audienceMembersStore.getUser(this.userSurvey.userId)
  }

  @computed
  public get key(): string {
    return this.userSurvey.objectId
  }

  @computed
  public get currentBranchQuestions(): Array<QuestionVM> {
    const questionIndexes: Set<number> = new Set()
    questionIndexes.add(0)
    let nextQuestion = this.allQuestions[0]
    while (nextQuestion) {
      if (nextQuestion.nextQuestionIndexes.length !== 0) {
        // eslint-disable-next-line no-loop-func
        nextQuestion.nextQuestionIndexes.forEach((idx) => {
          questionIndexes.add(idx)
          nextQuestion = this.allQuestions[idx]
        })
      } else nextQuestion = null
    }
    const questions = []
    questionIndexes.forEach((idx) => {
      if (idx < this.allQuestions.length) questions.push(this.allQuestions[idx])
    })
    return questions
  }

  @action
  public setQuestionDisplayedIndexes() {
    let numberOfInfoTexts = 0
    this.currentBranchQuestions.forEach((q, idx) => q.setDisplayedIndex(idx - numberOfInfoTexts))
  }

  @computed
  public get userTaskId(): string {
    if (!this.userSurvey.userTaskId) return ''
    return this.userSurvey.userTaskId
  }

  @computed
  public get userTask(): UserTask {
    if (!this.userTaskId) return null
    return this.rootStore.userTasksStore.getUserTask(this.userTaskId)
  }

  @computed
  public get taskAssignedBy(): string {
    const user = this.rootStore.audienceMembersStore.getUser(this.userTask.assignedByUserId)
    if (!user) return ''
    return user.name
  }

  @computed
  public get taskCompletedBy(): string {
    if (!this.userTask) return ''
    const user = this.rootStore.audienceMembersStore.getUser(this.userTask.userId)
    if (!user) return ''
    return user.name
  }

  @computed
  public get userIsTaskOwner(): boolean {
    if (this.userTask && this.userTask.assignedByUserId === this.rootStore.appStore.currentUserId)
      return true
    return false
  }

  @computed
  public get showPendingSurveysButton(): boolean {
    return (
      Boolean(this.rootStore.appStore.currentUserId) &&
      !window.location.href.toLowerCase().includes('public') &&
      !this.isForPreviewer
    )
  }

  @computed
  public get showSurveyResultsButton(): boolean {
    return this.userSurvey.showResults
  }

  @computed
  public get currentQuestion() {
    return this.currentBranchQuestions[this.currentQuestionIndex]
  }

  @action
  public setCurrentQuestionIndex() {
    if (this.questionsFlowFormat === 'paged') {
      this.nextQuestionIndex = this.getCurrentQuestionIndex()
    } else {
      this.currentQuestionIndex = this.getCurrentQuestionIndex()
    }
  }

  @action
  public openTask() {
    if (!this.userTask) return
    if (this.userIsTaskOwner) {
      this.rootStore.tasksStore.openTask(this.userTask.taskId)
      this.rootStore.tasksStore.openDrawer()
    } else {
      this.rootStore.userTasksStore.loadEditVM(this.userTaskId)
      this.rootStore.userTasksStore.openDrawer()
    }
  }

  @action
  public getCurrentQuestionIndex() {
    let idx = 0
    const questionsCount = this.currentBranchQuestions.length
    if (this.isComplete) return questionsCount - 1
    if (this.lastAnsweredIndex === -1) return 0
    if (idx === 0 && this.currentBranchQuestions[0].hasAnswer) idx = idx + 1
    if (this.lastAnsweredIndex + 1 < questionsCount) idx = this.lastAnsweredIndex + 1
    if (idx > this.firstUnansweredRequiredIndex) idx = this.firstUnansweredRequiredIndex
    if (idx >= questionsCount) idx = questionsCount
    if (idx === -1) return questionsCount - 1
    return idx
  }

  @computed
  public get lastAnsweredIndex(): number {
    let lastIdx = -1
    this.currentBranchQuestions.forEach((q, idx) => {
      if (q.hasAnswer) lastIdx = idx
    })
    return lastIdx
  }

  @computed
  public get firstUnansweredRequiredIndex(): number {
    let foundIdx = -1
    this.currentBranchQuestions.forEach((q, idx) => {
      if (!q.hasAnswer && q.required && foundIdx === -1) foundIdx = idx
    })
    return foundIdx
  }

  @computed
  public get lastQuestion(): QuestionVM {
    return this.currentBranchQuestions[this.currentBranchQuestions.length - 1]
  }

  @computed
  public get firstUnansweredBranchIndex(): number {
    let foundIdx = -1
    this.currentBranchQuestions.forEach((q, idx) => {
      if (q.hasBranch && !q.hasAnswer && foundIdx === -1) foundIdx = idx
    })
    if (foundIdx === -1) foundIdx = this.currentBranchQuestions.length - 1
    return foundIdx
  }

  @computed
  public get currentQuestionId(): string {
    try {
      return this.currentBranchQuestions[this.currentQuestionIndex].id
    } catch (e) {
      console.error(e)
      console.log(this.currentQuestionIndex)
      return ''
    }
  }

  @computed
  public get percentComplete(): number {
    const answered = this.currentBranchQuestions.filter((e) => e.hasAnswer || !e.required).length
    const total = this.currentBranchQuestions.length
    let progress = (answered / total) * 100
    if (progress === 0) progress = 1
    return progress
  }

  @computed
  public get requiredButNotCompleted(): QuestionVM[] {
    const notAnswered = this.currentBranchQuestions.filter((e) => !e.hasAnswer && e.required)
    if (notAnswered) return notAnswered
    return null
  }

  @computed
  public get isComplete(): boolean {
    if (this.isForCompletedSurvey) return false
    return this.percentComplete === 100
  }

  @computed
  public get isReadyToSubmit(): boolean {
    if (this.isForCompletedSurvey) return false
    return this.percentComplete > 1
  }

  @computed
  public get resetEnabled(): boolean {
    if (this.allQuestions.some((e) => e.hasAnswer)) return true
    return false
  }

  @action
  public resetSurvey() {
    this.loadData()
  }

  @action
  public toggleShowSurveyIntroductionPage() {
    this.showSurveyIntroductionPage = !this.showSurveyIntroductionPage
  }

  @action
  public handleNextQuestion() {
    if (this.questionsFlowFormat === 'paged' && this.showSurveyIntroductionPage) {
      this.toggleShowSurveyIntroductionPage()
      if (this.currentQuestion.isMediaFirstQuestionAndMediaNotShown) {
        this.currentQuestion.setShowMedia()
        return
      }
      return
    }
    if (this.currentQuestion.isMediaFirstQuestionAndMediaShown) {
      this.currentQuestion.setDontShowMedia()
      return
    }
    this.currentQuestionIndex = this.currentQuestionIndex + 1
  }

  @action
  public toggleShowCurrentQuestionMedia() {
    this.currentQuestion.showMedia = !this.currentQuestion.showMedia
  }

  @action
  public handlePreviousQuestion() {
    if (
      this.questionsFlowFormat === 'paged' &&
      this.hasSurveyIntroduction &&
      this.currentQuestionIndex === 0
    ) {
      if (this.currentQuestion.isMediaFirstQuestionAndMediaNotShown) {
        this.currentQuestion.setShowMedia()
        return
      }
      this.toggleShowSurveyIntroductionPage()
      return
    }
    if (this.currentQuestion.isMediaFirstQuestionAndMediaNotShown) {
      this.currentQuestion.setShowMedia()
      return
    }
    this.currentQuestionIndex = this.currentQuestionIndex - 1
    if (this.currentQuestion.isMediaFirstQuestionAndMediaNotShown) {
      this.currentQuestion.setShowMedia()
      return
    }
  }

  @action
  private async submitAlwaysAvailableResponse() {
    const svc = new SurveyResponsesService(this.rootStore)
    const result = await svc.saveSurveyResponseFromAlwaysAvailableSurvey(
      this.toDTO(),
      this.isLoggedIn
    )
    this.isSubmitted = true
  }

  @action
  public async submit() {
    const res = this.requiredButNotCompleted
    if (res && res.length > 0) {
      this.isValid = false
      this.openSnackbar(
        `Warning! ${res.length} required ${
          res.length > 1 ? 'questions remain' : 'question remains'
        } unanswered (${res
          .slice(0, res.length > 2 ? 3 : res.length)
          .map((i) => `Q${i.displayedIndex + 1}`)}${
          res.length > 2 ? '...' : ''
        }). Please try again.`
      )
      return
    } else {
      this.isValid = true
    }

    this.isSubmitting = true
    this.rootStore.appStore.checkOnline()
    if (this.isForAlwaysAvailable) return this.submitAlwaysAvailableResponse()

    if (this.isForPreviewer) {
      setTimeout(() => (this.isSubmitted = true), 1500)
      return
    }

    const postData = this.toDTO()
    const parseSvc = new ParseService()
    try {
      if (this.anonymousToken) {
        await parseSvc.saveUserSurveyResponse(
          this.userSurvey.surveyId,
          this.userSurvey.objectId,
          this.userSurvey.organizationId,
          null,
          postData,
          this.isLoggedIn,
          this.anonymousToken
        )
        this.isSubmitted = true
        return
      }

      await parseSvc.saveUserSurveyResponse(
        this.userSurvey.surveyId,
        this.userSurvey.objectId,
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId,
        postData,
        this.isLoggedIn
      )
    } catch (e) {
      this.isSubmitting = false
      Sentry.captureException(e, {
        extra: {
          surveyId: this.userSurvey.surveyId,
        },
      })
      console.error(e)
      this.openSnackbar(this.rootStore.localizationStore.lzStrings.surveys.unable_to_save, true)
      return
    }
    this.isSubmitted = true
    if (this.userSurvey.isPaperSurvey) {
      if (this.rootStore.appStore.router && this.rootStore.appStore.router.goBack)
        this.rootStore.appStore.router.goBack()
    }
  }

  @action
  public async deleteSurveyResponse() {
    this.confirmDeleteShown = false
    const svc = new SurveyResponsesService(this.rootStore)
    const result = await svc.deleteSurveyResponse(
      this.rootStore.appStore.currentOrgId,
      this.userSurvey.surveyId,
      this.surveyResponseId
    )
    this.rootStore.surveysStore.viewModels.editVM.setResponseJustDeleted()
    this.rootStore.surveysStore.viewModels.editVM.analysis.clearSelectedResponse()
  }

  @action
  public toggleConfirmDelete() {
    this.confirmDeleteShown = !this.confirmDeleteShown
  }

  @action
  public openSnackbar(msg, error: boolean = false) {
    this.snackbarMessage = msg
    this.isSnackbarOpen = true
    this.snackbarError = error
  }

  @action
  public closeSnackbar() {
    this.isSnackbarOpen = false
    this.snackbarError = false
  }

  private toDTO() {
    const dto: any = {
      surveyId: this.userSurvey.objectId,
      name: this.name,
      anonymousToken: this.userSurvey.anonymousToken,
      questions: this.currentBranchQuestions.map((e) => e.toDTO()),
    }
    if (this.userSurvey.isPaperSurvey) {
      dto.inputByUserId = this.currentUser.objectId
      if (this.paperSurveyResponseDate) {
        dto.paperSurveyResponseDate = this.paperSurveyResponseDate
      }
    }
    return dto
  }

  @computed
  public get surveyCompletedContent(): string {
    if (!this.userSurvey.surveyCompletedContent) return ''
    return this.userSurvey.surveyCompletedContent
  }
}
