import React from 'react'
import { observer, inject } from 'mobx-react'
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Searchbar from '../../../shared/Searchbar'
import LocalizationStore from '../../../localization/LocalizationStore'
import './OrganizationUsersListTableHeader.scss'
import { OrganizationUsersListManageVM } from '../../view-models/list/OrganizationUsersListManageVM'
import FilterListIcon from '@material-ui/icons/FilterList'
import MuiSelectPrompt from '../../../shared/Mui-select-prompt/MuiSelectPrompt'
import MoreIcon from '@material-ui/icons/MoreVert'
import RefreshIcon from '@material-ui/icons/Refresh'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import PlayArrow from '@material-ui/icons/PlayArrow'
import ImportExport from '@material-ui/icons/ImportExport'

interface Props {
  organizationUsersListManageVM?: OrganizationUsersListManageVM
  localizationStore?: LocalizationStore
}

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: 'none',
  },
})

const OrganizationUsersListTableHeader: React.FC<Props> = ({
  organizationUsersListManageVM,
  localizationStore,
}) => {
  const { tableVM } = organizationUsersListManageVM
  const styles = useStyles()
  if (!tableVM) return null
  const lz = localizationStore.lzStrings.orgUsersList

  const renderAddToGroupDialog = () => {
    if (!tableVM.addToGroupDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.add_group_confirm} ${tableVM.dialogCount} ${lz.selected_users_to}`}
        onClose={() => tableVM.toggleAddToGroupDialog()}
        open={tableVM.addToGroupDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.handleSelectedOption(e.target.value, 'group')}
        onChange={(e) => tableVM.setNewGroupToAdd(e.target.value)}
        selectedOption={tableVM.selectedGroupsToAdd ? tableVM.selectedGroupsToAdd[0] : ''}
        options={tableVM.addGroupOptions}
        title={lz.add_to_group}
        addNew={tableVM.addNew}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const renderAddToRoleDialog = () => {
    if (!tableVM.addToRoleDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.add_role_confirm} ${tableVM.dialogCount} ${lz.selected_users_to}`}
        onClose={() => tableVM.toggleAddToRoleDialog()}
        open={tableVM.addToRoleDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.handleSelectedOption(e.target.value, 'role')}
        onChange={(e) => tableVM.setNewRoleToAdd(e.target.value)}
        selectedOption={tableVM.selectedRolesToAdd[0]}
        options={tableVM.addRoleOptions}
        title={lz.add_to_role}
        addNew={tableVM.addNew}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const renderAddToPrivilegeDialog = () => {
    if (!tableVM.addToPrivilegeDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.add_privilege_set_confirm} ${tableVM.dialogCount} ${lz.selected_users_to}`}
        onClose={() => tableVM.toggleAddToPrivilegeDialog()}
        open={tableVM.addToPrivilegeDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.setSelectedPrivilegeSetsToAdd(e.target.value)}
        selectedOption={tableVM.selectedPrivilegeSetsToAdd[0]}
        options={tableVM.privilegeSetsOptions}
        title={lz.add_to_privilege_set}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const renderRemoveFromGroupDialog = () => {
    if (!tableVM.removeFromGroupDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.remove_group_confirm} ${tableVM.dialogCount} ${lz.selected_users_from}`}
        onClose={() => tableVM.toggleRemoveFromGroupDialog()}
        open={tableVM.removeFromGroupDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.setSelectedGroupsToRemove(e.target.value)}
        selectedOption={tableVM.selectedGroupsToRemove[0]}
        options={tableVM.groupOptions}
        title={lz.remove_from_group}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const renderRemoveFromRoleDialog = () => {
    if (!tableVM.removeFromRoleDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.remove_role_confirm} ${tableVM.dialogCount} ${lz.selected_users_from}`}
        onClose={() => tableVM.toggleRemoveFromRoleDialog()}
        open={tableVM.removeFromRoleDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.setSelectedRolesToRemove(e.target.value)}
        selectedOption={tableVM.selectedRolesToRemove[0]}
        options={tableVM.roleOptions}
        title={lz.remove_from_role}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const renderRemoveFromPrivilegeDialog = () => {
    if (!tableVM.removeFromPrivilegeDialogOpen) return
    return (
      <MuiSelectPrompt
        confirmMessage={`${lz.add_privilege_set_confirm} ${tableVM.dialogCount} ${lz.selected_users_from}`}
        onClose={() => tableVM.toggleRemoveFromPrivilegeDialog()}
        open={tableVM.removeFromPrivilegeDialogOpen}
        onConfirm={() => tableVM.saveOptionToUsers()}
        onSelect={(e) => tableVM.setSelectedPrivilegeSetsToRemove(e.target.value)}
        selectedOption={tableVM.selectedPrivilegeSetsToRemove[0]}
        options={tableVM.privilegeSetsOptions}
        title={lz.remove_from_privilege_set}
        disabled={tableVM.isProcessing}
      />
    )
  }

  const handleClose = (value: string) => {
    tableVM.setModifyMenuAnchorEl(null)
    if (value === 'addToGroup') tableVM.toggleAddToGroupDialog()
    if (value === 'removeFromGroup') tableVM.toggleRemoveFromGroupDialog()
    if (value === 'addToRole') tableVM.toggleAddToRoleDialog()
    if (value === 'removeFromRole') tableVM.toggleRemoveFromRoleDialog()
    if (value === 'addToPrivilege') tableVM.toggleAddToPrivilegeDialog()
    if (value === 'removeFromPrivilege') tableVM.toggleRemoveFromPrivilegeDialog()
    if (value === 'expire') tableVM.toggleExpirePasswordsConfirmDialog()
    if (value === 'welcome') tableVM.toggleSendWelcomeConfirmDialog()
    if (value === 'archive') tableVM.toggleArchiveUsersConfirmDialog()
    if (value === 'delete') tableVM.toggleDeleteUsersConfirmDialog()
    if (value === 'addTo') tableVM.toggleAddToGroupDialog()
    if (value === 'removeFrom') tableVM.toggleRemoveFromGroupDialog()
    if (value === 'enableEmailNotifications') tableVM.toggleEnableEmailNotificationsDialog()
    if (value === 'disableEmailNotifications') tableVM.toggleDisableEmailNotificationsDialog()
    if (value === 'enableTextNotifications') tableVM.toggleEnableTextNotificationsDialog()
    if (value === 'disableTextNotifications') tableVM.toggleDisableTextNotificationsDialog()
    if (value === 'enablePushNotifications') tableVM.toggleEnablePushNotificationsDialog()
    if (value === 'disablePushNotifications') tableVM.toggleDisablePushNotificationsDialog()
    if (value === 'startTrainingFlowBeginner') tableVM.toggleStartTrainingPlanFlowBeginnerConfirmDialog()
    if (value === 'startTrainingFlowIntermediate') tableVM.toggleStartTrainingPlanFlowIntermediateConfirmDialog()
    if (value === 'startTrainingFlowAdvanced') tableVM.toggleStartTrainingPlanFlowAdvancedConfirmDialog()
    if (value === 'cancelTrainingFlow') tableVM.toggleCancelTrainingPlanFlowConfirmDialog()
  }

  const renderAddOption = () => {
    return (
      <div>
        <MenuItem
          className='menu-item-arrow-icon'
          onMouseOver={(e) => tableVM.handleClickAdd(e)}
          onMouseLeave={(e) => tableVM.handleCloseHoverAdd()}
        >
          <ListItemText>Add to... </ListItemText>
          <ListItemIcon className='arrow-icon'>
            <PlayArrow />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='AddMenu'
          anchorEl={tableVM.addMenuAnchorEl}
          open={Boolean(tableVM.addMenuAnchorEl)}
          onClose={(e) => tableVM.setAddMenuAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          MenuListProps={{
            onMouseEnter: (e) => tableVM.handleHoverAdd(),
            onMouseLeave: (e) => tableVM.handleCloseHoverAdd(),
            style: { pointerEvents: 'auto' },
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {renderAddActionItems()}
        </Menu>
      </div>
    )
  }

  const renderRemoveOption = () => {
    return (
      <div>
        <MenuItem
          className='menu-item-arrow-icon'
          onMouseOver={(e) => tableVM.handleClickRemove(e)}
          onMouseLeave={(e) => tableVM.handleCloseHoverRemove()}
        >
          <ListItemText>Remove from... </ListItemText>
          <ListItemIcon className='arrow-icon'>
            <PlayArrow />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='AddMenu'
          anchorEl={tableVM.removeMenuAnchorEl}
          open={Boolean(tableVM.removeMenuAnchorEl)}
          onClose={(e) => tableVM.setRemoveMenuAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          MenuListProps={{
            onMouseEnter: (e) => tableVM.handleHoverRemove(),
            onMouseLeave: (e) => tableVM.handleCloseHoverRemove(),
            style: { pointerEvents: 'auto' },
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {renderRemoveActionItems()}
        </Menu>
      </div>
    )
  }

  const renderEnableOption = () => {
    return (
      <div>
        <MenuItem
          className='menu-item-arrow-icon'
          onMouseOver={(e) => tableVM.handleClickEnable(e)}
          onMouseLeave={(e) => tableVM.handleCloseHoverEnable()}
        >
          <ListItemText>Enable... </ListItemText>
          <ListItemIcon className='arrow-icon'>
            <PlayArrow />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='enableMenu'
          anchorEl={tableVM.enableMenuAnchorEl}
          open={Boolean(tableVM.enableMenuAnchorEl)}
          onClose={(e) => tableVM.setEnableMenuAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          MenuListProps={{
            onMouseEnter: (e) => tableVM.handleHoverEnable(),
            onMouseLeave: (e) => tableVM.handleCloseHoverEnable(),
            style: { pointerEvents: 'auto' },
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {renderEnableActionItems()}
        </Menu>
      </div>
    )
  }

  const renderDisableOption = () => {
    return (
      <div>
        <MenuItem
          className='menu-item-arrow-icon'
          onMouseOver={(e) => tableVM.handleClickDisable(e)}
          onMouseLeave={(e) => tableVM.handleCloseHoverDisable()}
        >
          <ListItemText>Disable... </ListItemText>
          <ListItemIcon className='arrow-icon'>
            <PlayArrow />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='disableMenu'
          anchorEl={tableVM.disableMenuAnchorEl}
          open={Boolean(tableVM.disableMenuAnchorEl)}
          onClose={(e) => tableVM.setDisableMenuAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          MenuListProps={{
            onMouseEnter: (e) => tableVM.handleHoverDisable(),
            onMouseLeave: (e) => tableVM.handleCloseHoverDisable(),
            style: { pointerEvents: 'auto' },
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {renderDisableActionItems()}
        </Menu>
      </div>
    )
  }

  const renderAddToGroupOption = () => {
    return <MenuItem onClick={() => handleClose('addToGroup')}>Group</MenuItem>
  }

  const renderRemoveFromGroupOption = () => {
    return <MenuItem onClick={() => handleClose('removeFromGroup')}>Group</MenuItem>
  }

  const renderAddToRoleOption = () => {
    return <MenuItem onClick={() => handleClose('addToRole')}>Role</MenuItem>
  }

  const renderRemoveFromRoleOption = () => {
    return <MenuItem onClick={() => handleClose('removeFromRole')}>Role</MenuItem>
  }

  const renderAddToPrivilegeOption = () => {
    return <MenuItem onClick={() => handleClose('addToPrivilege')}>Privilege Set</MenuItem>
  }

  const renderRemoveFromPrivilegeOption = () => {
    return <MenuItem onClick={() => handleClose('removeFromPrivilege')}>Privilege Set</MenuItem>
  }

  const renderEnableEmailNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('enableEmailNotifications')}>
        Email Notifications
      </MenuItem>
    )
  }

  const renderDisableEmailNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('disableEmailNotifications')}>
        Email Notifications
      </MenuItem>
    )
  }

  const renderEnableTextNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('enableTextNotifications')}>Text Notifications</MenuItem>
    )
  }

  const renderDisableTextNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('disableTextNotifications')}>
        Text Notifications
      </MenuItem>
    )
  }

  const renderEnablePushNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('enablePushNotifications')}>Push Notifications</MenuItem>
    )
  }

  const renderDisablePushNotificationsOption = () => {
    return (
      <MenuItem onClick={() => handleClose('disablePushNotifications')}>
        Push Notifications
      </MenuItem>
    )
  }

  const renderExpirePasswordOption = () => {
    if (!tableVM.isSystemAdmin) return
    return <MenuItem onClick={() => handleClose('expire')}>{lz.expire_password}</MenuItem>
  }

  const renderSendWelcomeEmailOption = () => {
    return <MenuItem onClick={() => handleClose('welcome')}>{lz.welcome_email}</MenuItem>
  }

  const renderArchiveUsersOption = () => {
    return <MenuItem onClick={() => handleClose('archive')}>Archive Users</MenuItem>
  }

  const renderDeleteUsersOption = () => {
    return <MenuItem onClick={() => handleClose('delete')}>Delete Users</MenuItem>
  }

  const renderStartAutomatedTrainingOption = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return (
      <div>
        <MenuItem
          className='menu-item-arrow-icon'
          onMouseOver={(e) => tableVM.handleClickStartFlow(e)}
          onMouseLeave={(e) => tableVM.handleCloseHoverStartFlow()}
        >
          <ListItemText>Start Training Flow</ListItemText>
          <ListItemIcon className='arrow-icon'>
            <PlayArrow />
          </ListItemIcon>
        </MenuItem>
        <Menu
          id='AddMenu'
          anchorEl={tableVM.startFlowMenuAnchorEl}
          open={Boolean(tableVM.startFlowMenuAnchorEl)}
          onClose={(e) => tableVM.setAddMenuAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          MenuListProps={{
            onMouseEnter: (e) => tableVM.handleHoverStartFlow(),
            onMouseLeave: (e) => tableVM.handleCloseHoverStartFlow(),
            style: { pointerEvents: 'auto' },
          }}
          PopoverClasses={{
            root: styles.popOverRoot,
          }}
        >
          {renderStartAutomatedTrainingActionItems()}
        </Menu>
      </div>
    )
  }
  
  const renderStartAutomatedTrainingActionItems = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return (
      <div>
        {renderStartAutomatedTrainingBeginnerOption()}
        {/* {renderStartAutomatedTrainingIntermediateAction()}
        {renderStartAutomatedTrainingAdvancedAction()} */}
      </div>
    )
  }

  const renderStartAutomatedTrainingBeginnerOption = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return <MenuItem onClick={() => handleClose('startTrainingFlowBeginner')}>Beginner (L1)</MenuItem>
  }

  const renderStartAutomatedTrainingIntermediateAction = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return <MenuItem onClick={() => handleClose('startTrainingFlowIntermediate')}>Intermediate (L2)</MenuItem>
  }

  const renderStartAutomatedTrainingAdvancedAction = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return <MenuItem onClick={() => handleClose('startTrainingFlowAdvanced')}>Optimization (OPT)</MenuItem>
  }

  const renderCancelAutomatedTrainingOption = () => {
    if (!tableVM.canStartTrainingPlanFlows) return
    return <MenuItem onClick={() => handleClose('cancelTrainingFlow')}>Cancel Training Flow</MenuItem>
  }

  const renderModifyButton = () => {
    if (tableVM.tableHasBeenEdited) return
    return (
      <div onMouseLeave={(e) => tableVM.nullAllAnchors()}>
        <Button
          disabled={tableVM.actionButtonDisabled}
          onClick={(e) => tableVM.setModifyMenuAnchorEl(e.currentTarget)}
          onMouseOver={(e) => tableVM.setModifyMenuAnchorEl(e.currentTarget)}
          variant='contained'
          className={
            tableVM.actionButtonDisabled ? 'manage-users-button-disabled' : 'manage-users-button'
          }
        >
          {lz.action} <ArrowDropDownIcon />
        </Button>
        <Menu
          id='simple-menu'
          anchorEl={tableVM.modifyMenuAnchorEl}
          keepMounted
          open={Boolean(tableVM.modifyMenuAnchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          onMouseLeave={() => handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          style={{ marginTop: 40 }}
          MenuListProps={{ onMouseLeave: (e) => tableVM.setModifyMenuAnchorEl(null) }}
        >
          {renderActionItems()}
        </Menu>
      </div>
    )
  }

  const renderAddActionItems = () => {
    return (
      <div>
        {renderAddToRoleOption()}
        {renderAddToGroupOption()}
        {renderAddToPrivilegeOption()}
      </div>
    )
  }

  const renderRemoveActionItems = () => {
    return (
      <div>
        {renderRemoveFromRoleOption()}
        {renderRemoveFromGroupOption()}
        {renderRemoveFromPrivilegeOption()}
      </div>
    )
  }

  const renderEnableActionItems = () => {
    return (
      <div>
        {renderEnableEmailNotificationsOption()}
        {renderEnablePushNotificationsOption()}
        {renderEnableTextNotificationsOption()}
      </div>
    )
  }

  const renderDisableActionItems = () => {
    return (
      <div>
        {renderDisableEmailNotificationsOption()}
        {renderDisablePushNotificationsOption()}
        {renderDisableTextNotificationsOption()}
      </div>
    )
  }

  const renderActionItems = () => {
    return (
      <div>
        {renderAddOption()}
        {renderRemoveOption()}
        {renderEnableOption()}
        {renderDisableOption()}
        {renderArchiveUsersOption()}
        {renderDeleteUsersOption()}
        {renderExpirePasswordOption()}
        {renderSendWelcomeEmailOption()}
        {renderStartAutomatedTrainingOption()}
        {renderCancelAutomatedTrainingOption()}
      </div>
    )
  }

  const renderViewColumnsButton = () => {
    if (tableVM.tableHasBeenEdited) return
    return (
      <Tooltip title={'Customize which columns are shown in this table'} placement='bottom'>
        <Button
          onClick={(e) => {
            tableVM.setColumnPopupAnchorEl(e.currentTarget)
          }}
          onMouseOver={(e) => {
            tableVM.setTimer(e.currentTarget)
          }}
          onMouseOut={(e) => {
            tableVM.cancelTimer()
          }}
          variant='outlined'
          className='action-btn column-btn'
        >
          {lz.columns} <ArrowDropDownIcon />
        </Button>
      </Tooltip>
    )
  }

  const renderTableEditButtons = () => {
    if (!tableVM.tableHasBeenEdited) return
    return (
      <>
        <Button
          onClick={(e) => {
            tableVM.cancelTableEdits()
          }}
          variant='outlined'
          className='action-btn table-edit-cancel'
        >
          CANCEL
        </Button>
        <Button
          onClick={(e) => {
            tableVM.saveTableEdits()
          }}
          variant='contained'
          disabled={tableVM.editedRowEvents.length === 0}
          className='table-edit-save'
        >
          SAVE
        </Button>
      </>
    )
  }

  const renderFilterUsers = () => {
    return (
      <Tooltip title={'Filter Users'} placement='bottom'>
        <IconButton
          size='small'
          onClick={(e) => {
            tableVM.setFilterPopupAnchorEl(e.currentTarget)
          }}
          className={tableVM.hasParticipants ? 'filter-is-active action-btn' : 'action-btn'}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const renderSearch = () => {
    return (
      <div className='search'>
        <Searchbar
          focused={tableVM.hasFilter}
          searchValue={tableVM.typedFilterText}
          onSearchChange={(e) => {
            tableVM.setQuickFilter(e.target.value)
          }}
          className='action-btn'
        />
      </div>
    )
  }

  const renderSelectedUsersCount = () => {
    if (tableVM.hideCounts) return null
    if (tableVM.newSelectedRows.length === 0) return null
    if (tableVM.selectAll && tableVM.unSelectedRows.length === 0)
      return (
        <div className='selected-user-count'>{` All ${tableVM.totalRecords} Users selected`}</div>
      )
    if (tableVM.selectAll) {
      const count = tableVM.totalRecords - tableVM.unSelectedRows.length
      return <div className='selected-user-count'>{`${count} Users selected`}</div>
    }
    return (
      <div className='selected-user-count'>
        {` ${tableVM.newSelectedRows.length} Users selected`}
      </div>
    )
  }

  const renderShowMoreMenu = () => {
    if (!tableVM.isSystemAdmin && !tableVM.isUserAdmin) return
    return (
      <div>
        <IconButton
          size='small'
          onClick={(e) => {
            tableVM.setMoreMenuAnchorEl(e.currentTarget)
          }}
          className={Boolean(tableVM.moreMenuAnchorEl) ? 'focused' : ''}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={tableVM.moreMenuAnchorEl}
          open={Boolean(tableVM.moreMenuAnchorEl)}
          onClose={(e) => {
            tableVM.setMoreMenuAnchorEl(null)
          }}
          elevation={1}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          className='more-menu'
        >
          {renderShowArchivedUsers()}
          {renderShowHiddenUsers()}
          {renderRefreshUsersInDW()}
        </Menu>
      </div>
    )
  }

  const renderRefreshUsersInDW = () => {
    if (!tableVM.isSystemAdmin) return
    return (
      <MenuItem
        onClick={() => {
          tableVM.refreshUsersInDW()
        }}
        className='refresh-users-dw-item'
      >
        <RefreshIcon className='refresh-users-dw-icon' />
        <ListItemText primary='Refresh Users In DW' />
      </MenuItem>
    )
  }

  const renderShowHiddenUsers = () => {
    if (!tableVM.isSystemAdmin && !tableVM.isUserAdmin) return
    return (
      <MenuItem
        onClick={() => {
          tableVM.toggleViewHiddenUsers()
        }}
        divider={tableVM.isSystemAdmin ? true : false}
      >
        <Checkbox checked={tableVM.shouldShowHiddenUsers} />
        <ListItemText primary='View Hidden' />
      </MenuItem>
    )
  }
  const renderShowArchivedUsers = () => {
    if (!tableVM.isSystemAdmin && !tableVM.isUserAdmin) return
    return (
      <MenuItem
        onClick={() => {
          tableVM.toggleShowArchivedUsers()
        }}
      >
        <Checkbox checked={tableVM.showArchivedUsers} />
        <ListItemText primary='View Archived' />
      </MenuItem>
    )
  }

  const renderImport = () => {
    return (
      <div className='import-action-btn'>
        <Tooltip
          title={localizationStore.lzStrings.orgUsersTableWidget.importUsers}
          placement='bottom'
        >
          <IconButton onClick={() => tableVM.openUserImport()}>
              <ImportExport />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const renderRefreshButton = () => {
    return (
      <Tooltip title={'Refresh'} placement='bottom'>
        <Button
          onClick={(e) => {
            tableVM.hardRefreshUsersList()
          }}
          variant='outlined'
          className='action-btn-end refresh-btn'
        >
          <RefreshIcon />
        </Button>
      </Tooltip>
    )
  }

  const renderAddNewUser = () => {
    if (tableVM.tableHasBeenEdited) return
    return (
      <div className='action-btn-add'>
        <Tooltip
          title={localizationStore.lzStrings.orgUsersTableWidget.addNewUser}
          placement='bottom'
        >
          <span>
            <Button
              onClick={() => tableVM.openNewUser()}
              variant='contained'
              className={!tableVM.isLoaded ? 'manage-users-button-disabled' : 'manage-users-button'}
              disabled={!tableVM.isLoaded}
            >
              New User
            </Button>
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <div id='TableHeader'>
      <div className='manage-users-container'>
        <div className='left'>
          {renderImport()}
          {renderAddNewUser()}
          {renderModifyButton()}
          {renderTableEditButtons()}
          {renderSelectedUsersCount()}
        </div>
        <div className='right'>
          {renderShowMoreMenu()}
          {renderSearch()}
          {renderFilterUsers()}
          {renderViewColumnsButton()}
          {renderRefreshButton()}
        </div>

        {renderAddToGroupDialog()}
        {renderAddToRoleDialog()}
        {renderAddToPrivilegeDialog()}
        {renderRemoveFromGroupDialog()}
        {renderRemoveFromRoleDialog()}
        {renderRemoveFromPrivilegeDialog()}
      </div>
    </div>
  )
}

export default inject(
  'organizationUsersStore',
  'localizationStore'
)(observer(OrganizationUsersListTableHeader))
