import React from 'react'
import { observer } from 'mobx-react'
import './CampaignRow.scss'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronDownIcon from '@material-ui/icons/ExpandMore'
import { IconButton, Typography } from '@material-ui/core'
import { CampaignRowVM } from '../../../view-models/dashboard/all-campaigns/CampaignRowVM'
import CampaignRowActions from './CampaignRowActions'
import CampaignRowTimeline from './CampaignRowTimeline'

interface Props {
  vm: CampaignRowVM
}

const CampaignRow: React.FC<Props> = ({ vm }) => {
  if (!vm) return null

  const renderExpandIcon = () => {
    let icon = <ChevronRightIcon className={'expand-icon'} />
    if (vm.isExpanded) icon = <ChevronDownIcon className={'expand-icon'} />
    return (
      <IconButton
        disableRipple
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          vm.toggleIsExpanded()
        }}
        className={'icon-container'}
      >
        {icon}
      </IconButton>
    )
  }

  const renderRow = () => {
    const shortTitle = vm.title.length > 10 ? vm.title.substring(0, 10) + '...' : vm.title
    return (
      <div className={`campaign-row border-status-${vm.status} expanded-${vm.isExpanded}`} onClick={(e) => vm.openDetails()}>
        {renderExpandIcon()}
        <div className={'name-column'}>
        <Typography className={'title-text'}>
        <span className='full-title'>{vm.title}</span>
        <span className='short-title'>{shortTitle}</span>
        </Typography>
        </div>
        <div className={'progress-column'}>
          {renderProgress()}
        </div>
        <div className={'start-column hide-on-small-screen'}>
          <Typography className={'other-text'}>{vm.startDate}</Typography>
        </div>
        <div className={'end-column hide-on-small-screen'}>
          <Typography className={'other-text'}>{vm.endDate}</Typography>
        </div>
        <div className={'type-column'}>
          <Typography className={'other-text'}>{vm.type}</Typography>
        </div>
        <div className={'owner-column hide-on-small-screen'}>
          <Typography className={'other-text'}>{vm.owner}</Typography>
        </div>
        <div className={'polarity-column'}>
          {renderPolarity()}
        </div>
        <div className={'responded-column'}>
          {renderResponses()}
        </div>
        <div className={'actions-column'}>
          <CampaignRowActions vm={vm} />
        </div>
      </div>
    )
  }

  const renderPolarity = () => {
    return (
      <div className={`polarity-container ${vm.polarityStatus}`}>
        <Typography className={'polarity-text'}>{vm.polarity}</Typography>
      </div>
    )
  }

  const renderProgress = () => {
    return (
      <>
        <div className={'progress-container'}>
          <div style={{ width: vm.sentPercentage }} className={'progress-bar'}></div>
        </div>
        <div className={'progress-text'}>
          <Typography className={'other-text'}>{`${vm.sentCount} of ${vm.surveysCount} sent (${vm.sentPercentage})`}</Typography>
        </div>
      </>
    )
  }

  const renderResponses = () => {
    return (
      <>
        <div className={'progress-text'}>
          <Typography className={'other-text'}>{vm.responsesText}</Typography>
        </div>
      </>
    )
  }

  const renderCampaignTimeline = () => {
    return <CampaignRowTimeline vm={vm} />
  }

  return (
    <div id='CampaignRow'>
      {renderRow()}
      {renderCampaignTimeline()}
    </div>
  )
}

export default observer(CampaignRow)
