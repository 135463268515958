import { observable, action } from 'mobx'
import { serializable, serialize, deserialize, list, object, primitive } from 'serializr'
import { ApplicableParticipant } from '../../training-items/aggregate/ApplicableParticipant'
import { ITrainingPlanDTO } from '../dtos/ITrainingPlanDTO'
import { TrainingPlanItem } from './TrainingPlanItem'
import { ICloudinaryResult } from '../../media-items/dtos/ICloudinaryResult'
import { ParticipantDueDate } from './ParticipantDueDate'
import moment from 'moment'
import { AudienceMember } from './AudienceMember'
import { Quiz } from '../../quiz/aggregate/Quiz'
import { IApplicableParticipantDTO } from '../../training-items/dto/IApplicableParticipantDTO'
import { PlanSnapshot } from './PlanSnapshot'
import { IAggregate } from 'src/app/shared/data/IAggregate'
import uuid from 'uuid/v4'
import { Attachment } from '../../upload/aggregate/Attachment'

export class TrainingPlan implements ITrainingPlanDTO, IAggregate {
  public static create(orgId: string, userId: string): TrainingPlan {
    const trainingPlan = new TrainingPlan()
    trainingPlan.organizationId = orgId
    trainingPlan.assignedByUserId = userId
    const now = moment()
    const remainder = 15 - (now.minute() % 15)
    const currentDateRoundedToNext15Mins = now.add(remainder, 'minutes')
    trainingPlan.activeDate = currentDateRoundedToNext15Mins.toISOString()
    trainingPlan.dueDate = currentDateRoundedToNext15Mins.toISOString()
    trainingPlan.quiz = Quiz.create()
    return trainingPlan
  }

  public static createCatalogPlan(orgId: string, userId: string): TrainingPlan {
    const trainingPlan = new TrainingPlan()
    trainingPlan.organizationId = orgId
    trainingPlan.assignedByUserId = userId
    const now = moment()
    const remainder = 15 - (now.minute() % 15)
    const currentDateRoundedToNext15Mins = now.add(remainder, 'minutes')
    trainingPlan.activeDate = currentDateRoundedToNext15Mins.toISOString()
    trainingPlan.dueDate = currentDateRoundedToNext15Mins.toISOString()
    trainingPlan.quiz = Quiz.create()
    trainingPlan.isCatalogPlan = true
    return trainingPlan
  }

  @serializable @observable public objectId: string = ''
  @serializable @observable public title: string = ''
  @serializable @observable public description: string = ''
  @serializable(list(object(AudienceMember)))
  @observable
  public assignedToAudienceMembers: AudienceMember[] = []
  @serializable @observable public thumbnail: string = ''
  @serializable @observable public thumbnailFileName: string = ''
  @serializable(list(object(TrainingPlanItem)))
  @observable
  public trainingPlanItems: TrainingPlanItem[] = []
  @serializable @observable public isRecurring: boolean = false
  @serializable @observable public activeDate: string = ''
  @serializable @observable public dueDate: string = ''
  @serializable @observable public dueDateSameForAll: boolean = true
  @serializable @observable public dueDateVariesByUser: boolean = false
  @serializable @observable public dueDateVariesByTrainingItem: boolean = false
  @serializable @observable public requireProofOfCompletion: boolean = false
  @serializable @observable public requireQuiz: boolean = false
  @serializable @observable public requireCertificate: boolean = false
  @serializable @observable public isCertificateUploadDisabled?: boolean = false
  @serializable(object(Attachment)) @observable public certificate: Attachment = null
  @serializable(list(primitive())) @observable public categories: string[] = []
  @serializable(list(object(ApplicableParticipant)))
  @observable
  public applicableParticipants: ApplicableParticipant[] = []
  @serializable @observable public organizationId: string = ''
  @serializable @observable public mustCompleteInOrder: boolean = false
  @serializable(list(object(ParticipantDueDate)))
  @observable
  public participantDueDates: ParticipantDueDate[] = []
  @serializable @observable public assignedByUserId: string = ''
  @serializable @observable public isForPeerTraining: boolean = false
  @serializable(object(AudienceMember)) @observable public trainer: AudienceMember = null
  @serializable(object(AudienceMember)) @observable public trainee: AudienceMember = null
  @serializable(object(Quiz)) @observable public quiz: Quiz = new Quiz()
  @serializable @observable public associateToGoals: boolean = false
  @serializable @observable public associatedGoalId: string = ''
  @serializable @observable public associatedCatalogGoalId: string = ''
  @serializable @observable public version: number = 1
  @serializable @observable public isCatalogPlan: boolean = false
  @serializable(list(object(PlanSnapshot))) @observable public planSnapshots: PlanSnapshot[] = []
  @serializable @observable public isArchived: boolean = false
  @serializable @observable public showQuizResults: boolean = false
  @serializable @observable public trainingPlanFlowId: string = ''
  @serializable @observable public program: string = ''
  @serializable @observable public isForAutomatedTraining: boolean = false

  isOnServer: boolean = false
  isDeleted: boolean = false

  @action
  public setAssociatedGoalId(id: string) {
    this.associatedGoalId = id
    this.associatedCatalogGoalId = id
  }

  @action
  public setAssociateToGoals(val: boolean) {
    this.associateToGoals = val
  }

  @action
  public setTitle(val: string) {
    this.title = val
  }

  @action
  public updateVersion() {
    this.version = this.version + 1
  }

  @action
  public setDueDate(val: Date) {
    this.dueDate = moment(val).toISOString()
  }

  @action
  public setDescription(val: string) {
    this.description = val
  }

  @action
  public setProgram(val: string) {
    this.program = val
  }

  @action
  public setCategories(tags: string[]) {
    this.categories = tags
  }

  @action
  public toggleShowQuizResults() {
    this.showQuizResults = !this.showQuizResults
  }

  @action
  public toggleIsForAutomatedTraining() {
    this.isForAutomatedTraining = !this.isForAutomatedTraining
  }

  @action
  public toggleRequireCompletion() {
    this.requireProofOfCompletion = !this.requireProofOfCompletion
  }

  @action
  public toggleRequireQuiz() {
    this.requireQuiz = !this.requireQuiz
  }

  @action
  public toggleRequireCertificate() {
    this.requireCertificate = !this.requireCertificate
  }

  @action
  public toggleMarkAsPeerTraining() {
    this.isForPeerTraining = !this.isForPeerTraining
  }

  @action
  public setThumbnail(result: Attachment) {
    this.thumbnail = result.url
    this.thumbnailFileName = result.fileName
  }

  @action
  public setTrainer(trainer: AudienceMember) {
    this.trainer = trainer
  }

  @action
  public setTrainee(trainee: AudienceMember) {
    this.trainee = trainee
  }

  @action
  public setParticipantDueDates(ary: ParticipantDueDate[]) {
    this.participantDueDates = ary
  }

  @action
  public removeCategory(id: string) {
    this.categories = this.categories.filter((cat) => cat !== id)
  }

  @action
  public setRolesAndGroups(array: IApplicableParticipantDTO[]) {
    this.applicableParticipants = []
    array.forEach((arr) => {
      const part = ApplicableParticipant.create(arr.id, arr.name, arr.type)
      this.applicableParticipants.push(part)
    })
  }

  @action
  public toggleMustCompleteInOrder() {
    this.mustCompleteInOrder = !this.mustCompleteInOrder
  }

  @action
  public toggleIsRecurring() {
    this.isRecurring = !this.isRecurring
  }

  @action
  public setActiveDate(date: Date) {
    this.activeDate = moment(date).toISOString()
  }

  @action
  public removeTrainingItem(id: string) {
    this.trainingPlanItems = this.trainingPlanItems.filter((item) => item.trainingItemId !== id)
  }

  @action
  public moveTrainingPlanItem(newIndex, oldIndex) {
    let trainingItemObj = this.trainingPlanItems.splice(oldIndex, 1)
    this.trainingPlanItems.splice(newIndex, 0, trainingItemObj[0])
  }

  @action
  public setDueDateSameForAll() {
    this.dueDateVariesByTrainingItem = false
    this.dueDateVariesByUser = false
    this.dueDateSameForAll = true
  }

  @action
  public setDueDateVariesByParticipant() {
    this.dueDateSameForAll = false
    this.dueDateVariesByTrainingItem = false
    this.dueDateVariesByUser = true
  }

  @action
  public setDueDateVariesByTrainingItem() {
    this.dueDateSameForAll = false
    this.dueDateVariesByUser = false
    this.dueDateVariesByTrainingItem = true
  }

  @action
  public addTrainingPlanItem(item: TrainingPlanItem) {
    item.id = uuid()
    this.trainingPlanItems.push(item)
  }

  @action
  public markIsOnServer() {
    this.isOnServer = true
  }

  @action
  public markIsNotOnServer() {
    this.isOnServer = true
  }

  public serialize(): ITrainingPlanDTO {
    const obj = serialize(this)
    return obj
  }

  public clone(): TrainingPlan {
    return deserialize(TrainingPlan, this.serialize())
  }
}
