import React from 'react'

const ActionableInsightsHomeScreen = () => {
  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
       <h2 style={{ fontSize: '2rem', color: '#cacace' }}>Coming Soon: Actionable Insights</h2>
  </div>
  )
}

export default ActionableInsightsHomeScreen