import { computed, action, observable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Consent } from '../aggregate/Consent'
import { ParseService } from '../../services/ParseService'
import { isNumeric } from '../../shared/isNumeric'
import { AttachmentVM } from '../../attachments/view-models/AttachmentVM'
import { OldAttachmentVM } from '../../attachments/view-models/OldAttachmentVM'

export class UserConsentScreenVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get language(): string {
    const pref = this.rootStore.userStore.user.languagePreference
    if (!pref) return this.rootStore.localizationStore.language
    if (pref === 'English') return 'en'
    if (pref === 'German') return 'de'
    return ''
  }

  @action
  public async decline() {
    await this.rootStore.appStore.logout('Consent Screen: User declined consent')
  }

  @computed
  public get currentConsent(): Consent {
    return this.rootStore.consentsStore.latestConsent
  }

  @computed
  public get text(): string {
    if (!this.currentConsent) return ''
    if (!this.currentConsent.text) return ''
    if (!this.language) return ''
    return this.currentConsent.text[this.language]
  }

  @computed
  public get attachments(): AttachmentVM[] {
    if (!this.currentConsent) return []
    return this.currentConsent.attachments.map((e, idx) => {
      if (isNumeric(e.objectId) || e.cmsItemId) {
        return this.rootStore.cmsItemAttachmentStore.loadAttachment(e)
      }
      return new OldAttachmentVM(this.rootStore, e, idx)
    })
  }

  public async openAttachment(attachment: AttachmentVM) {
    attachment.openAttachment()
  }
  @action
  public async accept() {
    const deleteMyData = false
    const svc = new ParseService()
    await svc.acceptConsent(
      this.rootStore.appStore.currentOrgId,
      this.rootStore.appStore.currentUserId,
      this.currentConsent.objectId,
      this.currentConsent.versionNumber,
      deleteMyData
    )
    location.reload()
  }
}
