import { Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import LocalizationStore from '../../../../localization/LocalizationStore'
import { TrainingPlansStore } from '../../../../training-plans/store/TrainingPlansStore'
import './CompletionTabPanel.scss'
import InfoTooltip from '../../../../shared/InfoTooltip'
import { render } from '@testing-library/react'

interface Props {
  trainingPlansStore?: TrainingPlansStore
  localizationStore?: LocalizationStore
}

const CompletionToolbar: React.FC<Props> = ({ trainingPlansStore, localizationStore }) => {
  const { trainingPlanEditVM: vm } = trainingPlansStore
  const { training_plans: strings } = localizationStore.lzStrings
  if (!vm) return null

  const renderQuizCheckbox = () => {
    if (vm.isCertificateUploadDisabled) return null
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={!vm.requireProofOfCompletion || vm.editIsDisabled}
            checked={vm.requireQuiz}
            onChange={() => vm.toggleRequireQuiz()}
            color='default'
            className={vm.proofOfCompletionValid ? '' : 'check-error'}
          />
        }
        label={strings.quiz}
      />
    )
  }

  const renderCertificateCheckbox = () => {
    if (vm.isCertificateUploadDisabled) return null
    return (
      <FormControlLabel
        control={
          <Checkbox
            disabled={!vm.requireProofOfCompletion || vm.editIsDisabled}
            checked={vm.requireCertificate}
            onChange={() => vm.toggleRequireCertificate()}
            color='default'
            className={vm.proofOfCompletionValid ? '' : 'check-error'}
          />
        }
        label={strings.certificate}
      />
    )
  }

  const renderRequireCompletionText = () => {
    return (
      <Typography variant='h5' className='input-label'>
        {strings.require_completion}
        <InfoTooltip
          message={
            vm.isCertificateUploadDisabled
              ? strings.completion_message_no_certificate
              : strings.completion_message
          }
        />
      </Typography>
    )
  }

  const renderRequireCompletionSwitchWithoutOptions = () => {
    if (!vm.isCertificateUploadDisabled) return null
    return (
      <Switch
        disabled={vm.editIsDisabled}
        checked={vm.requireProofOfCompletion}
        onChange={() => vm.toggleRequireCompletionWithoutOptions()}
      />
    )
  }

  const renderRequireCompletionSwitchWithOptions = () => {
    if (vm.isCertificateUploadDisabled) return null
    return (
      <Switch
        disabled={vm.editIsDisabled}
        checked={vm.requireProofOfCompletion}
        onChange={() => vm.toggleRequireCompletionWithOptions()}
      />
    )
  }

  return (
    <div id='CompletionToolbar'>
      <Grid container>
        <Grid item xs={12} className='flex-align'>
          {renderRequireCompletionText()}
          {renderRequireCompletionSwitchWithoutOptions()}
        </Grid>
        <Grid item xs={12}>
          {renderRequireCompletionSwitchWithOptions()}
          {renderQuizCheckbox()}
          {renderCertificateCheckbox()}
        </Grid>
      </Grid>
    </div>
  )
}

export default inject('trainingPlansStore', 'localizationStore')(observer(CompletionToolbar))
