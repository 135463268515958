import { RootStore } from '../../stores/RootStore'
import Parse from 'parse'
import { observable, runInAction, action, computed } from 'mobx'
import { deserialize } from 'serializr'
import { TrainingPlan } from '../aggregates/TrainingPlan'
import { TrainingPlanEditVM } from '../view-models/TrainingPlanEditVM'
import CatalogPlansWidgetVM from '../view-models/widgets/plan-catalog-widget/CatalogPlansWidgetVM'
import CatalogItemsWidgetVM from '../view-models/widgets/item-catalog-widget/CatalogItemsWidgetVM'
import { DataStore } from '../../shared/data/DataStore'
import { ITrainingPlanDTO } from '../dtos/ITrainingPlanDTO'

export class TrainingPlansCatalogStore extends DataStore<TrainingPlan, ITrainingPlanDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, TrainingPlan, 'catalogTrainingPlans', [
      'organizationId',
      'title',
      'description',
      'assignedToAudienceMembers',
      'thumbnail',
      'thumbnailFileName',
      'trainingPlanItems',
      'isRecurring',
      'activeDate',
      'dueDate',
      'dueDateSameForAll',
      'dueDateVariesByUser',
      'dueDateVariesByTrainingItem',
      'requireProofOfCompletion',
      'requireQuiz',
      'requireCertificate',
      'isCertificateUploadDisabled',
      'certificate',
      'categories',
      'applicableParticipants',
      'mustCompleteInOrder',
      'participantDueDates',
      'assignedByUserId',
      'isForPeerTraining',
      'trainer',
      'trainee',
      'quiz',
      'associateToGoals',
      'associatedGoalId',
      'associatedCatalogGoalId',
      'version',
      'isCatalogPlan',
      'planSnapshots',
      'isArchived',
      'showQuizResults',
      'isForAutomatedTraining',
      'program',
    ])
  }

  @observable public trainingPlanEditVM: TrainingPlanEditVM = null
  @observable public catalogPlansWidgetVM: CatalogPlansWidgetVM = null
  @observable public catalogItemsWidgetVM: CatalogItemsWidgetVM = null
  @observable public loaded: boolean = false

  @computed
  public get orgCatalogTrainingPlans(): TrainingPlan[] {
    return this.records
  }

  @action
  public onListRecordsLoaded() {
    this.catalogPlansWidgetVM = new CatalogPlansWidgetVM(this.rootStore)
    this.catalogItemsWidgetVM = new CatalogItemsWidgetVM(this.rootStore)
    this.loaded = true
  }

  @computed
  public get canCreateTrainingPlans(): boolean {
    if (
      this.rootStore.organizationsStore.currentOrganization &&
      !this.rootStore.organizationsStore.currentOrganization.isTrainingsEnabled
    )
      return false
    return this.rootStore.appStore.canCreateTrainings
  }

  @action
  public createNewCatalogPlan() {
    this.rootStore.trainingPlansStore.trainingPlanEditVM = new TrainingPlanEditVM(
      this.rootStore,
      TrainingPlan.createCatalogPlan(
        this.rootStore.appStore.currentOrgId,
        this.rootStore.appStore.currentUserId
      )
    )
  }

  public getCatalogTrainingPlan(objectId): TrainingPlan {
    return this.orgCatalogTrainingPlans.find((trainingPlan) => trainingPlan.objectId === objectId)
  }

  public deleteCatalogTrainingPlan(objectId) {
    this.orgCatalogTrainingPlans.splice(this.getTrainingPlanIndex(objectId), 1)
  }

  private getTrainingPlanIndex(objectId): number {
    return this.orgCatalogTrainingPlans.findIndex((e) => e.objectId === objectId)
  }

  @action
  public editCatalogTrainingPlan(id: string, attempts: number = 0) {
    if (attempts > 10) console.log('Error finding training plan to edit')
    const trainingPlan = this.getCatalogTrainingPlan(id)
    if (!trainingPlan || trainingPlan === undefined) {
      setTimeout(() => this.editCatalogTrainingPlan(id, attempts++), 500)
    } else {
      this.rootStore.trainingPlansStore.trainingPlanEditVM = new TrainingPlanEditVM(
        this.rootStore,
        trainingPlan.clone()
      )
    }
  }
}
