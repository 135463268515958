import { action, observable, computed } from 'mobx'
import { debounce } from 'lodash'
import moment from 'moment'
import { QuizTakeVM } from '../../../quiz/view-models/QuizTakeVM'
import { Quiz } from '../../../quiz/aggregate/Quiz'
import { RootStore } from '../../../stores/RootStore'
import { TrainingPlan } from '../../aggregates/TrainingPlan'
import { TrainingPlanItem } from '../../aggregates/TrainingPlanItem'
import { UserTrainingPlan } from '../aggregate/UserTrainingPlan'
import { UserTrainingPlansUpdateService } from '../service/UserTrainingPlansUpdateService'
import { ContentVM } from './ContentVM'
import { PlanItemVM } from './PlanItemVM'
import { MediaItemsService } from '../../../media-items/service/MediaItemsService'
import { FileOpenService } from '../../../upload/services/FileOpenService'

export class UserTrainingPlanTakeVM {
  private rootStore: RootStore
  public userTrainingPlan: UserTrainingPlan
  public svc: UserTrainingPlansUpdateService
  private selectedTrainingItemId: string
  private selectedQuizId: string
  private selectedCertId: string

  constructor(
    rootStore: RootStore,
    userTrainingPlan: UserTrainingPlan,
    isReadOnly: boolean = false,
    selectedTrainingItemId: string = '',
    selectedQuizId: string = '',
    selectedCertId: string = ''
  ) {
    this.rootStore = rootStore
    this.userTrainingPlan = userTrainingPlan
    this.isReadOnly = isReadOnly
    this.selectedTrainingItemId = selectedTrainingItemId
    this.selectedQuizId = selectedQuizId
    this.selectedCertId = selectedCertId
    this.svc = new UserTrainingPlansUpdateService()
    this.reviewPlan()
    this.openTrainingItem()
    this.openSelectedTrainingItemFromTimeLine(selectedTrainingItemId)
    this.openSelectedQuizFromTimeLine(selectedQuizId)
    this.openSelectedCertificateFromTimeLine(selectedCertId)
    if (!this.userTrainingPlan.isRead && this.userTrainingPlan.objectId)
      setTimeout(() => this.markAsRead(), 2000)
  }

  @observable public selectedContentItem: ContentVM = null
  @observable public selectedQuiz: Quiz = null
  @observable public isReadOnly: boolean = false
  @observable public selectedCertificateUploadId: string = ''
  @observable public selectedCertificateFileName: string = ''
  @observable public reviewingPlan: boolean = false
  @observable public completionScreenShown: boolean = false
  @observable public showCompletionUploadModal: boolean = false

  @computed
  public get isCertificateUploadDisabled(): boolean {
    return true
  }

  @action
  private openTrainingItem() {
    if (this.selectedTrainingItemId) return
    if (this.selectedQuizId) return
    if (this.selectedCertId) return
    if (!this.userTrainingPlan.activeTrainingItem)
      this.setActiveTrainingItem(this.trainingItemRows[0].id)
    if (this.userTrainingPlan.activeTrainingItem) {
      setTimeout(() => this.openPreviousTrainingItem(), 1000)
    }
  }

  @action
  public openSelectedTrainingItemFromTimeLine(id: string) {
    if (!id) return
    if (this.selectedQuizId) return
    if (this.selectedCertId) return
    this.setActiveTrainingItem(id)
    setTimeout(() => this.activeTrainingItem.toggleIsExpanded(), 500)
  }

  @action
  public openSelectedQuizFromTimeLine(id: string) {
    if (!id) return
    if (this.selectedTrainingItemId) return
    if (this.selectedCertId) return
    this.openQuiz(this.userTrainingPlan.quiz)
  }

  @action
  public openSelectedCertificateFromTimeLine(id: string) {
    if (!id) return
    if (this.selectedTrainingItemId) return
    if (this.selectedQuizId) return
    this.setSelectedCertificateUpload(id)
  }

  @action
  public async markAsRead() {
    this.userTrainingPlan.isRead = true
    await this.svc.markUserTrainingPlanAsRead(this.objectId, this.userTrainingPlan.organizationId)
  }

  @computed
  public get trainingPlan(): TrainingPlan {
    if (!this.userTrainingPlan.trainingPlanId)
      //for preview on training plan builder
      return this.rootStore.trainingPlansStore.trainingPlanEditVM.trainingPlan
    return this.rootStore.trainingPlansStore.getTrainingPlan(this.userTrainingPlan.trainingPlanId)
  }

  @computed
  public get objectId(): string {
    return this.userTrainingPlan.objectId
  }

  @computed
  public get title(): string {
    if (!this.trainingPlan || !this.trainingPlan.title) return '(No title provided)'
    return this.userTrainingPlan.title
  }

  @computed
  public get isForTrainingFlow(): boolean {
    return Boolean(this.userTrainingPlan.trainingPlanFlowId)
  }

  @computed
  public get mustCompleteItemsInOrder(): boolean {
    return this.userTrainingPlan.mustCompleteInOrder
  }

  @computed
  public get requireQuiz(): boolean {
    return this.userTrainingPlan.requireQuiz
  }

  @computed
  public get requireCertificate(): boolean {
    if (this.isCertificateUploadDisabled) {
      return false
    } else {
      return this.userTrainingPlan.requireCertificate
    }
  }

  @computed
  public get dueDateVariesByTrainingItem(): boolean {
    if (!this.trainingPlan) return false
    return this.trainingPlan.dueDateVariesByTrainingItem
  }

  @computed
  public get certificateUrl(): string {
    if (!this.selectedCertificateUploadId) return ''
    if (this.selectedCertificateUploadId === 'for-plan') {
      if (!this.userTrainingPlan.certificate) return ''
      return this.userTrainingPlan.certificate.url
    } else {
      const item = this.trainingItemRows.filter(
        (item) => item.id === this.selectedCertificateUploadId
      )[0]
      if (!item) return ''
      return item.certificateUrl
    }
  }

  @computed
  public get certificateFileName(): string {
    if (this.selectedCertificateUploadId === 'for-plan') {
      return `${this.userTrainingPlan.certificate.fileName}`
    } else {
      const item = this.trainingItemRows.filter(
        (item) => item.id === this.selectedCertificateUploadId
      )[0]
      if (!item) return ''
      return item.certificateFileName
    }
  }

  @computed
  public get itemHasCertificate(): boolean {
    if (this.selectedCertificateUploadId === 'for-plan') {
      return Boolean(this.userTrainingPlan.certificate)
    } else {
      const item = this.trainingItemRows.filter(
        (item) => item.id === this.selectedCertificateUploadId
      )[0]
      if (!item) return false
      return Boolean(item.certificateUrl)
    }
  }

  @action
  public setCertificate(attachment) {
    const item = this.trainingItemRows.filter(
      (item) => item.id === this.selectedCertificateUploadId && item.requireCertificate
    )[0]
    if (!item && this.selectedCertificateUploadId === 'for-plan') {
      this.userTrainingPlan.setCertificate(attachment)
      this.continue()
      this.save()
    } else {
      item.setCertificate(attachment)
      this.continue()
      this.save()
    }
  }

  @action
  public async openCertificate() {
    let attachment = null
    if (!this.selectedCertificateUploadId) return
    if (this.selectedCertificateUploadId === 'for-plan') {
      if (!this.userTrainingPlan.certificate) return
      attachment = this.userTrainingPlan.certificate
    } else {
      const item = this.trainingItemRows.filter(
        (item) => item.id === this.selectedCertificateUploadId
      )[0]
      if (!item) return
      attachment = item.trainingPlanItem.certificate
    }
    const svc = new FileOpenService(this.rootStore)
    await svc.openFile(attachment)
  }

  @action
  public reviewPlan() {
    if (!this.userTrainingPlan.isComplete) return
    this.reviewingPlan = true
    this.completionScreenShown = false
  }

  @computed
  public get isCompletionQuiz(): boolean {
    if (!this.planQuiz) return false
    return Boolean(this.selectedQuiz.id === this.planQuiz.id)
  }

  @computed
  public get quizTakeVM(): QuizTakeVM {
    if (!this.selectedQuiz) return null
    return new QuizTakeVM(
      this.rootStore,
      this.selectedQuiz,
      this.userTrainingPlan,
      this.activeTrainingItemId,
      (quiz) => this.updateQuiz(quiz)
    )
  }

  @computed
  public get quizOpenAndDisabled(): boolean {
    if (this.isReadOnly) return true
    if (
      this.activeTrainingItem &&
      this.activeTrainingItem.mustCompleteInOrder &&
      this.selectedQuiz &&
      !this.planQuizSelected &&
      !this.activeTrainingItem.onlyQuizIsLeft
    )
      return true
    if (this.activeTrainingItem && !this.activeTrainingItem.completionAllowed) return true
    if (
      !this.planQuizSelected &&
      this.selectedQuiz &&
      this.activeTrainingItem.mustCompleteInOrder &&
      this.activeTrainingItem.contentItems.some((item) => !item.isComplete)
    )
      return true
    if (
      this.planQuizSelected &&
      this.mustCompleteItemsInOrder &&
      this.trainingItemRows.some((row) => !row.isComplete)
    )
      return true
    return false
  }

  @computed
  public get activeTrainingItemId(): string {
    return this.userTrainingPlan.activeTrainingItem
  }

  @computed
  public get activeTrainingItem(): PlanItemVM {
    return this.trainingItemRows.find((item) => item.id === this.activeTrainingItemId)
  }

  @computed
  public get planQuiz(): Quiz {
    return this.userTrainingPlan.quiz
  }

  @computed
  public get planQuizSelected(): boolean {
    if (this.selectedQuiz && this.planQuiz && this.selectedQuiz.id === this.planQuiz.id) return true
    return false
  }

  @computed
  public get certificateOpenAndDisabled(): boolean {
    if (this.selectedCertificateUploadId === 'for-plan') {
      if (this.requireQuiz && !this.planQuiz.isComplete) return true
      if (this.trainingItemRows.some((row) => !row.isComplete) && this.mustCompleteItemsInOrder)
        return true
    }
    if (this.activeTrainingItem) {
      if (this.reviewingPlan) return true
      if (!this.userTrainingPlan.objectId) return true
      return this.activeTrainingItem.menuCertificateDisabled
    }
    return false
  }

  @computed
  public get categories(): string[] {
    if (!this.trainingPlan) return []
    return this.trainingPlan.categories
      .map((cat) => {
        const category = this.rootStore.categoriesStore.getCategory(cat)
        if (!category) return null
        return category.name
      })
      .filter((e) => e != null)
  }

  @action
  public backToImpactDashboard() {
    this.rootStore.appStore.router.push('/dashboard/impact/trainings')
  }

  @action
  public setActiveTrainingItem(id: string) {
    this.userTrainingPlan.setActiveTrainingItem(id)
    this.selectedQuiz = null
    this.selectedCertificateUploadId = ''
  }

  @action
  public openPreviousTrainingItem() {
    const activeTrainingItem = this.trainingItemRows.filter(
      (item) => item.id === this.userTrainingPlan.activeTrainingItem
    )[0]
    if (activeTrainingItem) activeTrainingItem.toggleIsExpanded()
  }

  @action
  public setSelectedContentItem(contentId: string, trainingItemId: string) {
    if (this.quizTakeVM && !this.quizTakeVM.isComplete) this.quizTakeVM.reset()
    this.selectedQuiz = null
    const trainingItem: PlanItemVM = this.trainingItemRows.filter(
      (item) => item.id === trainingItemId
    )[0]
    if (!trainingItem) return
    this.selectedContentItem = trainingItem.contentItems.filter((item) => item.id === contentId)[0]
    this.setActiveTrainingItem(trainingItem.id)
  }

  @action
  public setSelectedCertificateUpload(id: string) {
    this.selectedCertificateUploadId = id
    this.selectedContentItem = null
    this.selectedQuiz = null
  }

  @computed
  public get certificateTitleString(): string {
    const itemName = this.activeTrainingItem ? this.activeTrainingItem.name : ''
    if (this.selectedCertificateUploadId === 'for-plan') {
      return `Upload Certificate for "${this.userTrainingPlan.title}"`
    } else {
      return `Upload Certificate for "${itemName}"`
    }
  }

  @computed
  public get certificateUploadedString(): string {
    return 'Certificate upload successful!'
  }

  @action
  public async generateSignature(callback: Function, paramsToSign: object): Promise<void> {
    const svc = new MediaItemsService()
    const signature = await svc.getCloudinarySignature(paramsToSign)
    callback(signature)
  }

  @action
  public toggleShowCompletionUploadModal() {
    this.showCompletionUploadModal = !this.showCompletionUploadModal
  }

  // @action
  // public showWidget() {
  //   if (this.isReadOnly) return
  //   window.cloudinary.openUploadWidget(
  //     {
  //       multiple: false,
  //       cloudName: 'rippleworx',
  //       sources: ['local', 'url', 'image_search', 'camera', 'dropbox', 'google_drive'],
  //       showPoweredBy: false,
  //       apiKey: '914141713215552',
  //       uploadSignature: this.generateSignature,
  //     },
  //     async (error, result) => {
  //       if (!error && result && result.event === 'success') {
  //         const attachment = Attachment.createFromCloudinaryResult(result.info)
  //         if (result.info.resource_type.includes('video')) {
  //           const thumbnail = await this.getThumbnailUrl(result.info)
  //           attachment.thumbnail = thumbnail
  //         }
  //         this.setCertificate(attachment)
  //       }
  //     }
  //   )
  // }

  // public async getThumbnailUrl(info) {
  //   const svc = new MediaItemsService()
  //   const thumbnailUrl = await svc.buildThumbnailURL(info.public_id, '.jpg', info.secure_url)
  //   return thumbnailUrl
  // }

  @action
  public openQuiz(quiz: Quiz) {
    const editableQuiz = quiz
    this.selectedQuiz = editableQuiz
    this.selectedContentItem = null
    this.selectedCertificateUploadId = null
    if (this.planQuiz && quiz.id === this.planQuiz.id) return
    const trainingItem = this.trainingItemRows.filter(
      (row) => row.requireQuiz && row.quiz.id === quiz.id
    )[0]
    if (!trainingItem) return
    this.userTrainingPlan.setActiveTrainingItem(trainingItem.id)
  }

  @action
  public updateQuiz(returnedQuiz: Quiz) {
    if (this.planQuiz && returnedQuiz.id === this.planQuiz.id) {
      this.userTrainingPlan.quiz = returnedQuiz
    } else {
      const item = this.trainingItemRows.filter((item) => item.id === this.activeTrainingItemId)[0]
      item.trainingPlanItem.quiz = returnedQuiz
    }
    this.continue()
    this.save()
  }

  @action
  public continue() {
    if (this.activeTrainingItem.requireCertificate && !this.isCertificateUploadDisabled) {
      this.setSelectedCertificateUpload(this.activeTrainingItem.id)
    } else if (this.activeTrainingItem.nextTrainingItem) {
      this.setActiveTrainingItem(this.activeTrainingItem.nextTrainingItem.id)
      this.activeTrainingItem.isExpanded = true
      this.activeTrainingItem.setActiveItem()
      // this.selectedContentItem = this.activeTrainingItem.contentItems[0]
    } else if (this.planQuiz && this.requireQuiz && !this.planQuiz.isComplete) {
      this.openQuiz(this.planQuiz)
    } else if (this.requireCertificate) {
      this.setSelectedCertificateUpload('for-plan')
    }
  }

  @computed
  public get userTrainingPlanItems(): TrainingPlanItem[] {
    return this.userTrainingPlan.trainingPlanItems
  }

  @computed
  public get trainingItemRows(): PlanItemVM[] {
    return this.userTrainingPlanItems.map((item) => new PlanItemVM(this.rootStore, item, this))
  }

  @computed
  public get lineHeight(): number {
    let height = 0
    this.trainingItemRows.forEach((row) => {
      height = height + row.height
    })
    if (this.planQuiz && this.requireQuiz) height = height + 65
    if (this.requireCertificate) height = height + 65
    return height
  }

  @computed
  public get description(): string {
    if (!this.userTrainingPlan.description) return '(No description provided)'
    return this.userTrainingPlan.description
  }

  @computed
  public get totalItems(): number {
    let items = 0
    if (this.requireQuiz) items++
    if (this.requireCertificate && !this.isCertificateUploadDisabled) items++
    this.trainingItemRows.forEach((row) => {
      if (row.requireQuiz) items++
      if (row.requireCertificate && !this.isCertificateUploadDisabled) items++
      items = items + row.contentItems.length
    })
    return items
  }

  @computed
  public get completedItems(): number {
    let items = 0
    if (this.requireQuiz && this.planQuiz.isComplete) items++
    if (this.requireCertificate && this.userTrainingPlan.certificate) items++
    this.trainingItemRows.forEach((row) => {
      if (row.requireQuiz && row.quiz.isComplete) items++
      if (row.requireCertificate && row.hasCertificate && !this.isCertificateUploadDisabled) items++
      row.contentItems.forEach((item) => {
        if (item.isComplete) items++
      })
    })
    return items
  }

  @computed
  public get progress(): number {
    return Math.round((this.completedItems / this.totalItems) * 100)
  }

  @computed
  public get progressLabel(): string {
    return `${100 - this.progress}%`
  }

  @action
  public showCompletedScreen() {
    this.completionScreenShown = true
  }

  @action
  public markItemsComplete() {
    this.trainingItemRows.forEach((row) => {
      if (row.contentItems.some((item) => !item.isComplete)) {
        row.markAsIncomplete()
      } else {
        if (row.requireQuiz && !row.quiz.isComplete) return
        if (row.requireCertificate && !this.isCertificateUploadDisabled && !row.hasCertificate)
          return
        row.markAsComplete()
      }
    })
  }

  @computed
  public get planIsComplete(): boolean {
    if (this.trainingItemRows.some((row) => !row.isComplete)) return false
    if (this.requireQuiz && !this.planQuiz.isComplete) return false
    if (this.requireCertificate && !this.userTrainingPlan.certificate) return false
    return true
  }

  @computed
  public get dueDate(): string {
    return this.userTrainingPlan.dueDate
  }

  @computed
  public get isPastDue(): boolean {
    if (moment().isAfter(moment(this.dueDate).endOf('day'))) return true
    return false
  }

  @computed
  public get lockClasses(): string {
    if (!this.planIsComplete && this.isPastDue) return 'status-due'
    return 'status-active'
  }

  @action
  private debounceFn = debounce((fn: Function) => {
    this.handleDebounceFn(fn)
  }, 1000)

  @action
  private handleDebounceFn(fn: Function) {
    fn()
  }

  @action
  private async doSave() {
    this.markItemsComplete()
    if (!this.userTrainingPlan.objectId || this.isReadOnly) return
    if (this.planIsComplete) {
      this.userTrainingPlan.markAsComplete()
    } else {
      this.userTrainingPlan.markAsIncomplete()
    }
    const dto = this.userTrainingPlan.serialize()
    dto.isCertificateUploadDisabled = this.isCertificateUploadDisabled
    await this.svc.saveUserTrainingPlan(dto)
    if (this.userTrainingPlan.isComplete && !this.reviewingPlan) this.showCompletedScreen()
  }

  @action
  public async save() {
    this.debounceFn(() => this.doSave())
  }

  @action
  public navigateToDashboard() {
    this.rootStore.appStore.router.push('/dashboard/impact/trainings')
  }
}
